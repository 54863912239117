.leftMenuWrraper {
    position: absolute;
    left: 0px;
    top: 140px;
    bottom: 0%;
    background: #FFFFFF;
    border: 1px solid #AEB6CF;
    width: 84px;
    height: calc(100% - 27px);
    overflow: auto;
}

.commonLeftMenu {
    position: absolute;
    left: 31.25px;
    cursor: pointer;
    padding-bottom: 20px;
}

.commonLeftMenu svg:hover path {
    fill: #1890ff;
}

.leftMenuHelpIcon {
    position: absolute;
    left: 31.25px;
    cursor: pointer;
}

.leftMenuHelpIcon svg:hover path {
    stroke: #1890ff;
}

.rightViewWrapper {
    position: absolute;
    left: 1124px;
    top: 149px;
    width: 315px;
    height: 650px;
    background: #FFFFFF;
    box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
    backdrop-filter: blur(19px);
}

.rightViewHeader {
    height: 44px;
    background: rgba(118, 160, 188, 0.3);
}

.rvHeaderText {
    width: 120px;
    font-size: 20px;
    line-height: 30px;
    color: #18A0FB;
    top: 8px;
    left: 98px;
}

.apperanceHeading {
    width: 100%;
    text-align: left;
    font-size: 18px;
    line-height: 27px;
    position: relative;
    padding: 10px 30px;
    font-weight: bold;
}
.toggleWrap{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
}
.toggleWrap .labelField{
    position: relative;
}
.toggleWrap .toggle{
    left: inherit;
    top: inherit;
}

.appReqLabel {
    width: 73px;
    height: 22px;
    left: 34px;
    top: 105px;
    color: #8A94A6;
}

.maxCharLabel {
    width: 125px;
    height: 22px;
    left: 34px;
    top: 144px;
    color: #8A94A6;
}

.apperanceToggle {
    position: absolute !important;
    left: 230px;
    top: 96px;
}

.maxCharToggle {
    position: absolute !important;
    left: 230px;
    top: 135px;
}

.maxCharInputBox {
    box-sizing: border-box;
    position: relative;
    width: 248px;
    height: 42px;
    left: 34px;
    top: 156px;
    border: 1px solid #AEB6CF;
}

.maxCharInput {
    border-radius: 0px;
    border: 0px;
    filter: none;
    height: 100%;
    width: 100%;
}

.breakLine {
    position: absolute;
    top: 268px;
    left: 0px;
}

.apperanceTextHeading {
    position: absolute;
    width: calc(100% - 30px);
    text-align: left;
    left: 28px;
    top: 279px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}
.inputWrap{
    width: 100%;
    padding: 15px 30px;
}
.inputWrap label{
    width: 100%;
}
.inputWrap label .maxCharInputBox{
    width: 100%;
    position: relative;
    top: inherit;
    left: inherit;
}
.formatWrapper .breakLine{
    width: 100%;
    position: relative;
    top: inherit;
    left: 0;
    display: block;
}

#font-picker {
    box-sizing: border-box;
    position: absolute;
    width: 118px;
    height: 37px;
    left: 30px;
    top: 320px;
    border: 1px solid #AEB6CF;
}

div[id^=font-picker] .dropdown-button:hover,
div[id^=font-picker] .dropdown-button:focus {
    background: #FFFFFF !important;
}

div[id^=font-picker] .dropdown-button {
    background: #FFFFFF !important;
}

.fontSizeBox {
    box-sizing: border-box;
    position: absolute;
    width: 105px;
    height: 37px;
    left: 157px;
    top: 320px;
    border: 1px solid #AEB6CF;
}

.symbol {
    width: 9px;
    height: 15px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.03em;
    color: #AEB6CF;
    text-align: center;
    position: absolute;
    left: 10px;
    top: 10px;
    cursor: pointer;
}

.fontSizeInputBox {
    top: 0px;
    left: 30px;
    position: absolute;
    width: 45px;
    height: 100%;
}

.fontSizeInput {
    border: 1px solid #AEB6CF;
    border-radius: 0px;
    filter: none;
    text-align: center;
    height: 100%;
    width: 45px;
}

.apperanceImgHeading {
    position: absolute;
    /* width: 67px;
    word-break: break-all; */
    height: 54px;
    left: 22px;
    top: 419px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
}

.textEditImg {
    top: 367px;
    position: absolute;
    cursor: pointer;
}

.apperanceLayoutHeading {
    position: absolute;
    width: calc(100% - 30px);
    left: 28px;
    top: 493px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
}

.imgIcons {
    top: 429px;
    position: absolute;
    cursor: pointer;
}

.layoutTopImgs {
    top: 532px;
    position: absolute;
    cursor: pointer;
}

.layoutBottomImgs {
    top: 591px;
    position: absolute;
    cursor: pointer;
}

.colorPicker {
    z-index: 1;
    position: absolute;
    width: 205px !important;
    left: 4px;
    top: 337px;
    padding: 0px !important;
}

.questionWrapper {
    position: absolute;
    width: 795px;
    height: 391px;
    left: 302px;
    top: 261px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    display: flex;
}

.MuiDialog-paper {
    max-width: 1400px !important;
    overflow: hidden !important;
    height: calc(100% - 64px);
}

.question-bank-dialog {
    padding: 0 !important;
    width: 1400px;
    min-height: 400px;
    overflow: hidden !important;;
}

.question-bank-header {
    width: 950px;
    /* height: 75px; */
    height: auto;
    background: rgba(217, 217, 217, 0.8);
    position: fixed;
}

.quesBank-left {
    border-right: 1px solid #AEB6CF;
    height: 100%;
    background: #F3F4F8;
}

.quesBank-right {
    width: 100%;
    background: #F3F4F8;
}

.bank-title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.03em;
    /* position: absolute;
    left: 50px;
    top: 20px; */
    padding-right:20px;
}

.question-bank-search{
    /* position: absolute;
    width: 600px; */
    width: 65%;
    height: 50px;
    /* left: 340px;
    top: 12px; */
    background: #FFFFFF;
    border: 1px solid rgba(174, 182, 207, 0.4);
    border-radius: 25px;
}

.question-bank-search > div{
    display: flex;
    justify-content: space-between;
}

.custom-dropdown{
    position: absolute;
    width: 103px;
    height: 22px;
    left: 536px;
    top: 134px;
    background: #18A0FB;
    border-radius: 11px;
}

.cust-select{
    /* position: absolute;
    top: 0; */
}
.cust-select label+.MuiInput-root{
    margin-top: 7px !important;
    background: #18A0FB;
    border-radius: 20px;
    margin-left: 10px;
    padding-left: 10px;
    padding-top: 3px;
}
.cust-select label+.MuiInput-root:hover:before{
    border: none !important;
}

.cust-select label+.MuiInput-root:before{
    border: none;
}

.cust-select label+.MuiInput-root svg{
    color: #fff;
}
.cust-select .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root{
    background: #18A0FB;
    width: 200px;
    color: #fff;
    border-radius: 25px;
    height: 31px;
    margin: 8px;
}

.cust-select .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:before{
    border-bottom: 0 !important;
}

.cust-select .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:after{
    border-bottom: 0 !important;
    background: #18A0FB !important;
}
.cust-select .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root:hover{
    border-bottom: 0 !important;
    background: #18A0FB !important;
}

.cust-select .css-67qocj-MuiInputBase-root-MuiFilledInput-root-MuiSelect-root.Mui-focused{
    background: #18A0FB !important;
}

.cust-select .MuiSelect-select{
    color: #fff;
    font-size: 14px;
/*    position: absolute;
    left: 11px;
    padding: 0;
    width: 150px;*/
}

.cust-select .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input:focus{
    background: #18A0FB !important;
}

.cust-select .MuiFormControl-root{
    display: contents;
}

.cust-select .MuiSelect-iconFilled{
    color: #ffffff;
}

.cust-select .answerFormControl:after, .cust-select .answerFormControl:before{
    display: none;
}

.question-search{
    /* position: absolute;
    top: 1px;
    right: 2px; */
}

.question-search svg.searchIcon {
    cursor: pointer;
    background: #18a0fb;
    color: #fff;
    border-radius: 20px;
    padding: 0 8px;
    font-size: 35px;
    width: 40px;
}
.question-search > .MuiFormControl-root{
    width: 300px;
}
.question-search .questionSearchInput:before{
    border-bottom: 0;
}
.question-search .questionSearchInput:hover{
    border-bottom: 0;
}
.question-search .questionSearchInput:after{
    border-bottom: 0;
}

.question-search .questionSearchInput:hover:not(.Mui-disabled):before{
    border-bottom: 0;
}

.ques-container{
    width: 950px;
    background: #F3F4F8;
    display: flex;
    overflow-y: auto;
    height: 600px;

}

.ques-row{
    display: flex;
    width: 100%;
    padding: 0px 10px 10px;
}

.ques-card{
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    margin: 10px;
    padding: 10px;
    width: 95%;
    float: left;
    display: flex;
}

.ques-card:hover{
    border-color: #18A0FB;
}

.ques-text{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    letter-spacing: 0.03em;
    color: #000000;
}

.ques-card-common{
    cursor: pointer;
    border-radius: 8px;
    padding: 7px 10px;
    white-space: nowrap;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
}

.ques-exp{
    left: 139px;
    top: 364px;
    background: rgba(24, 160, 251, 0.2);
}

.ques-subCat{
    left: 387px;
    top: 364px;
    background: rgba(174, 182, 207, 0.2);
}

.ques-preview-common{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.03em;
    color: #181818;
}

.ques-preview{
    border-bottom: 1px solid #AEB6CF;
    height: 75px;
    padding: 20px 50px;
}

.ques-queue{
    border-bottom: 1px solid #AEB6CF;
    height: 50px;
    text-align: center;
    font-size: 14px;
    padding-top: 10px;
}

.ques-queue-container{
    padding: 10px 10px;
}

.queue-card{
    min-height: 60px;
    left: 976px;
    top: 246px;
    background: #18A0FB33;
    border-radius: 5px;
    padding:10px;
    margin-bottom: 10px;
    display: flex;
}

.queue-add-btn{
    width: 250px;
    height: 62px;
    padding: 18px 25px;
    /* right: 100px; */
    position: absolute;
    margin-top: 10px;
    cursor: pointer;
    background: #18A0FB;
    left: 50%;
    transform: translateX(-50%);
}

.ques-queue-container .disabled{
    background: rgba(174, 182, 207, 0.2);
    pointer-events: none;
}

.ques-bank-spinner {
    position: absolute;
    left:33%;
    top:50%;
}

.cust-select #demo-simple-select-standard{
    background-color: transparent;
}
