.survey-content {
    position: relative;
    width: 90%;
    margin-left: 10%;
    margin-top: 4%;
    float: left;
    display: grid;

}

.surveyLeftWrapper {
    position: absolute;
    width: 617px;
    height: 800px;
    top: 0px;
    left: 823px;
    background: #fff;
}

.surveyRightWrapper {
    background: #F3F4F8;
    position: absolute;
    width: 823px;
    height: 800px;
    top: 0px;
}

.surveyHeading {
    display: flex;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 72px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #181818;
}

.surveyHelpText {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: -0.015em;
    color: #8A94A6;
    width: 490px;
}

.headerWrapper {
    position: absolute;
    width: 100%;
    height: 81px;
    background: #FFFFFF;
}

.headerImg {
    position: absolute;
    width: 76px;
    height: 55px;
    left: 50px;
    top: 19px;
}

.eyeCls {
    position: absolute;
    left: 999px;
    top: 29px;
    cursor: pointer;
}

.upgrade {
    box-sizing: border-box;
    position: absolute;
    width: 69px;
    height: 40px;
    left: 1049px;
    top: 26px;
    border: 1px solid #18A0FB;
    border-radius: 3px;
    cursor: pointer;
}

.createSurvey {
    position: absolute;
    width: 112px;
    height: 40px;
    left: 1135px;
    top: 26px;
    background: #18A0FB;
    border-radius: 3px;
    cursor: pointer;
}

.settingCls {
    position: absolute;
    left: 1268px;
    top: 35px;
    cursor: pointer;
}

.eyeCls svg:hover path {
    stroke: #18A0FB;
}

.settingCls svg:hover path {
    fill: #18A0FB;
}

.profieImg {
    position: absolute;
    width: 45px;
    height: 45px;
    left: 1312px;
    top: 24px;
    cursor: pointer;
}

.toggle {
    position: absolute;
    left: 1371.2px;
    top: 33.29px;
    cursor: pointer;
}

.companyName {
    box-sizing: border-box;
    width: 148px;
    height: 46px;
    border: 1px solid #AEB6CF;
    margin-left: 6.5px;
}

.companyNameText {
    position: relative;
    width: 135px;
    height: 22px;
    left: 5px;
    top: 10px;
    line-height: 24px;
    color: #8A94A6;
}

.subMenuIcon {
    cursor: pointer;
}

.subMenuIcon svg:hover path {
    fill: #18A0FB;
}

.subMenuIcon svg:hover text {
    fill: #FFFFFF;
}


.back-btn {
    box-sizing: border-box;
    position: absolute;
    width: 136px;
    height: 40px;
    border: 1px solid #AEB6CF;
    border-radius: 3px;
    cursor: pointer;
    right: 225px;
}

.next-btn {
    position: absolute;
    width: 136px;
    height: 40px;
    background: #18A0FB;
    border-radius: 3px;
    cursor: pointer;
    right: 59px;
}

.back-btn-text {
    left: 37%;
    top: 11px;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    color: #AEB6CF;
}

.next-btn-text {
    left: 37%;
    top: 11px;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    color: #FFFFFF;
}

.MuiPaper-rounded {
    border-radius: 0px !important;
}

.dialog-input-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;

    color: #8A94A6;
}

.dialog-paper-content {
    min-width: 600px;
    min-height: 140px;
    padding: 20px 50px !important;
}

@media(max-width: 1460px) {
    .dialog-paper-content {
        min-width: 100%;
    }
}
@media(max-width: 1024px) {
    .dialog-paper-content {
        padding: 20px 30px !important;
    }
}
@media(max-width: 768px) {
    .dialog-paper-content {
        padding: 20px !important;
    }
}

.dialog-input {
    width: 497px;
}

.dialog-action {
    margin-bottom: 35px;
    margin-right: 40px;
}

.dialog-ok-btn {
    background: #18A0FB !important;
}

.dialog-cancel-btn {
    border-color: #AEB6CF !important;
    color: #AEB6CF !important;
    margin-right: 15px !important;
}

.start-survey {
    background: #efeeee;
}

.start-survey-title {
    position: absolute;
    top: 47px;
    left: 51px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.015em;
    color: #8A94A6;
}

.title-underline {
    position: absolute;
    width: 1340px;
    height: 0px;
    left: 50px;
    top: 86px;
    border: 1px solid #AEB6CF;
}

.scratch-heading {
    font-weight: 700;
    font-size: 22px;
    line-height: 33px;
    color: #000000;
}

.choose-theme {
    width: 645px;
    max-width: 100%;
    margin: 0 auto;
}

.select-experience {
    box-sizing: border-box;
    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
    border-radius: 6px;
}

.select-exp-label {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 8px;
    color: #181818;
}

.survey-event {
    width: 100%;
}

.survey-event-img {
    margin-left: 27px;
}

.survey-event-img:nth-child(1) {
    margin-left: 0;
}


.response-label {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #181818;
}

.response-whiteboard {
    position: absolute;
}

.survey-response {
    background: #FFFFFF;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 650px;
    height: 180px;
    margin-top: 20px;
    padding: 25px;
}

.survey-response-type {
    padding: 4px 10px;
    display: grid;
    text-align: left;
}

.survey-link {
    font-family: 'Poppins';
    letter-spacing: -0.015em;
    color: #181818;
}

.survey-helping-txt {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 15px;
    /* identical to box height */

    display: flex;
    align-items: center;
    letter-spacing: -0.015em;

    color: #AEB6CF;
}

/*.Mui-checked{
    color: #63cf4c !important;
}*/

.response-check {
    cursor: pointer;
}

.response-check:checked {
    background: #9FF4A7 !important;
    border: 1px solid #9FF4A7;
    border-radius: 2px;
}