.main-section {
    height: calc(100vh - 130px);
    width: 100%;
    overflow: auto;
    position: relative;
    padding-top: 10px;
    background: #F3F4F8;

    @media screen and (max-width: 1024px){
        height: auto;
        display: flex;
        flex-direction: column;
    }

    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .left-column-section {
        position: absolute;
        left: 0;
        top: 10px;
        width: 316px;
        background: #FFF;
        height: 100%;
        overflow: auto;
        padding: 30px 0;

        @media screen and (max-width: 1024px){
            position: unset;
            width: 100%;
            height: 100%;
        }
    }

    .left {
        &__menu {
            &--title {
                font-family: 'Poppins', sans-serif;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                color: #181818;
                padding: 0 30px;
                margin-bottom: 24px;

                @media screen and (max-width: 1024px){
                    padding: 0 20px;
                }
            }

            &--dropdown {
                padding: 0 30px;
                margin-bottom: 40px;

                @media screen and (max-width: 1024px){
                    padding: 0 20px;
                }

                .MuiFormControl-root {
                    .MuiInputBase-root {
                        border: 1px solid #18A0FB;
                        filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
                        border-radius: 6px;

                        .MuiSelect-select {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: -0.015em;
                            color: #18A0FB;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            display: none;
                        }

                        svg {
                            path {
                                fill: #AEB6CF;
                            }
                        }
                    }
                }
            }

            &--list {
                padding: 0 0 0 30px;
                display: flex;
                flex-direction: column;

                @media screen and (max-width: 1024px){
                    padding: 0 20px;
                }

                &-item {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 24px;
                    color: #8A94A6;
                    padding: 12px;
                    transition: all 0.3s;
                    margin-bottom: 10px;
                    cursor: pointer;

                    &:hover,
                    &.active {
                        background-color: rgba(225, 228, 232, 0.5);
                        color: #18A0FB;
                        transition: all 0.3s;
                    }
                    .tag{
                        width: 80px;
                        background: #FCA426;
                        border-radius: 3px;
                        color: #fff;
                        font-size: 10px;
                        font-style: normal;
                        text-align: center;
                        float: right;
                    }
                }
            }

            &--upload {
                // padding: 0 30px;
                padding: 0 10px 0 30px;
                margin-top: 60px;

                @media screen and (max-width: 1024px){
                    padding: 0 20px;
                }

                .uploaded {
                    &__btn {
                        background: #F8F8F8;
                        border: 1px solid #18A0FB;
                        border-radius: 3px;
                        padding: 10px 12px;
                        width: -moz-fit-content;
                        width: fit-content;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        color: #18A0FB;
                        cursor: pointer;
                    }

                    &__list {
                        background: #FFFFFF;
                        border: 1px solid #E1E4E8;
                        box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.1);
                        border-radius: 2px;
                        overflow-y: auto;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.2s;
                        margin-top: 4px;
                        max-height: 100px;

                        &--item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-weight: 400;
                            font-size: 12px;
                            line-height: 15px;
                            color: #8A94A6;
                            cursor: pointer;
                            padding: 5px 10px;
                            transition: all 0.3s;

                            .title {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: calc(100% - 20px);

                                &__text{
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    // display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }

                                svg {
                                    width: 14px;
                                    height: 14px;
                                    transform: rotate(45deg);
                                    margin-right: 4px;

                                    path {
                                        fill: #18A0FB;
                                    }
                                }
                            }

                            .delete-icon {
                                opacity: 0;
                                visibility: hidden;
                                transition: all 0.3s;

                                svg {
                                    width: 14px;
                                    height: 14px;

                                    path {
                                        fill: #8A94A6;
                                    }
                                }
                            }

                            &:hover,&.active {
                                background-color: rgba(225, 228, 232, 0.5);
                                transition: all 0.3s;

                                .delete-icon {
                                    opacity: 1;
                                    visibility: visible;
                                    transition: all 0.3s;
                                }
                            }

                        }

                        &.list--active {
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.2s;
                        }

                        &::-webkit-scrollbar {
                            width: 5px;
                        }

                        &::-webkit-scrollbar-track {
                            background: #D9D9D9;
                            border-radius: 18px;
                        }

                        &::-webkit-scrollbar-thumb {
                            background: #AEB6CF;
                            border-radius: 18px;
                        }

                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                    }
                }
            }
        }
    }

    .right-column-section {
        position: absolute;
        right: 25px;
        left: 335px;
        top: 10px;
        width: calc(100% - 350px);
        background: #FFF;
        box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
        height: 100%;
        overflow: auto;
        padding: 24px;

        @media screen and (max-width: 1024px){
            position: unset;
            width: 100%;
            height: 100%;
            box-shadow: none;
            margin-top: 20px;
        }

        .uploadDialogInner {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: flex-start;
            height: 100%;

            .icon {
                width: 57px;
                height: 57px;
                background: #FFFFFF;
                box-shadow: 0 4px 10px rgba(10, 68, 106, 0.15);
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 15px;
                position: relative;
            }

            .areaInput {
                position: relative;
                display: flex;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                padding: 20% 36% 10px;

                @media screen and (max-width: 1024px){
                    padding: 20px 0 0 0;
                    width: 100%;
                }

                .dragableElement {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    border: 1px dotted #18a0fb;
                    border-radius: 5px;
                    display: none;
                }

                input {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    cursor: pointer;
                    opacity: 0;

                    &:hover {
                        &~.dragableElement {
                            display: block;
                        }
                    }
                }
            }

            h3 {
                font-size: 14px;
                line-height: 21px;
                margin-bottom: 0;
                color: #181818;
                white-space: nowrap;
            }

            p {
                font-size: 12px;
                line-height: 18px;
                text-align: center;
                color: #8A94A6;
                margin-bottom: 20px;
            }
        }

        .blue--btn {
            background: #18A0FB;
            border-radius: 3px;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            padding: 8px 16px;
            width: -moz-fit-content;
            width: fit-content;
            white-space: nowrap;
        }

        .section-row {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-bottom: 45px;

            @media screen and (max-width:600px){
                flex-direction: column;
            }

            .blue--btn {
                height: 44px;
                margin-left: 16px;

                @media screen and (max-width:600px){
                    width: 100%;
                    margin: 0 0 15px 0;
                }
            }

            .select-wrap {

                @media screen and (max-width:600px){
                    width: 100%;
                    margin-bottom: 15px;
                }
                .left__menu--upload{
                    padding: 0;
                    margin: 0;
                    min-width: 150px;
                    height: 44px;
                    text-align: right;

                    .uploaded__btn{
                        min-width: 150px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: auto;
                        @media screen and (max-width:600px){
                            width: 100%;
                        }
                    }

                    .uploaded__list {
                        min-width: 250px;
                        height: auto;
                        position: relative;
                        overflow: unset;
                        z-index: 100;

                        .uploaded__list--item{
                            position: relative;
                            font-size: 14px;
                            line-height: 18px;
                            .fieldSubTypePopup{
                                width: 150px;
                                padding:10px;
                                text-align: left;
                            }

                            .questionQuePopup{
                                position: absolute;
                                left: 100%;
                                z-index: 1000;
                                top: 0;
                                background: #fff;
                                background: #FFFFFF;
                                border: 1px solid #E1E4E8;
                                box-shadow: 0px 7px 15px rgb(0 0 0 / 10%);
                                border-radius: 4px;

                                @media screen and (max-width: 1024px){
                                    left: 10px;
                                    width: 100%;
                                    top: 100%;
                                }
                            }
                            &:hover{
                                .title{
                                    color: #18A0FB;
                                }
                                .delete-icon{
                                    svg{
                                        path{
                                            fill: #18A0FB;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .MuiFormControl-root {
                    .MuiInputBase-root {
                        height: 100%;
                        background: #F8F8F8;
                        border: 1px solid #18A0FB;
                        // filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25));
                        border-radius: 6px;

                        .MuiSelect-select {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 21px;
                            display: flex;
                            align-items: center;
                            text-align: center;
                            letter-spacing: -0.015em;
                            color: #18A0FB;
                        }

                        .MuiOutlinedInput-notchedOutline {
                            display: none;
                        }

                        svg {
                            path {
                                fill: #AEB6CF;
                            }
                        }
                    }
                }

                .questionQuePopup {
                    width: 350px;
                    display: none;
                    
                    h3 {
                        padding: 10px 15px;
                        border-bottom: 1px solid #ccc;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        color: #000000;
                        margin: 0;

                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        span{
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            color: #8A94A6;
                        }
                    }
    
                    .innerPopup {
                        width: 100%;
                        padding: 10px 5px;
    
                        h4 {
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 15px;
                            color: #8A94A6;
                            margin-bottom: 10px;
                        }
    
                        .items {
                            display: flex;
                            flex-wrap: wrap;
                            align-content: center;
    
                            .item {
                                width: 33.33%;
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                cursor: pointer;
                                position: relative;
    
                                &.active {
                                    background: #E1E4E8;
                                    border-radius: 5px;
                                }
    
                                &:hover {
                                    background: #E1E4E8;
                                    border-radius: 5px;
                                }
    
                                .ai {
                                    display: none;
                                    position: absolute;
                                    // right: 40px;
                                    right: 16px;
                                    top: -3px;
                                    font-weight: bold;
                                    font-size: 12px;
                                }
    
                                &:first-child {
                                    .ai {
                                        display: block;
                                    }
                                }

                                > img{
                                    width: 36px;
                                    height: 36px;
                                    object-fit: contain;
                                }

                                &.questionHelp{
                                    img{
                                        width: 18px;
                                        margin: 6px 10px;
                                        height: auto;
                                        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                                    }
                                }
    
                                p {
                                    font-weight: 400;
                                    font-size: 10px;
                                    line-height: 15px;
                                    color: #181818;
                                    margin: 0;
                                    margin-top: 8px;
                                    text-align: left;

                                    @media screen and (max-width: 1024px){
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                    }
    
    
                    &.active{
                        display: block;
                    }
                }

                .btn-wrap{
                    display: flex;
                    align-items: center;

                    @media screen and (max-width: 600px){
                        flex-direction: column;
                        align-items: flex-start;
                        margin-top: 20px;
                        .blue--btn{
                            margin-top: 20px;
                        }
                    }
                }
            }
        }

        .search-wrap {
            background: #F8F8F8;
            border: 1px solid #E1E4E8;
            border-radius: 4px;
            position: relative;
            margin-left: 16px;

            @media screen and (max-width:600px){
                width: 100%;
                margin: 0 0 15px 0;
            }

            input {
                width: 100%;
                height: 42px;
                padding: 10px;
                border: none;
                padding-right: 50px;
                background: #F8F8F8;

                &:focus {
                    outline: 0;
                }
            }

            .searchIcon {
                position: absolute;
                right: 5px;
                top: 5px;
                background: #18A0FB;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 31px;
                width: 37px;
                cursor: pointer;

                svg {
                    color: #FFF;
                }
            }
        }

        .table-wrapper {
            .MuiTableContainer-root {
                box-shadow: none;
                border: 1px solid #AEB6CF;
                border-radius: 0;

                .MuiTable-root {
                    .MuiTableHead-root {
                        background: #EFF1F3;

                        tr {
                            th {
                                padding: 25px 16px;
                                // padding: 16px;
                                font-weight: 500;
                                // font-size: 22px;
                                font-size: 18px;
                                line-height: 28px;
                                color: #181818;
                                border: 0;
                                white-space: nowrap;

                                &:nth-child(2){
                                    width: 20%;
                                }
                                &:nth-child(3){
                                    width: 20%;
                                }
                                &:nth-child(4){
                                    width: 25%;
                                }
                                &:nth-child(5){
                                    width: 30%;
                                }

                                @media screen and (max-width:1024px){
                                    font-size: 16px;
                                    padding: 16px 8px;
                                }
                            }
                        }
                    }

                    .MuiTableBody-root {
                        .MuiTableRow-root {

                            th,
                            td {
                                // padding: 25px 16px;
                                padding: 16px;
                                font-weight: 500;
                                // font-size: 18px;
                                font-size: 16px;
                                line-height: 24px;
                                color: #8A94A6;
                                border-bottom: 1px solid #AEB6CF;

                                &:nth-child(2){
                                    width: 20%;
                                }
                                &:nth-child(3){
                                    width: 20%;
                                }
                                &:nth-child(4){
                                    width: 25%;
                                }
                                &:nth-child(5){
                                    width: 30%;
                                }

                                @media screen and (max-width:1024px){
                                    padding: 8px;
                                }

                                .answer {
                                    word-break: break-word;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    display: -webkit-box;
                                    -webkit-line-clamp: 1;
                                    -webkit-box-orient: vertical;
                                }

                                .textField{
                                    border: 1px solid #AEB6CF;
                                    border-radius: 10px;
                                    &:focus{
                                        outline: 0;
                                    }
                                }
                            }
                        }
                    }

                    .MuiTableCell-paddingCheckbox {
                        padding: 0 0 0 4px !important;

                        .MuiButtonBase-root {
                            svg {
                                path {
                                    border-radius: 2px;
                                }
                            }

                            &.Mui-checked {
                                svg {
                                    path {
                                        fill: #9FF4A7;
                                        border-radius: 2px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}