.questionInputArea {
    width: 410px;
    height: 45px;
    border: none;
    outline: none;
    resize: none;
}

.answerInputArea {
    width: 272px;
    border: none;
    outline: none;
    resize: none;
    height: 30px;
}

.questionInputArea::placeholder {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #A3DAFF;
}
.answerInputArea::placeholder {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #A3DAFF;
}

.answerLine {
    position: absolute;
    width: 410px;
    height: 0;
    border: 1px solid #A3DAFF;
    background: #A3DAFF;
    margin-top: 38px;
}

.answerTypeSelectBox {
    box-sizing: border-box;
    width: 105px;
    height: 35px;
    border: 0.5px solid #AEB6CF;
    margin-top: -2px;
    overflow: hidden;
}

.answerInputType label+.answerFormControl {
    /* width: 105px; */
    width: 110px;
    margin: -7px 0px 0px -11px !important;
}

.answerInputType .answerFormControl:before {
    border-bottom: 0px !important;
}

.answerInputType .answerFormControl:after {
    border-bottom: 0px !important;
}

.answerInputType .answerFormControl:hover {
    border-bottom: 0px !important;
}

.answerInputType .css-6hp17o-MuiList-root-MuiMenu-list {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
}

.sortAnswerIcon {
    width: 23px;
    position: absolute;
    /* top: 8px;
    left: 5px; */
    top: 7px;
    left: 2px;
}
.sortAnswerIcon.pictureIcon{
    width: 22px !important;
    height: auto !important;
    padding: 5px;
    padding-top: 4px;
}

.sortAnswerLabel {
    font-size: 9px;
    line-height: 14px;
    left: 4px;
    top: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 103px;
}

.sort1::before{
    content: "AI";
    position: absolute;
    transform: translate(48px, -3.5px);
    font-weight: bold;
}



.imageIcons {
    position: absolute;
    display: flex;
    cursor: pointer;
    margin-top: 3px;
    margin-left: 7px;
}

.inputFile>input{
    display: none;
}

@media (min-width: 600px) {
    .typeListItem {
        min-height: 32px !important;
    }
}
