@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import "./variables.scss";
@import "./global.scss";
@import "./previewScreen.scss";

.scratchDialog {
  .MuiDialog-paper {
    height: auto;
    .alertBox {
      margin-top: 20px;
      .MuiAlert-root {
        position: static;
        @media screen and (max-width: $screen_540px) {
          padding: 0 5px;
          .MuiAlert-action {
            padding: 2px 0 0 10px;
          }
        }
      }
    }
  }
}

.MuiDialog-root .analyseNotes {
  overflow: unset !important;
  .cancelIcon {
    position: absolute;
    right: 6px;
    top: 8px;
    z-index: 100;
    cursor: pointer;
    svg {
      path {
        fill: #fc567a;
      }
    }
  }
}

.headerWrap {
  background: $white;
  min-height: 50px;
  width: 100%;
  @include flex(center, space-between);
  padding: 0 35px 0 0px;

  @media screen and (max-width: $screen_1024px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 600px) {
    justify-content: center; // Adjust justify-content for smaller screens
  }

  .leftSide,
  .rightSide {
    @include flex(center, space-between);
  }

  .leftSide {
    padding-left: 0;
    .arrow {
      cursor: pointer;
      &:after {
        display: none;
      }
    }

    .logoHead {
      cursor: pointer;
      margin-left: 7px;
      img {
        width: 41px;
        height: auto;
      }
    }
  }

  .rightSide {
    & > div {
      margin: 0 10px;
    }

    .eye {
      width: 26px;
      height: 26px;
      background: #e7e7e7;
      border-radius: 2px;
      text-align: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 15px;
      }
    }

    .upgradeBtn {
      .btn {
        padding: 10px 20px;
        min-width: 120px;
        font-family: "Poppins";
        font-size: 13px;
        font-weight: 500;
        line-height: 15px;
        font-weight: normal;
        align-items: center;
        color: #18a0fb;
        text-align: center;
        border: 1px solid #18a0fb;
        border-radius: 3px;

        &.lightblue {
          background: #18a0fb;
          color: #fff;
        }
      }
    }

    .profile {
      margin-right: 0;

      .photoArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .photo {
          height: 30px;
          width: 30px;
          border-radius: 50%;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        img.arrow {
          color: #aeb6cf;
          margin-left: 10px;
          cursor: pointer;
          @media screen and (max-width: $screen_1024px) {
            width: 10px;
            height: auto;
          }
        }
      }

      .profileImage {
        background: #878282;
        color: #fff;
        line-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.mainMenuWrap {
  height: 60px;
  width: 100%;
  background: rgba(24, 160, 251, 0.2);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0 50px;
  position: relative;
  z-index: 1000;

  @media screen and (max-width: $screen_1024px) {
    padding: 0 20px 0 80px;
    height: 60px;

    &.dashboardMenu {
      padding: 0 20px;
    }
  }

  @media screen and (max-width: $screen_768px) {
    padding: 0 20px 0 60px;
  }

  @media screen and (max-width: $screen_540px) {
    padding: 0 20px 0 55px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;

    li {
      position: relative;
      border-bottom: 4px solid transparent;
      height: 60px;
      display: flex;
      align-items: center;

      @media screen and (max-width: 1024px) {
        height: 48px;
        margin-right: 16px;
        border-bottom: 0;
      }

      &:after {
        display: none;
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -4px;
        height: 4px;
        width: 100%;
        background: #18a0fb;
        border-radius: 4px;
      }

      &:hover,
      &.active {
        &:after {
          display: block;
        }

        a {
          color: #18a0fb;
        }
      }

      a {
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: -0.015em;
        color: #181818;

        em {
          margin-left: 16px;
        }

        &:hover,
        &:focus {
          border: none;
          outline: none;
          text-decoration: none;
        }
      }
    }
  }

  .mainMenuList {
    @media screen and (max-width: 1024px) {
      overflow-x: auto;
      // height: 65px;
      height: 60px;
    }

    &::-webkit-scrollbar {
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #d9d9d9;
      border-radius: 18px;
    }

    &::-webkit-scrollbar-thumb {
      background: #acaeb6;
      border-radius: 18px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .mainMenuList-item {
      .mainMenuList-item-link {
        padding-right: 40px;
        background-image: url("../assets/images/down-arrow.svg");
        background-position: right center;
        background-size: auto;
        background-repeat: no-repeat;

        // &.rotate-icon{
        //     background-image: url('../assets/images/up-arrow.svg');

        // }
        @media screen and (max-width: 1200px) {
          font-size: 18px;
          padding-right: 30px;
          background-size: 16px;
        }
        @media screen and (max-width: $screen_1024px) {
          font-size: 15px;
          background-size: 12px;
          padding-right: 20px;
          white-space: nowrap;
          .arrow {
            width: 10px;
            height: auto;
          }
        }

        // @media screen and (max-width: 820px){
        //     font-size: 16px;
        //   }
        @media screen and (max-width: $screen_768px) {
          padding-right: 25px;
          // white-space: nowrap;
        }
      }
      .hide-link{
        background: none;
        padding-right: 0;
      }
      .help{
        @media screen and (max-width: $screen_768px){
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .dropDown {
    position: absolute;
    background: #fff;
    right: 0;
    //   top: calc(100% + 4px);
    top: 100%;
    z-index: 100;
    min-width: 130px;
    border: 1px solid #e1e4e8;
    border-radius: 2px;
    width: 100%;

    @media screen and (max-width: $screen_1024px) {
      right: unset;
      left: 0;
      top: -4px;
    }

    .dropdownIcon {
      position: absolute;
      top: -22px;
      right: -3px;

      @media screen and (max-width: $screen_1024px) {
        right: unset;
        left: -3px;
      }

      svg {
        color: white;
        width: 1.5em;
        height: 1.5em;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-block;
      width: 100%;
      padding-top: 10px;

      li {
        float: left;
        width: 100%;
        padding: 5px 0;
        position: relative;
        border-bottom: none;
        height: auto;
        display: block;

        &:after {
          display: none;
        }

        a {
          float: left;
          width: 100%;
          font-size: 14px;
          line-height: 12px;
          color: #8a94a6;
          padding: 4px 8px;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.03em;

          &:hover,
          &.active {
            background: #e1e4e8;
            color: #000;
          }
        }
      }
    }
  }
}

.mainPageBody {
  padding: 20px 0 0 0;
  width: 100%;
  height: calc(100vh - 111px);
  display: flex;
  background: #f3f4f8;

  @media screen and (max-width: $screen_768px) {
    height: auto;
    min-height: calc(100vh - 100px);
    position: relative;
  }

  .mainPageBodyScroller {
    width: 100%;
    height: 100%;
    overflow: auto;

    @media screen and (max-width: $screen_1024px) {
      width: calc(100% - 250px);
    }
    @media screen and (max-width: $screen_768px) {
      width: 100%;
      padding-top: 50px;
    }
  }
}

.templateGalleryMenu {
  width: 316px;
  height: 100%;
  background: #fff;

  @media screen and (max-width: $screen_768px) {
    position: fixed;
    height: auto;
    top: 0;
    bottom: 0;
    left: -100%;
    box-shadow: 0px 3px 4px rgb(0 0 0 / 10%);
    z-index: 1050;
    // bottom: 20px;
    transition: all 0.3s ease;
    will-change: auto;
    width: 50%;
    // height: 100vh;
    overflow-y: auto;
  }
  @media screen and (max-width: $screen_540px) {
    width: 80%;
  }

  &.panel-active {
    left: 0;
    transition: all 0.3s ease;
  }

  .headSection {
    background: rgba(118, 160, 188, 0.3);
    height: 44px;
    width: 100%;
    position: relative;
    padding-left: 15px;

    h1 {
      font-size: 20px;
      line-height: 42px;
      letter-spacing: 0.03em;
      height: 100%;
      color: #181818;
      margin: 0;
    }

    svg {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }

  .searchWrap {
    margin: 15px 10px;
    background: #f8f8f8;
    border: 1px solid #e1e4e8;
    border-radius: 4px;
    position: relative;

    input {
      width: 100%;
      height: 42px;
      padding: 10px;
      border: none;
      padding-right: 50px;
      background: #f8f8f8;

      &:focus {
        outline: 0;
      }
    }

    .searchIcon {
      position: absolute;
      right: 5px;
      top: 5px;
      background: #18a0fb;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 31px;
      width: 37px;
      cursor: pointer;

      svg {
        color: white;
      }
    }
  }

  .listing {
    padding: 5px 0 0 0;
    height: calc(100% - 118px);
    position: relative;

    h2 {
      font-family: inherit;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: #181818;
      margin: 0;
      padding: 0 15px 10px 15px;
    }

    ul {
      list-style: none;
      width: 100%;
      padding: 0;
      height: calc(100% - 34px);
      overflow: auto;

      li {
        width: 100%;

        a {
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: #8a94a6;
          padding: 10px 5px 10px 15px;
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-decoration: none;

          .tag {
            width: 55px;
            background: #fca426;
            border-radius: 3px;
            color: #fff;
            font-size: 6px;
            font-style: normal;
            text-align: center;
          }

          &:hover,
          &.active {
            background: rgba(224, 228, 232, 0.5);
            color: #18a0fb;
            text-decoration: none;
          }
        }
      }
    }
  }
  .privateArea {
    height: 100%;
    overflow: auto;
    .privateHead {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      margin-bottom: 10px;
      padding: 0 10px;
      .drop {
        margin-right: 10px;
        cursor: pointer;
        svg {
          color: #aeb6cf;
        }
      }
      h2 {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        color: #181818;
        padding: 0;
      }
      .add {
        position: absolute;
        right: 10px;
        top: 5px;
        background: #eaf6ff;
        border-radius: 2px;
        width: 22px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        svg {
          font-size: 14px;
          color: #18a0fb;
        }
      }
    }
    .privateBody {
      height: 50%;
      ul {
        li {
          position: relative;
          cursor: pointer;
          p {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 50px 10px 20px;
            span {
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding-right: 10px;
            }
            em {
              font-style: normal;
            }
          }
          .more {
            position: absolute;
            right: 10px;
            top: 5px;
            //   display: none;
            cursor: pointer;
            opacity: 0;
            visibility: hidden;
            transition: all 0.3s;
          }
          &.active {
            cursor: default;
          }
          &.active,
          &:hover {
            background: rgba(225, 228, 232, 0.5);
            transition: all 0.3s;
            .more {
              // display: block;
              opacity: 1;
              visibility: visible;
              transition: all 0.3s;
            }
          }
        }
      }
    }
    .profileAccount {
      border-top: 1px solid #aeb6cf;
      h2 {
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #000;
        padding-top: 10px;
        strong {
          color: #18a0fb;
        }
      }
      h4 {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8a94a6;
        padding: 10px;
      }
      .response {
        margin-bottom: 15px;
        position: relative;
        padding: 0 20px;
        .slider {
          width: 100%;
          height: 7px;
          border-radius: 3px;
          background: #d9d9d9;
          position: relative;
          .value {
            position: absolute;
            left: 0;
            top: 0;
            height: 7px;
            background: #18a0fb;
            border-radius: 3px;
          }
        }
        p {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 10px;
          line-height: 15px;
          color: #8a94a6;
          padding: 10px;
        }
        em {
          color: #18a0fb;
          font-style: normal;
        }
      }
    }
    .helpArea {
      position: absolute;
      // bottom: -20px;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 85px;
      line-height: 3;
      display: inline;
      justify-content: flex-start;
      align-items: center;
      background: #fff;
      cursor: pointer;
      svg {
        margin-right: 10px;
        font-size: 24px;
      }
      .arrow {
        position: absolute;
        right: 0;
        top: 0px;
        svg {
          font-size: 24px;
          color: #aeb6cf;
        }
        &::after {
          display: none;
        }
      }
      .arrowContact{
        top: 40px;
      }

      @media screen and (max-width: $screen_768px) {
        bottom: 40px;
      }
    }
    .help{
      border-top: 1px solid #aeb6cf;
      padding: 0 20px;
    }
    .contactUs{
      padding: 0 20px;
      display: flex;
    }
  }
  &.DashboardSidebarViews {
    .listing {
      height: calc(100% - 73px);
      @media screen and (max-width: $screen_768px) {
        height: calc(100% - 115px);
      }
    }
  }
}

.ListViewWrap {
  padding: 0 30px;

  @media screen and (max-width: $screen_768px) {
    padding: 0 20px;
  }

  .tableWrap {
    border: none;
    box-shadow: none;
    background: transparent;
    table {
      thead {
        tr {
          td,
          th {
            background: transparent;
          }
        }
      }
      tbody {
        tr {
          border-bottom: 15px solid #f3f4f8;
          td,
          th {
            background: #fff;
            border: 0;

            &:first-child {
              border-radius: 3px 0 0 3px;
            }
            &:last-child {
              border-radius: 0 3px 3px 0;
            }
          }

          th {
            display: flex;
            align-items: center;
          }

          .titleDate {
            margin-top: 0;
            .surveySubTitle {
              margin-bottom: 2px;
            }
          }
        }
      }

      .listViewBadge {
        left: 0;
        top: 0;
        transform: unset !important;
        min-width: 60px;
        text-align: center;
      }
      .themeIcon {
        width: 44px;
        height: 44px;
        background: #fca426;
        border-radius: 4px;
        margin-right: 20px;
        float: left;
        cursor: pointer;
        flex: 0 0 44px;
      }
      .titleDate {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 5px;

        .title {
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height: 40px;
        }
      }
      .titleName {
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 27px;
        color: #181818;
      }
      .date {
        font-size: 10px;
        line-height: 20px;
        color: #8a94a6;
      }
      .surveySubTitle {
        font-size: 12px;
        line-height: 15px;
        color: #8a94a6;
        font-weight: 400;
      }
      .inner {
        background: #f2f3f7;
        border-radius: 2px;
        height: 33px;
        width: 35px;
        padding: 0 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #181818;
        margin: 0 auto;
        cursor: pointer;
      }
      .innerDate {
        font-size: 18px;
        color: #181818;
        .more {
          cursor: pointer;
          padding: 5px 5px;
          border-radius: 3px;
          margin-left: 10px;
          &:hover,
          &:focus {
            background: #f2f3f7;
          }
        }
      }
    }
  }
}

.productFeedbackWrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px 70px;
  padding: 0 30px 20px;

  @media screen and (max-width: $screen_1368px) {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: $screen_768px) {
    padding: 0 20px;
  }
  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }

  .itemFeedBack {
    // width: 209px;
    background: #ffffff;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    // float: left;
    // margin: 2px 30px 40px;
    width: 100%;
    position: relative;

    .surveyTheme {
      // background: #319EB8;
      background-color: #fff;
      // height: 134px;
      height: 140px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      border-radius: 3px 3px 0 0;

      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      cursor: pointer;

      @media screen and (max-width: $screen_768px) {
        height: 200px;
      }

      h3 {
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #ffffff;
        margin: 0;
        padding: 0;
        position: relative;
        z-index: 11;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        min-height: 50px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        font-weight: 500;
      }

      p {
        position: relative;
        z-index: 20;
        color: #8a94a6;
        font-size: 12px;
      }

      button.btnPrv {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid #f3f3f3;
        border-radius: 6px;
        font-size: 12px;
        line-height: 14px;
        text-align: center;
        color: #000000;
        padding: 4px 15px;
        z-index: 20;
      }

      .overlay {
        width: 100%;
        height: 100%;
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 10;
        border-radius: 3px 3px 0 0;

        &.active {
          display: block;
        }
      }

      &:hover {
        button.btnPrv {
          display: block;
        }
      }

      &.hasTheme {
        p {
          color: #ffffff;
        }
      }
    }

    .surveyCard {
      display: block;
      padding: 20px 10px;

      .surveyTitleWrap{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        h3 {
            &.surveyTitle {
                display: flex;
                flex-direction: column;
                line-height: 25px;
                text-align: left;
                width: 100%;
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
        .surveySubTitle {
            font-size: 12px;
            line-height: 15px;
            color: #8a94a6;
            font-weight: 400;
            text-align: left;
            width: 100%;
            &.textWhite {
                color: #ffffff;
                display: block;
                z-index: 99;
            }
        }
      }
    }

    .surveyDetail {
      background: #fff;
      padding: 10px;
      border-radius: 0 0 3px 3px;

      .innerRow {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        p {
          font-size: 10px;
          line-height: 15px;
          letter-spacing: 0.03em;
          color: #8a94a6;
          margin: 0;
          width: 35%;
        }

        .result {
          display: flex;
          // justify-content: space-between;
          align-items: center;
          justify-content: flex-start;
          width: 55%;
        }

        em {
          background: #f2f3f7;
          border-radius: 2px;
          font-size: 10px;
          line-height: 9px;
          color: #181818;
          font-style: normal;
          padding: 5px 0;
          width: 20px;
          text-align: center;
        }

        .title {
          font-size: 8px;
          line-height: 8px;
          color: #18a0fb;
          background: #eaf6ff;
          border-radius: 1px;
          padding: 3px 5px;
          margin-left: 5px;
          position: relative;
          cursor: pointer;
          @media screen and (max-width: 1600px) {
            font-size: 7px;
          }
        }

        .copyRow {
          visibility: hidden;
          transform: rotate(-90deg);
          cursor: copy;

          svg {
            font-size: 10px;
            color: #18a0fb;
          }
        }

        &:hover {
          .copyRow {
            visibility: visible;
          }
        }
      }
    }

    .gridViewBadge {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 100;
    }
  }
}

.filterPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 20px;

  @media screen and (max-width: $screen_1024px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: $screen_768px) {
    position: fixed;
    z-index: 1050;
    background: #fff;
    top: 0;
    right: -100%;
    bottom: 0;
    margin: 0;
    width: 80%;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    justify-content: flex-start;
    padding-top: 60px;
    transition: all 0.3s ease;
    will-change: auto;
    width: 50%;
  }

  @media screen and (max-width: $screen_540px) {
    width: 80%;
    padding-left: 20px;
    padding-right: 20px;
  }

  &.filter-active {
    right: 0;
    transition: all 0.3s ease;
  }

  .menuClose {
    margin: 0;
    position: absolute;
    top: 20px;
    left: 20px;
  }

  .leftFilter {
    display: flex;
    align-items: center;

    @media screen and (max-width: $screen_1024px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: $screen_768px) {
      width: 100%;
    }

    button.btn {
      border: 1px solid #aeb6cf;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #18a0fb;
      min-height: 42px;
      border-radius: 0;
      background-color: transparent;

      @media screen and (max-width: 1080px) {
        font-size: 14px;
      }
      @media screen and (max-width: $screen_768px) {
        width: 100%;
      }
    }
  }

  .rightFilter {
    display: flex;
    @media screen and (max-width: $screen_768px) {
      flex-direction: column;
      width: 100%;
    }

    .dropdownGroup {
      display: flex;
      align-items: center;
      @media screen and (max-width: $screen_768px) {
        width: 100%;
        gap: 10px;
      }
      @media screen and (max-width: $screen_540px) {
        flex-direction: column;
        gap: 0;
      }
    }

    .dropDownWrap {
      margin-right: 10px;
      position: relative;

      @media screen and (max-width: 800px) {
        margin-right: 8px;
      }
      @media screen and (max-width: $screen_768px) {
        width: 50%;
      }
      @media screen and (max-width: $screen_540px) {
        width: 100%;
      }

      button {
        border: 1px solid #18a0fb;
        width: 150px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #18a0fb;
        height: 42px;
        border-radius: 3px;
        background-color: transparent;

        @media screen and (max-width: $screen_1024px) {
          width: 120px;
        }
      }

      ul.drop {
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        list-style: none;
        z-index: 199;
        background: #ffffff;
        border-radius: 4px;
        width: 100%;
        padding: 0;
        box-shadow: 0px 12px 58px rgba(34, 43, 69, 0.15);

        li {
          width: 100%;
          padding: 4px 10px;
          font-family: "Poppins";
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #8a94a6;
          text-align: left;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          transition: all 0.3s;

          .emptyDiv {
            width: 20px;
            height: 20px;
            background-color: transparent;
            // margin-right: 10px;
          }

          svg {
            margin-right: 10px;
          }

          &:hover,
          &.active {
            transition: all 0.3s;
            background: rgba(225, 228, 232, 0.5);
            color: #18a0fb;
          }
        }
      }

      @media screen and (max-width: $screen_768px) {
        margin: 0 0 20px 0;
        button {
          width: 100%;
        }
      }
    }

    .gridListWrap {
      button {
        border: 1px solid #18a0fb;
        width: 80px;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #18a0fb;
        height: 42px;
        border-radius: 3px 0 0 3px;
        background-color: transparent;

        &.btnList {
          border-radius: 0 3px 3px 0;
        }

        &.active {
          background: #18a0fb;
          color: #fff;
        }

        //   @media screen and (max-width: $screen_1024px){
        //     width: 75px;
        //   }
      }

      @media screen and (max-width: $screen_768px) {
        button {
          width: 50%;
        }
      }
    }
  }
}

.surveyNameWrap {
  padding: 60px 40px !important;
  width: 700px;
  max-width: 100%;

  .fieldWrap {
    label {
      font-size: 16px;
      line-height: 24px;
      color: #8a94a6;
      margin-bottom: 10px;
    }

    input {
      border: 1px solid #aeb6cf;
      border-radius: 10px;
      width: 100%;
      padding: 5px 10px;
      height: 50px;
      color: #181818;
      font-size: 18px;
    }
  }

  &.dialog-paper-content {
    padding: 60px 40px !important;

    @media screen and (max-width: $screen_540px) {
      padding: 40px 20px !important;

      > div {
        height: 100%;
        .btnGroupWrap {
          justify-content: space-between;
          button {
            width: 45%;
            margin: 0;
          }
        }
      }
    }
  }

  .btnGroupWrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 30px;

    .btn {
      background: #18a0fb;
      border-radius: 6px;
      color: #fff;
      border: 1px solid #18a0fb;
      margin-left: 10px;
      font-size: 15px;
      line-height: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 110px;
      height: 38px;

      &.cancel {
        background: #fff;
        color: #18a0fb;
      }
    }

    @media screen and (max-width: $screen_540px) {
      justify-content: space-between;
      button {
        width: 45% !important;
        margin: 0 !important;
      }
    }
  }
}

.newSurveyWrapper {
  background: #f3f4f8 !important;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;

  .innerScratch {
    padding: 30px 50px;
    // height: calc(100% - 110px);
    height: calc(100vh - 110px);
    overflow: auto;
  }

  .select-experience {
    margin-bottom: 30px;

    svg {
      color: #18a0fb;
    }

    .MuiSelect-select {
      color: #18a0fb;
    }

    fieldset {
      border: 1px solid #18a0fb;

      legend {
        display: none;
      }
    }
  }

  .survey-event {
    .eventWrap {
      display: flex;
      // justify-content: space-between;
      justify-content: center;
      align-items: center;
      width: calc(100% + 28px);
      margin: 0 -14px;

      .imgWrap {
        position: relative;
        margin: 0 14px;
        height: 148px;
        border-radius: 5px;
        overflow: hidden;

        @media screen and (max-width: $screen_540px) {
          height: 250px !important;
        }

        .selected {
          display: none;
        }

        input {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          z-index: 1;
          opacity: 0;
          cursor: pointer;

          &:checked + .selected {
            //   display: block;
            //   position: absolute;
            //   left: 10px;
            //   right: 10px;
            //   top: 10px;
            //   bottom: 10px;
            //   height: calc(100% - 20px);
            //   width: calc(100% - 20px);
            //   border: 5px solid #18A0FB;
            //   border-radius: 10px;
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            border: 5px solid #18a0fb;
            border-radius: 5px;
            z-index: 10;
          }
        }

        img {
          width: 100%;
          height: 100%;
          margin: 0;
          object-fit: cover;
          border-radius: 5px;

          @media screen and (max-width: $screen_540px) {
            object-fit: fill;
          }
        }

        .popular {
          position: absolute;
          top: 0;
          right: 0;
          color: #fff;
          font-size: 10px;
          padding: 3px;
          background: rgba(255, 160, 110, 0.8);
          border-radius: 0 5px 0 0;
          font-weight: 600;
        }

        .bg-overlay {
          width: 100%;
          height: 100%;
          display: block;
          background-color: rgba(0, 0, 0, 0.4);
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 5px;
        }

        .theme-title {
          color: #fff;
          font-size: 20px;
          font-weight: 700;
          padding: 15px 20px;
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
        }
      }
    }

    .response {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: relative;
      padding: 20px 0;

      .response-whiteboard {
        position: relative;
        width: calc(50% - 30px);

        &:first-child {
          margin-right: 30px;
        }

        &:last-child {
          margin-left: 30px;
        }

        .survey-response {
          width: 100%;

          .responseHeading {
            display: flex;
            align-items: center;
          }

          .checkBoxWrap {
            position: absolute;
            right: 20px;
          }

          .responseBody {
            display: flex;

            .responseItem {
              display: flex;
              margin-top: 20px;
              width: 33.33%;
              position: relative;

              .checkBoxWrap {
                left: -20px;
                right: inherit;
                top: -4px;
              }

              .survey-helping-txt {
                width: auto;
              }
            }
          }
        }
      }
    }
  }

  .btnGroup {
    padding: 10px 0;
    display: flex;
    justify-content: flex-end;

    .back-btn {
      position: relative;
      right: inherit;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .next-btn {
      position: relative;
      right: inherit;
      margin-left: 15px;
      justify-content: center;
      align-items: center;
      display: flex;
    }

    .back-btn-text,
    .next-btn-text {
      position: relative;
      left: inherit;
      top: inherit;
    }
  }
}

.proTips {
  position: relative;
  z-index: 10;

  .proTipButton {
    cursor: pointer;
  }

  .proTipPopup {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    // left: calc(100% + 10px);
    right: 115%;
    padding: 24px 32px;
    width: 380px;
    height: 205px;
    background: #ff8a00;
    border-radius: 12px;
    z-index: 1;

    @media screen and (max-width: $screen_768px) {
      top: unset;
      right: unset !important;
      bottom: 50px;
      transform: translateX(-50%);
      left: 50%;
      padding: 16px;
    }

    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 10px 0 10px 17.3px;
      border-color: transparent transparent transparent #ff8a00;
      transform: rotate(0deg) translateY(-50%);
      position: absolute;
      top: 50%;
      right: -10px;
      @media screen and (max-width: $screen_768px) {
        top: unset;
        right: unset;
        bottom: -24px;
        left: 50%;
        transform: rotate(90deg) translateX(-50%);
      }
    }

    .leftIcon {
      position: absolute;
      left: -20px;
      top: 65%;

      svg {
        color: #ff8a00;
        width: 1.5em;
        height: 1.5em;
      }
    }

    h4 {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #ffffff;
      text-indent: 0;
      margin-bottom: 8px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #ffffff;
      margin-bottom: 16px;
    }

    .btnGroup {
      padding: 0;
      .btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 32px 7px;
        width: 93px;
        height: 32px;
        background: #ffffff;
        border-radius: 50px;
        border: 1px solid #ffffff;
        color: #ff8a00;
        font-size: 14px;
        line-height: 16px;

        &.btnPrv {
          background: transparent;
          color: #fff;
          margin-right: 15px;
        }
      }
    }
  }
}

.CreateSurveyWrapper {
  position: fixed;
  top: 0;
  left: 13px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #f3f4f8;

  .surveySubMenu {
    z-index: 1;
    //   top: 5px !important;
    display: flex;
    justify-content: flex-start;
    //   justify-content: space-between;
    overflow-x: auto;
    position: relative;

    //   @media screen and (min-width: 1600px){
    //     justify-content: flex-start;
    //   }

    @media screen and (max-width: 1024px) {
      position: fixed !important;
      top: 0 !important;
      right: 0;
      left: -100%;
      bottom: 0;
      width: 40vw;
      height: 100%;
      background: #fff;
      z-index: 99999;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      // padding-top: 50px;
      box-shadow: -10px 56px 140px rgb(143 155 186 / 20%);
      transition: all 0.3s;
      margin: 0 !important;
      padding-bottom: 20px;

      .menuClose {
        display: block;
        margin: 10px;
      }
    }

    @media screen and (max-width: $screen_768px) {
      width: 60vw;
    }
    //   @media screen and (max-width: $screen_540px){
    //     width: 55vw;
    //   }

    &.menu-active {
      left: 0;
      transition: all 0.3s;
    }

    .item {
      float: inherit !important;
      .itemInner {
        .subMenuIcon {
          svg {
            text {
              text-transform: capitalize;
            }
          }
        }
      }

      &.analyseSubmissionItem {
        .itemInner {
          .subMenuIcon {
            svg {
              text {
                transform: translateX(-16px);
              }
            }
          }
        }
      }

      &.pageLogicItem {
        .itemInner {
          // width: 86px;
          border-top: 2px solid #e1e4e8;
          border-bottom: 2px solid #e1e4e8;

          @media screen and (max-width: 1380px) {
            // width: 75px;
            margin-top: 3px;
          }

          @media screen and (max-width: 1024px) {
            border: 0;
            height: 40px;
          }

          svg {
            margin-left: -14px !important;
            background-image: url("../assets/images/logic.png");
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            margin-top: 0px !important;
            height: 42px;
            width: 140px;

            path {
              display: none;
            }

            @media screen and (max-width: 1380px) {
              height: 36px;
            }

            @media screen and (max-width: 1024px) {
              background-image: none;
            }

            &.pageLogicActive {
              background-image: url("../assets/images/logic-hover.png");
              text {
                fill: #fff;
              }

              @media screen and (max-width: 1024px) {
                background-image: none;
              }
            }
          }

          &:hover {
            svg {
              background-image: url("../assets/images/logic-hover.png");
              text {
                fill: #fff;
              }

              @media screen and (max-width: 1024px) {
                background-image: none;
                text {
                  fill: #181818 !important;
                }
              }
            }
          }
        }
      }

      &.surveyLastItem {
        position: absolute;
        right: 0;
        @media screen and (max-width: 1024px) {
          position: unset;
        }
      }

      &.formLastItem {
        .companyName {
          @media screen and (max-width: 1024px) {
            border: 0;
          }
        }
      }

      @media screen and (max-width: 1024px) {
        margin-bottom: 20px;
        margin-left: 15px;

        &:nth-child(2) {
          margin-left: 0;
        }
        &:nth-child(9) {
          margin: 0 0 20px 16px;
          border: 1px solid #18a0fb;
          width: 184px;
          .itemInner {
            width: 100%;
            svg {
              margin-left: 0 !important;
              width: 85%;
              path {
                fill: transparent;
                stroke: transparent;
              }
              text {
                font-size: 16px !important;
                fill: #181818 !important;
                @media screen and (max-width: 1024px) {
                  font-size: 20px !important;
                }
              }
            }
          }
        }
        &:nth-child(10) {
          margin: 0 0 0 10px;
          width: 184px;
          .companyName {
            width: 100%;
          }
        }
      }

      .itemInner {
        > svg {
          width: 100px;
          margin-left: -24px !important;
          @media screen and (min-width: 1024px) and (max-width: 1380px) {
            height: 38px;
            margin-top: 2px !important;
          }
        }
      }

      .subMenuIcon {
        @media screen and (min-width: 1024px) and (max-width: 1380px) {
          svg {
            width: 150px !important;
          }
        }
        @media screen and (max-width: 1024px) {
          svg {
            margin-left: 0 !important;
          }
        }
      }
    }

    .companyName {
      background: #fff;
      margin-right: 10px;
      border: 1px solid #18a0fb;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 120px;
      height: 42px;
      margin-top: 2px;

      @media screen and (min-width: 1024px) and (max-width: 1380px) {
        height: 36px;
        margin-top: 4px;
      }

      .companyNameText {
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        width: 100%;
        margin: 0;
        position: unset;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #8a94a6;
      }
    }
  }

  .createSurveyBody {
    height: calc(100% - 150px);
    width: 100%;
    overflow: auto;
    position: relative;
    top: 5px;

    @media screen and (max-width: 1024px) {
      overflow-x: hidden;
      width: 100%;
      padding: 0 20px;
      //   height: calc(100vh - 150px);
      margin-top: 50px;

      &.mobileView {
        margin-top: 20px !important;
      }
    }

    .leftMenuWrraper {
      top: 20px;
      z-index: 100;
      transition: all 0.3s;

      @media screen and (max-width: 1024px) {
        left: -100%;
        position: fixed;
        bottom: 0;
        top: 130px;
        height: auto;
        box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
        backdrop-filter: blur(9.5px);
        -webkit-backdrop-filter: blur(9.5px);
      }

      &.menu-active {
        left: 0;
        transition: all 0.3s;
      }

      //   .commonLeftMenu {
      //   }
    }
  }

  .questionsQueueWrap {
    width: 183px;
    height: calc(100% - 20px);
    position: absolute;
    left: 100px;
    top: 20px;

    @media screen and (max-width: 1024px) {
      // display: none;
      position: unset;
      width: 100%;
      height: auto;
      margin-bottom: 30px;
    }

    .logoPageTitle {
      display: flex;
      margin-bottom: 15px;

      .addLogo {
        font-size: 14px;
        line-height: 21px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #aeb6cf;
        width: 71px;
        height: 34px;
        background: #ffffff;
        border: 1px solid #fff;
        border-radius: 5px;
        margin-right: 10px;
        cursor: pointer;

        svg {
          color: #aeb6cf;
          font-size: 12px;
          margin-left: 4px;
        }

        &:hover {
          border: 1px solid #18a0fb;

          svg {
            color: #18a0fb;
          }
        }

        &.logoAdded {
          img {
            max-height: 100%;
            max-width: 100%;
          }
        }
      }

      .pageTitle {
        width: 100px;
        margin-right: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        //   white-space: nowrap;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 30px;
        text-align: center;
      }
    }

    .questionQueueInner {
      background: #fff;
      height: calc(100% - 57px);

      @media screen and (max-width: $screen_1024px) {
        height: auto;
      }

      .head {
        background: rgba(118, 160, 188, 0.3);
        height: 35px;
        width: 100%;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        color: #181818;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border: none;
        margin: 0;

        svg {
          font-size: 19px;
          color: #18a0fb;
          cursor: pointer;
        }

        @media screen and (max-width: 1024px) {
          justify-content: flex-end;
          flex-direction: row-reverse;
          padding: 10px;
          svg {
            margin-right: 20px;
          }
        }
      }

      .details {
        height: calc(100% - 34px);
        padding: 20px 0;
        overflow: auto;

        .item {
          background: #fff;
          padding: 5px 10px;
          margin: 5px 0;
          display: inline-block;
          width: 100%;

          &.longText .text {
            background: rgba(0, 102, 99, 0.2);
          }

          &.rating .text {
            background: rgba(37, 149, 185, 0.2);
          }

          &.email .text {
            background: rgba(92, 57, 166, 0.2);
          }

          &.shortText .text {
            background: rgba(84, 54, 34, 0.2);
          }

          &.multiline .text {
            background: rgba(0, 213, 213, 0.2);
          }

          &.yesNo .text {
            background: rgba(114, 114, 89, 0.2);
          }

          &.picture .text {
            background: rgba(223, 223, 223, 0.5);
          }

          &.contact .text {
            background: rgba(80, 166, 43, 0.2);
          }

          &.dropdownIcon .text {
            background: rgba(198, 216, 228, 0.5);
          }

          &.file .text {
            background: rgba(252, 86, 122, 0.2);
          }

          &.active {
            background: #e1e4e8;
          }

          &:hover {
            .iconDot {
              display: block;
            }
          }

          .icon {
            //background: #543622;
            border-radius: 2px 0 0 2px;
            float: left;
            width: 25px;
            height: 25px;
            font-size: 14px;
            position: relative;

            //padding:0 2px;
            img {
              left: -11px;
              top: -8px;
              position: absolute;
              width: 50px;

              &.pictureIcon {
                width: 30px;
                padding: 5px;
                background: #dfdfdf;
                left: -4px;
                top: 0;
              }
            }

            svg {
              color: #fff;
            }
          }

          .text {
            background: rgba(84, 54, 34, 0.2);
            border-radius: 0 2px 2px 0;
            max-width: calc(100% - 50px);
            float: left;
            padding: 4px;
            font-size: 12px;
            line-height: 18px;
            height: 25px;
            color: #000;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            min-width: 27px;
            padding-left: 8px;

            @media screen and (max-width: 1024px) {
              width: calc(100% - 50px);
            }
          }

          .iconDot {
            display: none;
            float: right;
            width: 25px;
            color: #aeb6cf;
            font-size: 14px;
            cursor: pointer;
          }

          &.helpIcon{
            .icon{
                img{
                    width: 24px;
                    height: 24px;
                    left: 2px;
                    top: 0px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
                }
            }
          }
        }
      }
    }
  }

  .questionWrapper {
    top: 50%;
    transform: translateY(-50%);
    width: calc(100% - 638px);
    min-height: calc(100% - 140px);
    overflow: auto;

    @media screen and (max-width: 1024px) {
      position: unset;
      transform: unset;
      width: 100%;
      flex-direction: column;
      height: auto !important;
      margin-bottom: 40px;
      padding: 0 0 20px 0;

      .questionWrapperDiv {
        width: 100% !important;
        height: auto !important;

        .questionAnswerWrap {
          .questionWrap {
            margin-top: 30px !important;
            margin-left: 20px !important;
          }
          .answerWrap {
            margin-left: 20px !important;
          }
        }
      }

      .questionAnswerWrap {
        .questionWrap {
          margin-top: 30px !important;
          margin-left: 20px !important;
        }
        .answerWrap {
          margin-left: 20px !important;
          margin-right: 20px !important;
        }
      }

      .ImageWrap {
        width: 100% !important;
        height: auto !important;
        padding: 20px;
      }
    }

    .questionAnswerWrap {
      .questionWrap {
        label {
          width: 100%;
          position: relative;

          textarea {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #8a94a6;
            height: auto;
            //   min-height: 60px;
            min-height: 75px;
            width: 100%;

            &::placeholder {
              font-weight: 300;
              font-style: italic;
              font-size: 16px;
              line-height: 24px;
              color: #8a94a6;
            }
          }
        }

        .num {
          line-height: 24px;
          padding: 3px 0;
          font-style: italic;
          color: #8e98a9;
        }
      }

      .questionTex {
        width: 100%;
        display: flex;
      }

      .discription {
        width: 100%;

        input {
          font-size: 14px;
          border: none;
          height: 40px;
          padding-left: 5px;
          width: 95%;
          // margin-bottom: 20px;
          margin-bottom: 8px;
          color: #181818;

          &:hover,
          &:focus {
            outline: none;
            border: none;
          }
        }
      }

      .answerWrap {
        margin-left: 34px;
        display: flex;
        position: relative;
        margin-right: 30px;

        label {
          width: 100%;

          textarea {
            font-style: italic !important;
            font-weight: 400 !important;
            font-size: 18px;
            line-height: 27px;
            color: #a3daff !important;
            min-height: 42px;
            width: 100% !important;
            border-bottom: 2px solid #a3daff;
            padding-right: 100px;

            &::placeholder {
              font-weight: 300;
              font-style: italic;
              font-size: 16px;
              line-height: 24px;
              color: #8a94a6;

              @media screen and (max-width: $screen_540px) {
                font-size: 14px;
              }
            }
          }
        }

        .answerTypeSelectBox {
          position: absolute;
          right: 0;
          top: 0;
          background: #fff;
        }
      }
    }

    .ImageWrap {
      .imageInner {
        width: calc(100% - 10px) !important;
        height: calc(100% - 20px) !important;
        margin: 10px 0px 0px;

        @media screen and (max-width: 1024px) {
          width: 100% !important;
          height: 100% !important;
          margin: 0 !important;
        }
      }
    }

    .btnSave {
      width: 85px;
      height: 32px;
      background: #18a0fb;
      border-radius: 4px;
      border: 1px solid #18a0fb;
      color: #fff;
      margin-left: 34px;

      @media screen and (max-width: 1024px) {
        margin-left: 20px;
      }
    }

    &.layout1 {
      /*   min-height: 391px;
        height: auto;
        overflow: hidden;*/
      .layoutWrap {
        .ImageWrap {
          .imageWrapInner {
            .imageInner {
              &.rightSideImage {
                width: 380px !important;
                height: auto !important;
                max-width: 380px;
                margin-bottom: 20px;

                @media screen and (max-width: 1024px) {
                  width: 100% !important;
                  height: 100% !important;
                  max-width: 100%;
                  margin: 0 !important;
                }
              }
            }
          }
        }
      }

      .longTextShortWrap {
        padding-bottom: 20px;
        .questionWrap {
          margin-top: 0px !important;
          // padding-top: 50px;
          padding-top: 20px;

          .questionInputArea {
            min-height: 50px;
          }
        }

        .rightSideImage {
          width: 380px !important;
          height: auto !important;
          max-width: 380px;
          padding-left: 30px;
          margin-bottom: 20px;

          @media screen and (max-width: $screen_768px) {
            padding-left: 20px;
            width: 95% !important;
            max-width: 100%;
          }
        }
      }

      .multipleInner {
        max-height: none;
        overflow: visible;
      }

      .imageUploadWrap {
        height: auto;
        overflow: visible;
      }

      .btnGroup {
        padding-bottom: 20px;
        .btnSave {
          margin-left: 0 !important;
        }
      }
    }

    &.layout2 {
      > div {
        &:first-child {
          width: 60% !important;
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }

          .longTextShortWrap {
            .questionWrap {
              textarea {
                min-height: 75px;
              }
            }
          }
        }
        &:last-child {
          width: 40% !important;
          .rightSideImage {
            width: 100% !important;
            padding-right: 20px;
            img {
              object-fit: cover;
            }

            @media screen and (max-width: 1024px) {
              margin-top: 20px !important;
              padding: 20px;
            }
          }
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }
        }
      }
    }

    &.layout3 {
      > div {
        &:first-child {
          width: 40% !important;
          .rightSideImage {
            width: 100% !important;
            padding-left: 20px;
            img {
              object-fit: cover;
            }

            @media screen and (max-width: 1024px) {
              margin-top: 20px !important;
              padding: 20px;
            }
          }
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }
        }
        &:last-child {
          width: 60% !important;
          .longTextShortWrap {
            .questionWrap {
              margin: 0 !important;
              padding: 60px 20px 0 20px;

              @media screen and (max-width: 1024px) {
                padding-top: 0;
              }
              textarea {
                min-height: 75px;
              }
            }
            .answerWrap {
              margin: 0 20px 0 0 !important;
              padding: 0 0 0 20px;
            }
            .btnSave {
              margin-left: 20px !important;
            }
          }
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }
        }
      }
    }

    &.layout5 {
      > div {
        &:first-child {
          width: 40% !important;

          .rightSideImage {
            width: 100% !important;
            padding: 20px 0 20px 20px;
            height: 100% !important;
            margin: 0 !important;
            img {
              object-fit: cover;
            }

            @media screen and (max-width: 1024px) {
              margin-top: 20px !important;
              padding: 20px;
            }
          }
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }
        }
        &:last-child {
          width: 60% !important;
          .longTextShortWrap {
            .questionWrap {
              margin: 0 !important;
              padding: 60px 20px 0 20px;

              @media screen and (max-width: 1024px) {
                padding-top: 0;
              }
              textarea {
                min-height: 75px;
              }
            }
            .answerWrap {
              margin: 0 20px 0 0 !important;
              padding: 0 0 0 20px;
            }
            .btnSave {
              margin-left: 20px !important;
            }
          }
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }
        }
      }
    }

    &.layout6 {
      // height: 435px;
      height: 470px;

      > div {
        position: relative;
      }

      @media screen and (max-width: 1024px) {
        padding-bottom: 0 !important;
      }

      .questionAnswerWrap,
      .ImageWrap {
        position: absolute;
        left: 40px;
        top: 40px;
        height: calc(100% - 80px) !important;
        width: calc(100% - 80px) !important;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 5px;

        textarea,
        input {
          background: transparent;
        }

        .contactInner .answerTypeSelectBox {
          background: #fff;
        }
      }

      .ImageWrap {
        position: absolute;
        left: 40px;
        top: 40px;
        height: calc(100% - 80px) !important;
        width: calc(100% - 80px) !important;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 5px;

        @media screen and (max-width: 1024px) {
          left: 20px;
          top: 50px;
          width: calc(100% - 40px) !important;
          height: calc(100% - 70px) !important;
          .questionWrap {
            margin: 0 !important;
            padding: 20px;
          }
          .answerWrap {
            margin: 0 20px !important;
          }
          .btnSave {
            margin-left: 20px !important;
          }

          .imageWrapInner {
            .imageUploadWrap {
              height: auto;
            }
            .btnGroup {
              margin: 0;
              .btnSave {
                margin-left: 0 !important;
              }
            }
          }
        }

        @media screen and (max-width: $screen_540px) {
          left: 10px;
          width: calc(100% - 20px) !important;
          padding: 10px;
          .questionWrap {
            padding: 10px;
          }
          .answerWrap {
            margin: 0 10px !important;
          }
          .btnSave {
            margin-left: 10px !important;
          }
        }
      }

      .longTextShortWrap {
        @media screen and (max-width: 1024px) {
          left: 20px;
          top: 50px;
          width: calc(100% - 40px) !important;
          height: calc(100% - 70px) !important;
          .questionWrap {
            margin: 0 !important;
            padding: 20px;
          }
          .answerWrap {
            margin: 0 20px !important;
          }
          .btnSave {
            margin-left: 20px !important;
          }
        }

        @media screen and (max-width: $screen_540px) {
          left: 10px;
          width: calc(100% - 20px) !important;

          .questionWrap {
            padding: 10px;
          }
          .answerWrap {
            margin: 0 10px !important;
          }
          .btnSave {
            margin-left: 10px !important;
          }
        }
      }
    }
  }

  .formatWrapper {
    position: absolute;
    right: 0;
    top: 20px;
    width: 316px;
    background: #fff;
    height: calc(100% - 27px);
    overflow: auto;
    transition: all 0.3s;
    z-index: 200;

    @media screen and (max-width: 1024px) {
      right: -100%;
      position: fixed;
      top: 130px;
      height: auto;
      bottom: 0;
      box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
      backdrop-filter: blur(9.5px);
    }

    @media screen and (max-width: $screen_768px) {
      width: 75%;
    }

    &.menu-active {
      right: 0;
      transition: all 0.3s;
    }

    //height: calc(100% - 20px);
    .head {
      background: rgba(118, 160, 188, 0.3);
      padding: 10px 20px;
      width: 100%;
      font-size: 20px;
      line-height: 30px;
      color: #181818;
      text-align: center;
      font-weight: 600;
      margin: 0;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        margin-left: 8px;
      }

      @media screen and (max-width: 1024px) {
        position: relative;
        font-size: 18px;
        .surveyMenuCloseBtn {
          position: absolute;
          width: 100%;
          height: 100%;

          button {
            position: absolute;
            left: 5px;
            top: 26px;
            transform: translateY(-50%);
            svg {
              margin: 0;
            }
          }
        }
      }
    }

    .formatWrapInner {
      height: calc(100% - 65px);
      overflow: auto;

      > div {
        &:first-child {
          padding: 15px 30px !important;
        }
      }

      .answerTypeSelectBox {
        &.appearanceAnswerType {
          border: thin solid rgba(77, 77, 77, 0.3);
          border-radius: 4px;
          height: 48px;
          .answerInputType {
            height: 100%;
            .MuiFormControl-root {
              min-width: 100%;
              margin: 0;
              height: 100%;
              justify-content: center;
              .MuiInputBase-root {
                width: 100%;
                margin: 0 !important;
                .MuiSelect-select {
                  background-color: transparent;
                }
                .sortAnswerIcon {
                  width: 35px;
                  top: 2px;
                  left: 2px;
                }
                .labelField {
                  font-size: 14px;
                  left: 24px;
                  top: 10px;
                  overflow: visible;
                  &::before {
                    transform: translate(72px, -8.5px);
                  }
                }
              }
            }
          }
        }
      }
    }

    .backTheme {
      width: 100%;
      padding: 20px 35px;
      border-bottom: 1px solid #aeb6cf;

      .btn {
        width: 100%;
        border: 1px solid #aeb6cf;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        line-height: 21px;
        color: #aeb6cf;
        border-radius: 0;

        svg {
          padding-left: 10px;
          color: #aeb6cf;
          font-size: 30px;
        }

        &.active {
          border-color: #18a0fb;
          color: #18a0fb;

          svg {
            color: #18a0fb;
            transform: rotate(90deg);
            padding-left: 5px;
          }
        }

        &:focus {
          box-shadow: none;
        }
      }

      .bgThemes {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        margin: 5px -5px;

        .item {
          width: calc(50% - 2px);
          margin: 1px;
          cursor: pointer;
          border: 4px solid transparent;
          position: relative;

          img {
            width: 100%;
          }

          svg {
            display: none;
            position: absolute;
            left: 50%;
            top: 50%;
            z-index: 1;
            transform: translate(-50%, -50%);
            color: #18a0fb;
          }

          &.active {
            border-color: #18a0fb;

            svg {
              display: block;
            }

            &:hover {
              border-color: #18a0fb;
            }
          }

          &:hover {
            border-color: #bddfff;

            svg {
              display: block;
            }
          }
        }
      }
      .chooseBgTheme {
        border: 1px solid #cccc;
        border-radius: 5px;
        padding: 5px;
        display: flex;
        align-items: center;
      }
      .uploadedImg{
        width: 115px !important;
        margin-left: 7px !important;
        &.active &:hover {
          border-color: #18a0fb;
          color: #18a0fb;

          svg {
            color: #18a0fb;
            transform: rotate(90deg);
            padding-left: 5px;
          }
        }

      }
      .noTheme{
        color: #c6c6c6;
        font-size: 14px;
        font-weight: 300;
        text-align: center;
        padding-top: 25%;
      }
      .uploadBtn{
        height: 32px;
        background: #18a0fb;
        border-radius: 4px;
        color: #fff;       
        padding: 5px;
        text-align: center;
      }
      .cancelBtn {
        position: absolute;
        right: -5px;
        top: -5px;
        color: rgb(255, 255, 255);
        background-color: black;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        text-align: center;
        line-height: 1.2;
      }
    }

    .questionTheme {
      padding: 20px 0;

      .item {
        width: 100%;
        padding: 15px 35px;
        padding-left: 80px;
        position: relative;
        margin-bottom: 20px;
        cursor: pointer;
        min-height: 77px;

        .imageWrap {
          width: 100px;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        svg {
          rect {
            stroke: #aeb6cf;
          }

          circle,
          line {
            stroke: #aeb6cf;
            fill: #aeb6cf;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 10px;
          line-height: 24px;
          color: #8a94a6;
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: #aeb6cf;
          margin: 0;
        }

        .beta {
          position: absolute;
          left: 0;
          top: 0;
          width: 61px;
          height: 18px;
          background: #ffa06e;
          font-size: 10px;
          line-height: 18px;
          text-align: center;
          color: #ffffff;
        }

        &.active {
          background: #e1e4e8;

          svg {
            rect {
              stroke: #18a0fb;
            }

            circle,
            line {
              stroke: #18a0fb;
              fill: #18a0fb;
            }

            path {
              fill: #18a0fb;
            }
          }

          h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #181818;
          }

          p {
            color: #181818;
          }
        }
      }
    }

    .surveyTime {
      padding: 20px 20px;
      .title {
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      label {
        color: #8a94a6;
      }
      .time-input {
        display: flex;
        border: 1px solid;
        border-color: rgba(0, 0, 0, 0.23);
        border-radius: 4px;
        padding: 10px;
        height: 50px;
        input {
          width: 100%;
          border: none;
        }
        input:focus {
          outline: none !important;
        }
        fieldset {
          border: none;
        }
      }
    }

    .textPropertyWrap {
      display: block;
      width: 100%;
      border-top: 2px solid #aeb6cf;
      border-bottom: 2px solid #aeb6cf;
      padding: 15px 30px;

      .apperanceTextHeading {
        position: relative;
        width: 100%;
        top: inherit;
        left: inherit;
        font-weight: bold;
      }

      .fontPickSize {
        width: 100%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0;

        #font-picker {
          position: relative;
          top: inherit;
          left: inherit;
        }

        .fontSizeBox {
          position: relative;
          top: inherit;
          left: inherit;
        }
      }

      .fontStyle {
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .imageVideoWrap {
      padding: 15px 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #aeb6cf;

      .apperanceImgHeading {
        position: relative;
        top: inherit;
        left: inherit;
        height: auto;
        font-weight: bold;
      }

      .imageVideoInner {
        display: flex;
        justify-content: space-between;
        align-items: center;
        //   width: 60%;
        width: 50%;

        .imgIcons {
          position: relative;
          left: inherit;
          top: inherit;
          margin: 0;
        }

        .addBtn {
          border: 1px solid #ffffff;
          padding: 6px;
          width: 70px;
          border-radius: 6px;
          background: #f2f3f7;
          color: #8a94a6;
          display: flex;
        }
      }
    }

    .layoutWrap {
      padding: 15px 30px;
      width: 100%;

      .apperanceLayoutHeading {
        position: relative;
        top: inherit;
        left: inherit;
        height: auto;
        width: 100%;
        font-weight: bold;
      }

      .layoutInner {
        display: flex;
        flex-wrap: wrap;
        align-content: center;

        .item {
          width: 33.33%;
          padding-top: 15px;
          cursor: pointer;
        }
      }
    }

    .buttonProperty{
        padding: 15px 30px;
        &__title{
            font-size: 18px;
            font-weight: bold;
            color: #181818;
            margin-bottom: 10px;
        }

        &__list{
            display: flex;
            flex-wrap: wrap;
            gap: 16px;

            .MuiButton-contained{
                background-color: #AEB6CF;
                border-color: #AEB6CF;
            }

            .MuiButton-outlined{
                background-color: transparent;
                border-color: #AEB6CF;
                color: #AEB6CF;
            }
        }
    }
  }

  .decoratorLayout{
    .backTheme {
        width: 100%;
        padding: 0;
        border-bottom: 1px solid #aeb6cf;
  
        .btn {
          width: 100%;
          border: 1px solid #aeb6cf;
          padding: 5px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-size: 14px;
          line-height: 21px;
          color: #aeb6cf;
          border-radius: 0;
  
          svg {
            padding-left: 10px;
            color: #aeb6cf;
            font-size: 30px;
          }
  
          &.active {
            border-color: #18a0fb;
            color: #18a0fb;
  
            svg {
              color: #18a0fb;
              transform: rotate(90deg);
              padding-left: 0;
            }
          }
  
          &:focus {
            box-shadow: none;
          }
        }
  
        .bgThemes {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          margin: 5px 0;
  
          .item {
            width: calc(50% - 2px);
            margin: 1px;
            cursor: pointer;
            border: 4px solid transparent;
            position: relative;
  
            img {
              width: 100%;
              height: 65px;
            }
  
            svg {
              display: none;
              position: absolute;
              left: 50%;
              top: 50%;
              z-index: 1;
              transform: translate(-50%, -50%);
              color: #18a0fb;
            }
  
            &.active {
              border-color: #18a0fb;
  
              svg {
                display: block;
              }
  
              &:hover {
                border-color: #18a0fb;
              }
            }
  
            &:hover {
              border-color: #bddfff;
  
              svg {
                display: block;
              }
            }
          }
        }
        .chooseBgTheme {
          border: 1px solid #cccc;
          border-radius: 5px;
          padding: 5px;
          display: flex;
          align-items: center;
        }
        .uploadedImg{
          width: 115px !important;
          margin-left: 7px !important;
          &.active &:hover {
            border-color: #18a0fb;
            color: #18a0fb;
  
            svg {
              color: #18a0fb;
              transform: rotate(90deg);
              padding-left: 5px;
            }
          }
  
        }
        .noTheme{
          color: #c6c6c6;
          font-size: 14px;
          font-weight: 300;
          text-align: center;
          padding-top: 25%;
        }
        .uploadBtn{
          height: 32px;
          background: #18a0fb;
          border-radius: 4px;
          color: #fff;       
          padding: 5px;
          text-align: center;
        }
        .cancelBtn {
          position: absolute;
          right: -5px;
          top: -5px;
          color: rgb(255, 255, 255);
          background-color: black;
          width: 18px;
          height: 18px;
          border-radius: 100%;
          text-align: center;
          line-height: 1.2;
        }
      }
  }

  .analyseResultsBody {
    display: flex;
    justify-content: space-between;
    //   padding: 20px 30px 30px 0;
    padding: 20px 0;
    height: calc(100% - 155px);
    width: 100%;
    //   overflow-y: scroll;

    @media screen and (max-width: 1024px) {
      height: calc(100% - 100px);
    }

    .analyseResultsMenu {
      background: #ffffff;
      box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
      backdrop-filter: blur(9.5px);
      width: 180px;
      padding: 35px 0;
      flex: 0 0 180px;
      overflow-y: auto;

      ul {
        display: block;
        list-style: none;
        padding: 0;
        margin-bottom: 0;

        li {
          display: block;
          padding: 15px 10px;
          font-family: "Poppins", sans-serif;
          font-size: 15px;
          font-weight: 400;
          letter-spacing: -0.015em;
          position: relative;
          cursor: pointer;

          &.active {
            background: #d3eeff;
            font-weight: 500;
            color: #181818;

            &::after {
              content: "";
              background: #18a0fb;
              position: absolute;
              width: 4px;
              height: 100%;
              right: 0;
              top: 0;
              bottom: 0;
              border-radius: 4px;
              z-index: 1;
            }
          }
        }
      }
    }

    .analyseResultsWrap {
      width: calc(100% - 180px);
      // margin-left: 25px;
      padding: 0 25px;
      overflow-y: auto;

      .answerAnalysisPage {
        display: block;
        width: 100%;

        .analyseCard {
          background: #ffffff;
          box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
          backdrop-filter: blur(19px);
          border-radius: 5px;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          padding: 15px;
          min-height: 420px;
        }

        .analyse-filter-box {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #181818;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          .select-items {
            display: flex;
            align-items: center;

            .menus {
              padding-left: 0;
              margin-bottom: 0;

              .menu-items {
                button {
                  padding: 6px 10px;
                  font-size: 13px;
                  font-weight: 400;
                  color: #18a0fb;
                  line-height: 18px;
                  min-width: 125px;
                  border: 1px solid #18a0fb;
                  border-radius: 4px;
                  position: relative;
                  background-image: url(../assets/images/arrow-downicon.svg);
                  background-repeat: no-repeat;
                  background-size: 12px 6px;
                  background-position: bottom 11px right 12px;

                  .arrow {
                    display: none;
                  }
                }

                .dropdown {
                  min-width: 9rem;

                  .menu-items {
                    font-size: 13px;
                    font-weight: 400;
                    color: #18a0fb;
                    line-height: 18px;

                    .custom {
                      padding: 6px 10px;
                    }

                    button {
                      border: none;

                      .head {
                        display: none;
                      }
                    }
                  }
                }
              }
            }

            .item {
              margin-right: 10px;

              &:last-child {
                margin-right: 0;
              }

              select {
                padding: 7px 35px 7px 10px;
                background-image: url(../assets/images/arrow-downicon.svg);
                background-repeat: no-repeat;
                background-size: 12px 6px;
                background-position: bottom 11px right 12px;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-weight: 400;
                width: 100%;
                height: 32px;
                min-width: 120px;
                color: #18a0fb;
                border: 1px solid #aeb6cf;
                border-radius: 4px;
                cursor: pointer;
                outline: none;
                box-shadow: none;
                appearance: none;
              }
            }
          }
        }

        .customSelect {
          position: absolute;
          width: 73px;
          height: 12px;
          font-weight: 400;
          font-size: 10.2375px;
          line-height: 13px;
          top: 2.4px;
          right: 12px;

          background: #f8f8f8;
          background-image: url(../assets/images/arrow-downicon.svg);
          background-repeat: no-repeat;
          background-size: 12px 6px;
          background-position: bottom 11px right 12px;

          height: 30px;
          color: #8a94a6;
          border: 1px solid #18a0fb;
          border-radius: 5px;
          cursor: pointer;
          outline: none;
          box-shadow: none;
          -webkit-appearance: none;
          appearance: none;

          &.select-question {
            background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
            background-size: 16px 9px;
            background-position: bottom 22px right 12px;
            padding: 10px 40px 10px 25px;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #000000;
            border: 1px solid #fff;
            box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
            border-radius: 5px;
          }
        }

        .customSelect1 {
          position: absolute;
          width: 73px;
          height: 12px;
          font-weight: 400;
          font-size: 10.2375px;
          line-height: 13px;
          top: 2.4px;
          right: 107px;

          background: #f8f8f8;
          background-image: url(../assets/images/arrow-downicon.svg);
          background-repeat: no-repeat;
          background-size: 12px 6px;
          background-position: bottom 11px right 12px;

          height: 30px;
          color: #8a94a6;
          border: 1px solid #18a0fb;
          border-radius: 5px;
          cursor: pointer;
          outline: none;
          box-shadow: none;
          -webkit-appearance: none;
          appearance: none;

          &.select-question {
            background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
            background-size: 16px 9px;
            background-position: bottom 22px right 12px;
            padding: 10px 40px 10px 25px;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #000000;
            border: 1px solid #fff;
            box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
            border-radius: 5px;
          }
        }

        .selectCustom {
          background: #f8f8f8;
          padding: 0 40px 0 15px;
          background-image: url(../assets/images/arrow-downicon.svg);
          background-repeat: no-repeat;
          background-size: 12px 6px;
          background-position: bottom 22px right 12px;
          font-size: 13px;
          font-weight: 400;
          width: 100%;
          height: 55px;
          color: #8a94a6;
          border: 1px solid #18a0fb;
          border-radius: 5px;
          cursor: pointer;
          outline: none;
          box-shadow: none;
          -webkit-appearance: none;
          appearance: none;

          &.select-question {
            background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
            background-size: 16px 9px;
            background-position: bottom 22px right 12px;
            padding: 10px 40px 10px 25px;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #000000;
            border: 1px solid #fff;
            box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
            border-radius: 5px;
          }
        }

        .analyseQuestionSelect {
          .analyseQuestion {
            display: block;
            background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
            background-size: 16px 9px;
            background-position: bottom 22px right 12px;
            width: 100%;
            padding: 10px 40px 10px 15px;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #000000;
            border: 1px solid #fff;
            appearance: none;
            box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
            border-radius: 5px;
            height: 55px;
            margin: 0;
            outline: none;
            max-width: 485px;

            //   option{
            //     max-width: 480px;
            //     word-break: break-word;
            //     overflow: hidden;
            //   }
          }
        }

        .questionsLong {
          margin-top: 28px;
        }

        .analyseQuestionType {
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 35px;
          letter-spacing: 0.03em;
          color: #000000;
          padding: 10px 15px;
          min-height: 55px;
          text-align: center;

          span {
            display: block;
          }
        }

        .analyseExperience {
          padding: 10px 15px;
          max-width: 220px;
          box-sizing: border-box;
          border: 1px solid #aeb6cf;
          height: 44px;
          margin-left: auto;
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          text-align: center;
          letter-spacing: 0.03em;
          color: #18a0fb;
          min-height: 1px;
          margin-top: 6px;

          span {
            display: block;
          }
          @media screen and (max-width: $screen_540px) {
            max-width: 100%;
          }
        }

        .analyseResponse {
          font-family: "Poppins", sans-serif;
          padding: 15px;
          width: 100%;
          min-height: 190px;
          margin-top: 30px;

          .iconbox {
            display: block;
            margin-bottom: 15px;

            img {
              width: 50px;
            }
          }

          .total {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.17px;
            color: #8a94a6;
            margin-bottom: 5px;
          }

          .responseTotal {
            font-size: 20px;
            line-height: 24px;
            font-weight: 700;
            color: #181818;
            margin-bottom: 5px;
          }

          .responseTotalType {
            font-size: 14px;
            line-height: 24px;
            letter-spacing: 0.17px;
            font-weight: 400;
            color: #8a94a6;
          }
        }

        .analyseCircle {
          //   margin-top: 30px;
          height: 100%;

          .assignBtnBox {
            .assignBtn {
              background: #fff;
              padding: 8px 20px;
              font-size: 13px;
              font-weight: 400;
              line-height: 13px;
              color: #18a0fb;
              border: 1px solid #aeb6cf;
              border-radius: 4px;
              min-height: 32px;
              line-height: 14px;
              position: relative;

              @media screen and (max-width: $screen_540px) {
                margin-bottom: 20px;
              }
            }
          }

          span {
            position: absolute;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.17px;
            color: #181818;
            margin-top: 18px;
            margin-left: 24px;
          }

          @media screen and (max-width: $screen_768px) {
            &.analyseMargin {
              margin-top: 20px;
            }
          }
        }

        .analyseChart {
          width: 100%;
          height: 337px;
          margin-top: 30px;

          .lineChart {
            position: absolute;
            // margin-top: 20px;
            margin-left: 60px;
            height: 300px;
            width: 650px;
          }

          span {
            margin-top: 24px;
            margin-left: 32px;
            position: absolute;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            letter-spacing: 0.17px;
            color: #181818;
          }
        }

        .analyseChart2 {
          margin-top: 0;
          height: 100%;

          @media screen and (max-width: 991px) {
            margin: 15px 0;
          }
        }

        .analyse-answerResponse {
          display: flex;
          background: #fff;
          box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
          border-radius: 15px;
          margin: 30px 0;

          .answer-item {
            width: 50%;
            transition: all 0.2s;

            &:last-child {
              border-left: 1px solid #aeb6cf;
            }

            .listingwrap {
              display: block;
              padding-left: 0;
              margin: 0;

              .questtion-item {
                display: block;
                padding: 15px;
                border-bottom: 1px solid #aeb6cf;

                &:last-child {
                  border-bottom: none;
                }

                .questtion-tilte {
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 500;
                  letter-spacing: 0.03em;
                  color: #8a94a6;
                  cursor: pointer !important;
                  padding: 0 90px 0 15px;
                  position: relative;
                  margin-bottom: 0;

                  &::after {
                    content: "";
                    position: absolute;
                    background: url(../assets/images/check-arrow-icon.png)
                      no-repeat;
                    width: 6px;
                    height: 11px;
                    left: 0;
                    top: 5px;
                    z-index: 1;
                  }

                  mark {
                    display: inline-block;
                    background: #ffed8c;
                    opacity: 0.3;
                    line-height: 1;
                  }

                  .coutdata {
                    display: inline-block;
                    vertical-align: middle;
                    background: #fc567a;
                    width: 15px;
                    height: 15px;
                    font-size: 10px;
                    color: #fff;
                    line-height: 15px;
                    text-align: center;
                    position: absolute;
                    right: 0;
                    top: 4px;
                    box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
                    border-radius: 2px;
                    z-index: 1;
                  }
                }

                .answer-data {
                  display: none;
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 400;
                  letter-spacing: 0.03em;
                  color: #8a94a6;
                  padding: 10px 0 0 15px;

                  p {
                    margin-bottom: 15px;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }

                &.active {
                  .answer-data {
                    display: block;
                  }
                }
              }
            }

            &:last-child {
              .filterbox {
                justify-content: flex-end;
              }
            }
          }

          .filterbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 20px;
            border-bottom: 1px solid #aeb6cf;

            .title {
              font-size: 16px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.17px;
              color: #181818;
            }

            .buttonbox {
              display: flex;
              align-items: center;

              .view-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                width: 32px;
                height: 32px;
                background: #eaf6ff;
                border-radius: 2px;
                outline: none;
                border: none;
              }

              .select-field {
                padding: 0 30px 0 15px;
                background-image: url(../assets/images/downarrow.svg);
                background-repeat: no-repeat;
                background-size: 12px 6px;
                background-position: bottom 12px right 10px;
                font-size: 13px;
                font-weight: 400;
                line-height: 13px;
                color: #18a0fb;
                border: 1px solid #aeb6cf;
                border-radius: 4px;
                appearance: none;
                text-align: center;
                height: 32px;
                margin-left: 15px;
              }
            }
          }

          .assign-btn {
            background: #fff;
            padding: 8px 20px;
            font-size: 13px;
            font-weight: 400;
            line-height: 13px;
            color: #18a0fb;
            border: 1px solid #aeb6cf;
            border-radius: 4px;
            min-height: 32px;
            line-height: 14px;
          }

          .progress-list {
            list-style: none;
            padding-left: 0;
            padding: 20px;

            .progress-item {
              .progress-titleflex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;

                .progress-title {
                  width: 210px;
                  margin-right: 15px;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 18px;
                  letter-spacing: 0.03em;
                  color: #181818;
                  position: relative;
                  padding-left: 25px;

                  .progress-button {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;

                    svg {
                      width: 16px;
                      height: 10px;
                    }
                  }
                }
              }

              .progresbarflex {
                width: calc(100% - 225px);
                display: flex;
                align-items: center;
                font-size: 13px;
                font-weight: 500;
                line-height: 10px;
                text-align: center;
                letter-spacing: 0.03em;
                color: #43a0a0;

                .progress-text {
                  color: #c22447;
                }

                .progress-yellow-text {
                  color: #fca426;
                }

                .progressbar {
                  display: flex;
                  align-items: center;
                  background: #a5dfdf;
                  border-radius: 20px;
                  width: 100%;
                  height: 10px;
                  position: relative;
                  overflow: visible;
                  margin: 0 10px;
                  text-align: center;

                  .progress {
                    background: #fc567a;
                    height: 100%;
                    width: 65%;
                    overflow: visible;
                    text-align: center;
                    justify-content: center;
                    border-radius: 0;

                    &.yellow {
                      background: #fca426;
                      // width: 20%;
                      border-radius: 20px 0 0 20px;

                      .progress-sub-text {
                        color: #fca426;
                      }
                    }

                    .progress-sub-text {
                      font-size: 11px;
                      font-weight: 500;
                      color: #c22447;
                      position: relative;
                      top: -10px;
                      z-index: 1;
                    }
                  }
                }
              }

              .progress-data {
                display: none;

                .progress-data-flex {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 18px;
                  text-align: center;
                  letter-spacing: 0.03em;
                  color: #181818;
                  margin-bottom: 15px;

                  .item {
                    display: flex;
                    width: 285px;
                    margin-right: 15px;
                    text-align: left;

                    .checkbox-info {
                      display: inline-flex;
                      align-items: center;
                      position: relative;
                      margin-right: 6px;

                      input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        border-radius: 4px;
                        cursor: pointer;

                        &:checked + label::before {
                          content: "";
                          background: #9ff4a7
                            url(../assets/images/check-white-icon.svg) no-repeat;
                          background-position: center center;
                          position: absolute;
                          width: 14px;
                          height: 14px;
                          left: 0;
                          top: 2px;
                          border-color: #9ff4a7;
                        }

                        &:checked + label::after {
                          border-color: #9ff4a7;
                        }
                      }

                      label {
                        position: relative;
                        padding-left: 14px;
                        margin-bottom: 0;

                        &::after {
                          content: "";
                          position: absolute;
                          width: 14px;
                          height: 14px;
                          left: 0;
                          top: 2px;
                          border: 1px solid #8a94a6;
                          border-radius: 2px;
                        }
                      }
                    }
                  }

                  .progresbarflex {
                    width: calc(100% - 300px);
                  }
                }
              }

              &.active {
                .progress-titleflex {
                  .progress-title {
                    color: #18a0fb;

                    .progress-button {
                      svg {
                        width: 16px;
                        height: 10px;

                        path {
                          fill: #18a0fb;
                        }
                      }
                    }
                  }
                }

                .progress-data {
                  display: block;
                }
              }
            }
          }

          &.customColumnWidth{
            flex-direction: column;
            .answer-item{
                width: 100%;
                transition: all 0.2s;
            }
          }
        }
      }

      .TagsPage {
        .analyseCard {
          background: #ffffff;
          box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
          backdrop-filter: blur(19px);
          border-radius: 5px;

          @media screen and (max-width: $screen_768px) {
            margin-bottom: 20px;
          }

          .item {
            select {
              background: #f8f8f8;
              padding: 0 0 0 15px;
              background-image: url(../assets/images/arrow-downicon.svg);
              background-repeat: no-repeat;
              background-size: 12px 6px;
              background-position: bottom 22px right 12px;
              font-size: 13px;
              font-weight: 400;
              width: 100%;
              height: 55px;
              color: #8a94a6;
              border: 1px solid #18a0fb;
              border-radius: 5px;
              cursor: pointer;
              outline: none;
              box-shadow: none;
              -webkit-appearance: none;
              appearance: none;

              &.select-question {
                background: #fff url(../assets/images/arrow-downicon.svg)
                  no-repeat;
                background-size: 16px 9px;
                background-position: bottom 22px right 12px;
                padding: 10px 40px 10px 25px;
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                color: #000000;
                border: 1px solid #fff;
                box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
                border-radius: 5px;
              }
            }
          }
        }

        .customStyle {
          .analyseCard {
            background: transparent;
            box-shadow: none;

            &.analyseQuestionType {
              background: #ffffff;
              box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
              backdrop-filter: blur(19px);
              border-radius: 5px;
            }
          }
        }

        .analyseQuestionSelect {
          height: 55px;
          width: 100%;

          .analyseQuestion {
            width: 90%;
            margin-left: 10px;
            height: 100%;
            border: none;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #000000;
          }
        }

        .questionsLong {
          margin-top: 28px;
        }

        .analyseQuestionType {
          width: 100%;
          height: 55px;

          span {
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #000000;
            padding: 15px;
            position: absolute;
            width: 100%;
          }
        }

        .analyseExperience {
          //   max-width: 220px;
          //   box-sizing: border-box;
          //   background: #18A0FB;
          //   border: 1px solid #AEB6CF;
          //   height: 44px;
          //   margin-left: auto;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          background: transparent;
          box-shadow: none;

          span {
            max-width: 220px;
            box-sizing: border-box;
            background: #18a0fb;
            border: 1px solid #aeb6cf;
            height: 40px;
            margin-left: auto;
            border-radius: 3px;

            // position: absolute;
            min-width: 112px;
            // height: 24px;
            font-weight: 500;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #fff;
            // margin: 9px 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: $screen_768px) {
              height: 100%;
              min-width: 130px;
              font-size: 16px;
            }
          }
          em.bubble {
            color: #000;
            background: #fff;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            position: absolute;
            right: -3px;
            top: -2px;
            font-style: normal;
            box-shadow: 0 0 2px 0px #000;
            font-size: 12px;
          }

          .bubble {
            position: absolute;
            right: -8px;
            top: 0;
            width: 26px !important;
            height: 26px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            &-text {
              font-size: 0.875rem;
              font-weight: 600;
              position: relative;
              z-index: 10;
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              right: 1px;
              top: 1px;
            }

            span {
              min-width: unset;
              border: 0;
              position: absolute;
            }

            &-circle-1 {
              width: 26px !important;
              height: 26px !important;
              background: rgba(200, 233, 255, 0.3);
              border-radius: 50%;
              right: 0;
              top: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &-circle-2 {
              width: 24px !important;
              height: 24px !important;
              background: rgba(155, 215, 255, 0.6);
              border-radius: 50%;
              right: 1px;
              top: 1px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &-circle-3 {
              width: 20px !important;
              height: 20px !important;
              background: #9bd7ff;
              border-radius: 50%;
              right: 3px;
              top: 3px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .card {
          box-sizing: border-box;
          margin-top: 1em;
          // position: absolute;
          // width: 1192px;
          // height: 32vh;
          // left: 199px;
          // top: 273px;

          background: #ffffff;

          //   .table-responsive{
          //     .table{
          //         thead{
          //             tr{
          //                 th{
          //                     &:nth-child(1){
          //                         width: 25%;
          //                     }
          //                     &:nth-child(2){
          //                         width: 10%;
          //                     }
          //                     &:nth-child(3){
          //                         width: 10%;
          //                     }
          //                     &:nth-child(4){
          //                         width: 40%;
          //                     }
          //                 }
          //             }
          //         }
          //         tbody{
          //             tr{
          //                 td{
          //                     &:nth-child(1){
          //                         width: 25%;
          //                     }
          //                     &:nth-child(2){
          //                         width: 10%;
          //                     }
          //                     &:nth-child(3){
          //                         width: 10%;
          //                     }
          //                     &:nth-child(4){
          //                         width: 40%;
          //                     }
          //                 }
          //             }
          //         }
          //     }
          //   }
        }

        .card {
          &.opinionCard {
            padding: 1rem 1.5rem;
          }
        }

        th,
        td {
          text-align: center;
          vertical-align: middle;
          font-size: 16px;
        }

        .insightSpan {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 150% */
          color: #000000;

          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }

        .insightText {
          color: #000000;
          font-weight: 400;
          display: block;
        }

        .companyName {
          margin-left: auto;
          padding: 12px;
          border: 1px solid #18a0fb;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;

          .labelField {
            position: unset;
            text-align: center;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 500;
            line-height: 18px;
            color: #8a94a6;
          }

          @media screen and (max-width: $screen_768px) {
            margin: 20px auto 20px 25px;
          }
        }

        .progress-wrapper {
          padding-right: 20px;
          @media screen and (max-width: $screen_768px) {
            margin: 0 25px;
            padding: 0;
          }
          .progress {
            background: linear-gradient(90deg, #18a0fb 0%, #0973bb 97.66%);
            height: 25px;
            border-radius: 100px;
            padding: 3px;

            @media screen and (max-width: 991px) {
              height: 24px;
              padding: 2px;
            }
            .progress-bar {
              height: 20px;
              border-radius: 100px;
              transform: rotate(180deg);
              background-color: transparent;
            }

            .progress-bar-striped {
              background-image: linear-gradient(
                135deg,
                #f1f1f1 25%,
                transparent 25%,
                transparent 50%,
                #f1f1f1 50%,
                #f1f1f1 75%,
                transparent 75%,
                transparent
              );
            }
          }

          .insightSpan {
            font-size: 12px;
            font-weight: 500;
            color: #181818;
            margin-top: 4px;
          }
        }
      }

      .getNewRecommendationsPage {
        padding-bottom: 50px;
        .finalize-card {
          background: #ffffff;
          padding: 15px;
          width: 100%;
          min-height: 160px;
          border: 1px solid #e7e7e8 !important;
          border-radius: 8px;
          margin-bottom: 30px;
          margin-top: 0 !important;
        }

        .profanityStylePositive {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          background-color: #ffd8ea;
          color: #f04e98;
          padding: 1px 6px;
          border-radius: 2px;
        }

        .finalizeCategoryHeading {
          font-family: "Poppins";
          font-style: normal;
          font-size: 16px;
          color: #8a94a6;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0.17px;
          margin-bottom: 10px;
        }

        .customCategoryButton {
          background: #fca426;
          border-radius: 18px;
          border: #fca426;
          color: white;
        }

        .finalizeCategoryNumbers {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 800;
          font-size: 30px;
          line-height: 24px;
          color: #18a0fb;
        }

        .finalizeCategoryShortAnswerText {
          height: 24px;
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.17px;
          color: #543622;
        }

        .seeAnswers {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          letter-spacing: -0.015em;
          color: #18a0fb;
          text-decoration: underline;
          cursor: pointer;
        }

        .profanityStyleVeryNegative {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          background-color: #d4edff;
          color: #84c8fb;
          padding: 1px 6px;
          border-radius: 2px;
        }

        .profanityStyleVeryPositive {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          background-color: #ebe0ff;
          color: #9364f0;
          padding: 1px 6px;
          border-radius: 2px;
        }

        .profanityStyleNeutral {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          background-color: #ffead6;
          color: #ffbc10;
          padding: 1px 6px;
          border-radius: 2px;
        }

        .profanityStyleNegative {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          background-color: #a2f0f0;
          color: #5db0b0;
          padding: 1px 6px;
          border-radius: 2px;
        }

        .tableHeadingStyles {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          letter-spacing: 0.03em;
          color: #18a0fb;
          white-space: nowrap;
        }

        .progressBarStyles {
          width: 140px;
          height: 140px;
          margin: 0 auto;
        }

        .cart-tabs-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 10px 15px;

          @media screen and (max-width: $screen_768px) {
            flex-wrap: nowrap;
            flex-direction: column;
            align-items: center;
          }
          .tab-link {
            display: block;

            span {
              padding: 8px 8px;
              font-family: "Poppins";
              font-style: normal;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              color: #000000;
              position: relative;

              &::after {
                content: "";
                background: #18a0fb;
                position: absolute;
                width: 100%;
                height: 4px;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                border-radius: 4px;
              }

              &.linkButton {
                font-family: "Poppins";
                font-style: normal;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                color: #aeb6cf;
                cursor: pointer;

                &::after {
                  background: transparent;
                }
              }
            }
          }
        }

        .tabData-Wrap {
          display: block;
          margin-top: 10px;

          .headBox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #eff1f3;
            padding: 12px 24px;
            font-family: "Poppins", sans-serif;

            @media screen and (max-width: $screen_768px) {
              padding: 10px 16px;
              position: relative;
            }

            .title {
              font-size: 18px;
              font-weight: 600;
              color: #18a0fb;
              line-height: 27px;
              @media screen and (max-width: $screen_768px) {
                font-size: 14px;
              }
            }

            .setting-title {
              font-size: 18px;
              font-weight: 600;
              color: #18a0fb;
              line-height: 27px;
              cursor: pointer;
              margin-left: 0;
              @media screen and (max-width: $screen_768px) {
                font-size: 14px;
              }

              img {
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
              }
            }
          }

          .contentbox {
            display: block;
            padding: 20px 24px;

            @media screen and (max-width: $screen_540px) {
              padding: 20px 15px;
            }

            .menubox-wrap {
              display: flex;
              justify-content: flex-end;
              margin-bottom: 20px;

              .menus {
                padding-left: 0;
                margin-bottom: 0;

                .menu-items {
                  button {
                    padding: 6px 10px 8px;
                    font-size: 12px;
                    font-weight: 400;
                    color: #18a0fb;
                    line-height: 18px;
                    min-width: 125px;
                    border: 1px solid #18a0fb;
                    border-radius: 4px;
                    position: relative;
                    background-image: url(../assets/images/arrow-downicon.svg);
                    background-repeat: no-repeat;
                    background-size: 12px 6px;
                    background-position: bottom 11px right 12px;

                    span {
                      display: inline-block;
                      vertical-align: middle;

                      img {
                        display: inline-block;
                        vertical-align: middle;
                      }
                    }

                    .arrow {
                      display: none;
                    }
                  }

                  .dropdown {
                    min-width: 9rem;

                    .menu-items {
                      font-size: 12px;
                      font-weight: 400;
                      color: #18a0fb;
                      line-height: 18px;

                      .custom {
                        padding: 6px 10px;
                      }

                      button {
                        border: none;

                        .head {
                          display: none;
                        }
                      }
                    }
                  }
                }
              }
            }

            table {
              border: 1px solid #dee2e6;
              font-family: "Poppins";
              font-size: 14px;
              font-weight: 400;
              color: #8a94a6;
              margin-bottom: 20px;

              overflow-x: auto;
              display: table;

              @media screen and (max-width: $screen_768px) {
                display: block;
              }

              th {
                color: #000;
              }

              th {
                font-weight: 600;
                vertical-align: middle;
              }
              td {
                word-break: break-word;
              }

              img {
                display: inline-block;
                vertical-align: middle;
                border-radius: 50%;
                transition: 0.3s;

                &:hover{
                    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
                    transition: 0.3s;
                }
              }

              .customButton {
                //   padding: 6px 15px;
                padding: 6px 12px;
                font-size: 12px;
                font-weight: 400;
                color: #fff;
                //   min-width: 92px;
                min-width: 135px;
                text-align: center;
              }

              mark {
                background: rgba(#ffed8c, 0.2);
                color: #8a94a6;
                font-weight: 400;
              }
            }

            .btn-wrap {
              display: flex;
              justify-content: flex-start;
            }

            .pagination-wrapper {
              display: flex;
              justify-content: flex-end;
              padding: 15px 0;

              @media screen and (max-width: $screen_540px) {
                justify-content: center;
              }

              .pagination {
                margin: 0;

                li {
                  display: inline-block;
                  vertical-align: middle;
                  margin: 5px;
                  cursor: pointer;

                  a {
                    display: inline-block;
                    vertical-align: middle;
                    text-decoration: none;
                    background: #fff;
                    padding: 9px;
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 20px;
                    color: #8a94a6;
                    min-width: 40px;
                    text-transform: capitalize;
                    border: 1px solid #e1e4e8;
                    border-radius: 4px;
                    text-align: center;
                  }

                  &.active {
                    a {
                      color: #18a0fb;
                      border-color: #18a0fb;
                    }
                  }
                }
              }
            }
            .select__menu {
              .select__menu-list {
                .select__option {
                  > div {
                    display: flex;
                    align-items: center;

                    span {
                      input {
                        margin-top: 4px;
                        width: -moz-fit-content !important;
                        width: fit-content !important;
                      }

                      label {
                        margin: 0 0 2px 8px !important;
                      }
                    }
                  }
                }
              }
            }

            .table-footer {
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
              justify-content: space-between;
              .pagination-wrapper {
                padding: 0;
              }
            }
          }
        }

        .insightTitle {
          display: block;
          font-family: "Poppins", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #000;
          text-align: center;
        }

        .upgradeBtn {
          .btn {
            padding: 10px 20px;
            min-width: 120px;
            font-family: "Poppins";
            font-size: 13px;
            font-weight: 500;
            line-height: 15px;
            font-weight: normal;
            align-items: center;
            color: #18a0fb;
            text-align: center;
            border: 1px solid #18a0fb;
            border-radius: 3px;

            @media screen and (max-width: $screen_540px) {
              width: 100%;
            }

            &.lightblue {
              background: #18a0fb;
              color: #fff;
            }

            &.customButtonDisabled {
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }

        .finalizesearchBar {
          cursor: pointer;
          text-align: center;

          svg {
            display: inline-block;
            vertical-align: middle;
          }
        }

        .search-tooglebox {
          position: relative;
          //margin-left: 60px;
          display: inline-flex;
          align-items: center;
          width: 70%;
          height: 24px;
          input {
            background: transparent;
            border: none;
            border-bottom: 2px solid #18a0fb;
            border-radius: 0;
            width: calc(100% - 45px);
            height: 20px;
            padding: 2px 0;
            font-size: 12px;
            line-height: 18px;
            color: rgba(#18a0fb, 0.4);
            font-weight: 400;
            position: relative;
            outline: none;
            box-shadow: none;

            &:focus,
            &:hover {
              box-shadow: none;
            }
          }

          .searchBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #18a0fb;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            margin-left: 5px;
            cursor: pointer;

            svg {
              color: #fff;
              width: 20px;
            }
          }
        }

        .searchBar {
          position: relative;
          width: 180px;

          &.rightbox {
            float: right;

            @media screen and (max-width: $screen_540px) {
              width: 100%;
            }
          }

          input {
            background: #f8f8f8;
            border: 1px solid #e1e4e8;
            border-radius: 3px;
            height: 42px;
            width: 180px;
            padding: 5px 45px 5px 10px;
            font-size: 12px;
            line-height: 18px;
            color: #8a94a6;
            position: relative;

            @media screen and (max-width: $screen_540px) {
              width: 100%;
            }
          }

          .searchBtn {
            display: flex;
            justify-content: center;
            align-items: center;
            background: #18a0fb;
            position: absolute;
            right: 5px;
            top: 6px;
            width: 32px;
            height: 30px;
            border-radius: 5px;
            cursor: pointer;

            svg {
              color: #fff;
            }
          }
        }

        .customButton {
          display: inline-block;
          vertical-align: middle;
          padding: 10px 20px;
          box-sizing: border-box;
          background: #18a0fb;
          font-family: "Poppins";
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          border-radius: 20px;
          cursor: pointer;

          @media screen and (max-width: $screen_540px) {
            width: 100%;
            text-align: center;
          }

          &.rounded-none {
            border-radius: 4px;
          }

          &.customButtonDisabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }

        .newButton {
          box-sizing: border-box;
          // background: #18A0FB;
          border: 1px solid #aeb6cf;
          height: 44px;
          border-radius: 20px;

          span {
            position: absolute;
            //  width: 141px;
            height: 24px;
            font-weight: 400;
            font-size: 12px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #18a0fb;
            margin-left: 60px;
            margin-top: 9px;
          }
        }

        .darkButton {
          display: inline-block;
          background: #fca426;
          // padding: 6px 20px;
          padding: 6px 12px;
          font-size: 12px;
          font-weight: 400;
          color: #fff;
          // min-width: 92px;
          min-width: 135px;
          max-width: 135px;
          text-align: center;
          border-radius: 20px;
          position: relative;
          cursor: pointer;
          margin-right: 10px;
          .memoryclose-btn {
            background: transparent;
            padding: 0;
            position: absolute;
            top: -4px;
            right: -4px;
          }
        }

        .analyseCard {
          background: #ffffff;
          box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
          backdrop-filter: blur(19px);
          border-radius: 5px;

          .item {
            select {
              background: #f8f8f8;
              padding: 0 0 0 15px;
              background-image: url(../assets/images/arrow-downicon.svg);
              background-repeat: no-repeat;
              background-size: 12px 6px;
              background-position: bottom 22px right 12px;
              font-size: 13px;
              font-weight: 400;
              width: 100%;
              height: 55px;
              color: #8a94a6;
              border: 1px solid #18a0fb;
              border-radius: 5px;
              cursor: pointer;
              outline: none;
              box-shadow: none;
              -webkit-appearance: none;
              appearance: none;

              &.select-question {
                display: block;
                background: #fff url(../assets/images/arrow-downicon.svg)
                  no-repeat;
                background-size: 16px 9px;
                background-position: bottom 22px right 12px;
                width: 100%;
                padding: 10px 40px 10px 15px;
                font-family: "Poppins", sans-serif;
                font-size: 18px;
                font-weight: 500;
                line-height: 27px;
                letter-spacing: 0.03em;
                color: #000000;
                border: 1px solid #fff;
                appearance: none;
                box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
                border-radius: 5px;
                height: 55px;
                margin: 0;
                outline: none;
              }
            }
          }
        }

        .analyseQuestionSelect {
          height: 55px;
          width: 100%;

          .analyseQuestion {
            width: 90%;
            margin-left: 10px;
            height: 100%;
            border: none;
            font-weight: 500;
            font-size: 18px;
            line-height: 27px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #000000;
          }
        }

        .questionsLong {
          margin-top: 28px;
        }

        .analyseQuestionType {
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 35px;
          letter-spacing: 0.03em;
          color: #000000;
          padding: 10px 15px;
          min-height: 55px;
          text-align: center;

          span {
            display: block;
          }
        }

        .analyseExperience {
          max-width: 220px;
          box-sizing: border-box;
          background: #18a0fb;
          border: 1px solid #aeb6cf;
          height: 44px;
          margin-left: auto;

          span {
            position: absolute;
            width: 141px;
            height: 24px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #fff;
            margin: 9px 40px;
          }
        }

        .card {
          background: #ffffff;
          border: none;
          box-sizing: border-box;
          border-radius: 5px;
          margin-top: 20px;

          &.common-space {
            padding: 15px;

            .title {
              font-family: "Poppins";
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              color: #000;
              margin-bottom: 20px;
            }
          }
        }

        // th,
        // td {
        //   text-align: center;
        //   vertical-align: middle;
        // }
        .circleSpan {
          position: absolute;
          left: 6em;
          top: 5em;

          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 24px;
          /* identical to box height, or 150% */

          color: #000000;
        }

        .insightSpan {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 24px;
          color: #000000;
        }

        .lightSpan {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          /* identical to box height, or 150% */
          color: #aeb6cf;
        }
      }

      .audienceAnalysisPage {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 40px;

        .customStyle {
          width: 100%;
        }

        .analyseQuestionSelect {
          .analyseQuestion {
            display: block;
            background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
            background-size: 16px 9px;
            background-position: bottom 22px right 12px;
            width: 100%;
            padding: 10px 40px 10px 15px;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #000000;
            border: 1px solid #fff;
            appearance: none;
            box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
            border-radius: 5px;
            height: 55px;
            margin: 0;
            outline: none;
          }
        }

        .questionsLong {
          margin-top: 28px;
        }

        .analyseQuestionType {
          font-family: "Poppins", sans-serif;
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0.03em;
          color: #000000;
          padding: 10px 15px;
          min-height: 55px;
          text-align: center;
        }

        .analyseExperience {
          max-width: 220px;
          box-sizing: border-box;
          border: 1px solid #aeb6cf;
          height: 44px;
          margin-left: auto;

          span {
            position: absolute;
            width: 141px;
            height: 24px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #18a0fb;
            margin: 9px 40px;
          }
        }

        .analyse-answerResponse {
          display: flex;
          background: #fff;
          box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
          border-radius: 15px;
          margin: 30px 0;

          .answer-item {
            width: 50%;

            &:last-child {
              border-left: 1px solid #aeb6cf;
            }

            .listingwrap {
              display: block;
              padding-left: 0;
              margin: 0;

              .questtion-item {
                display: block;
                padding: 15px;
                border-bottom: 1px solid #aeb6cf;

                &:last-child {
                  border-bottom: none;
                }

                .questtion-tilte {
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 500;
                  letter-spacing: 0.03em;
                  color: #8a94a6;
                  cursor: pointer !important;
                  padding: 0 90px 0 15px;
                  position: relative;
                  margin-bottom: 0;

                  &::after {
                    content: "";
                    position: absolute;
                    background: url(../assets/images/check-arrow-icon.png)
                      no-repeat;
                    width: 6px;
                    height: 11px;
                    left: 0;
                    top: 5px;
                    z-index: 1;
                  }

                  mark {
                    display: inline-block;
                    background: #ffed8c;
                    line-height: 1;
                    opacity: 0.3;
                  }

                  .coutdata {
                    display: inline-block;
                    vertical-align: middle;
                    background: #fc567a;
                    width: 15px;
                    height: 15px;
                    font-size: 10px;
                    color: #fff;
                    line-height: 15px;
                    text-align: center;
                    position: absolute;
                    right: 0;
                    top: 4px;
                    box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
                    border-radius: 2px;
                    z-index: 1;
                  }
                }

                .answer-data {
                  display: none;
                  font-size: 12px;
                  line-height: 18px;
                  font-weight: 400;
                  letter-spacing: 0.03em;
                  color: #8a94a6;
                  padding: 10px 0 0 15px;

                  p {
                    margin-bottom: 15px;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }
                }

                &.active {
                  .answer-data {
                    display: block;
                  }
                }
              }
            }

            &:last-child {
              .filterbox {
                justify-content: flex-end;
              }
            }
          }

          .filterbox {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 15px 20px;
            border-bottom: 1px solid #aeb6cf;

            .title {
              font-size: 16px;
              font-weight: 400;
              line-height: 16px;
              letter-spacing: 0.17px;
              color: #181818;
              min-width: 150px;
            }

            .buttonbox {
              display: flex;
              align-items: center;

              .view-btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                width: 32px;
                height: 32px;
                background: #eaf6ff;
                border-radius: 2px;
                outline: none;
                border: none;
              }

              .select-field {
                padding: 0 30px 0 15px;
                background-image: url(../assets/images/downarrow.svg);
                background-repeat: no-repeat;
                background-size: 12px 6px;
                background-position: bottom 12px right 10px;
                font-size: 13px;
                font-weight: 400;
                line-height: 13px;
                color: #18a0fb;
                border: 1px solid #aeb6cf;
                border-radius: 4px;
                appearance: none;
                text-align: center;
                height: 32px;
                margin-left: 15px;
              }
            }
          }

          .assign-span {
            // background: #fff;
            padding: 8px 20px;
            font-size: 13px;
            font-weight: 400;
            line-height: 13px;
            // color: #18A0FB;
            // border: 1px solid #AEB6CF;
            // border-radius: 4px;
            min-height: 32px;
            line-height: 14px;
          }

          .progress-list {
            list-style: none;
            padding-left: 0;
            padding: 20px;

            .progress-item {
              .progress-titleflex {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;

                .progress-title {
                  width: 210px;
                  margin-right: 15px;
                  font-weight: 400;
                  font-size: 15px;
                  line-height: 18px;
                  letter-spacing: 0.03em;
                  color: #181818;
                  position: relative;
                  padding-left: 25px;

                  .progress-button {
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;

                    svg {
                      width: 16px;
                      height: 10px;
                    }
                  }
                }
              }

              .progresbarflex {
                width: calc(100% - 225px);
                display: flex;
                align-items: center;
                font-size: 13px;
                font-weight: 500;
                line-height: 10px;
                text-align: center;
                letter-spacing: 0.03em;
                color: #43a0a0;

                .progress-text {
                  color: #c22447;
                }

                .progress-yellow-text {
                  color: #fca426;
                }

                .progressbar {
                  display: flex;
                  align-items: center;
                  background: #a5dfdf;
                  border-radius: 20px;
                  width: 100%;
                  height: 10px;
                  position: relative;
                  overflow: visible;
                  margin: 0 10px;
                  text-align: center;

                  .progress {
                    background: #fc567a;
                    height: 100%;
                    width: 65%;
                    overflow: visible;
                    text-align: center;
                    justify-content: center;
                    border-radius: 0;

                    &.yellow {
                      background: #fca426;
                      // width: 20%;
                      border-radius: 20px 0 0 20px;

                      .progress-sub-text {
                        color: #fca426;
                      }
                    }

                    .progress-sub-text {
                      font-size: 11px;
                      font-weight: 500;
                      color: #c22447;
                      position: relative;
                      top: -10px;
                      z-index: 1;
                    }
                  }
                }
              }

              .progress-data {
                display: none;

                .progress-data-flex {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  font-size: 13px;
                  font-weight: 400;
                  line-height: 18px;
                  text-align: center;
                  letter-spacing: 0.03em;
                  color: #181818;
                  margin-bottom: 15px;

                  .item {
                    display: flex;
                    width: 285px;
                    margin-right: 15px;
                    text-align: left;

                    .checkbox-info {
                      display: inline-flex;
                      align-items: center;
                      position: relative;

                      input {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        opacity: 0;
                        z-index: 1;
                        border-radius: 4px;
                        cursor: pointer;

                        &:checked + label::before {
                          content: "";
                          background: #9ff4a7
                            url(../assets/images/check-white-icon.svg) no-repeat;
                          background-position: center center;
                          position: absolute;
                          width: 14px;
                          height: 14px;
                          left: 0;
                          top: 2px;
                          border-color: #9ff4a7;
                          z-index: 11;
                        }
                      }

                      label {
                        position: relative;
                        padding-left: 20px;
                        margin-bottom: 0;

                        &::after {
                          content: "";
                          position: absolute;
                          width: 14px;
                          height: 14px;
                          left: 0;
                          top: 2px;
                          border: 1px solid #8a94a6;
                          border-radius: 2px;
                          z-index: 1;
                        }
                      }
                    }
                  }

                  .progresbarflex {
                    width: calc(100% - 300px);
                  }
                }
              }

              &.active {
                .progress-titleflex {
                  .progress-title {
                    color: #18a0fb;

                    .progress-button {
                      svg {
                        width: 16px;
                        height: 10px;

                        path {
                          fill: #18a0fb;
                        }
                      }
                    }
                  }
                }

                .progress-data {
                  display: block;
                }
              }
            }
          }
          &.analyseChartBox {
            display: block;

            .analyseChart-content {
              display: flex;

              @media screen and (max-width: 991px) {
                flex-direction: column;
              }

              .analyseChart-piechart {
                position: absolute;
                top: -9999px;
                opacity: 0;
                visibility: hidden;
              }

              &.fixedHeightColumn {
                justify-content: space-between;
                align-items: flex-start;
                height: 450px;

                @media screen and (max-width: 991px) {
                  flex-direction: column;
                  height: auto;
                }

                .analyseChart-content {
                  flex-direction: column;
                  width: 50%;
                  height: 450px;
                  overflow: auto;

                  @media screen and (max-width: 991px) {
                    width: 100%;
                    height: auto;
                    overflow: unset;
                    margin-bottom: 20px;
                  }

                  .answer-item {
                    width: 100%;
                    border: 0;
                  }
                }

                .analyseChart-piechart {
                  width: 45%;
                  height: 100%;
                  position: relative;
                  top: unset;
                  opacity: 1;
                  visibility: visible;

                  @media screen and (max-width: 991px) {
                    width: 100%;
                    height: 450px;
                  }

                  .analyseCard.analyseCircle {
                    box-shadow: none;
                    height: 100%;
                    padding: 0;
                    margin: 0;

                    @media screen and (max-width: 991px) {
                      padding: 20px;
                    }

                    canvas {
                      height: 90% !important;
                      width: auto !important;
                      margin: auto;

                      @media screen and (max-width: $screen_540px) {
                        height: 70% !important;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .customSelect {
          position: absolute;
          width: 73px;
          height: 12px;
          font-weight: 400;
          font-size: 10.2375px;
          line-height: 13px;
          // color: #18A0FB;
          top: 16.4px;
          right: 16px;
          // left: 7px;
          // float: right;
          background: #f8f8f8;
          // padding: 0 40px 0 15px;
          background-image: url(../assets/images/arrow-downicon.svg);
          background-repeat: no-repeat;
          background-size: 12px 6px;
          background-position: bottom 11px right 8px;
          // font-size: 13px;
          // font-weight: 400;
          // width: 100
          height: 30px;
          color: #8a94a6;
          border: 1px solid #18a0fb;
          border-radius: 5px;
          cursor: pointer;
          outline: none;
          box-shadow: none;
          -webkit-appearance: none;
          appearance: none;

          &.select-question {
            background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
            background-size: 16px 9px;
            background-position: bottom 22px right 12px;
            padding: 10px 40px 10px 25px;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #000000;
            border: 1px solid #fff;
            box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
            border-radius: 5px;
          }
        }

        .customSelectWithIcon {
          position: absolute;
          width: 100%;
          height: 100%;
          font-weight: 400;
          top: 16.4px;
          right: 24px;

          background: #f8f8f8;

          color: #8a94a6;
        }

        .analyseCard {
          background: #ffffff;
          box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
          backdrop-filter: blur(19px);
          border-radius: 5px;
          padding: 15px;
        }

        .analyse-filter-box {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            font-size: 16px;
            font-weight: 500;
            color: #181818;
            padding-right: 1rem;
          }

          .select-items {
            display: flex;
            align-items: center;

            .item {
              margin-right: 10px;
              min-width: 120px;

              &:last-child {
                margin-right: 0;
              }

              .css-yk16xz-control {
                border-color: #18a0fb;
                color: #18a0fb;

                .css-1uccc91-singleValue {
                  color: #18a0fb;
                }
              }

              select {
                padding: 7px 35px 7px 10px;
                background-image: url(../assets/images/arrow-downicon.svg);
                background-repeat: no-repeat;
                background-size: 12px 6px;
                background-position: bottom 11px right 12px;
                font-family: "Poppins", sans-serif;
                font-size: 13px;
                font-weight: 400;
                width: 100%;
                height: 32px;
                min-width: 125px;
                color: #18a0fb;
                border: 1px solid #18a0fb;
                border-radius: 4px;
                cursor: pointer;
                outline: none;
                box-shadow: none;
                appearance: none;
              }
            }

            &.select-items-flex {
              flex: 1;
              justify-content: flex-end;
              gap: 1rem;
              .item {
                min-width: unset;
                flex: 50%;
                max-width: 50%;
                margin: 0;
                // > div{
                //     min-width: 130px;
                // }
                // &.item-country{
                //     margin-right: 0;
                //     width: 100%;
                //     > div{
                //         min-width: 100%;
                //     }
                // }
              }
            }
          }
        }

        .analyseQuestionSelect {
          .analyseQuestion {
            display: block;
            background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
            background-size: 16px 9px;
            background-position: bottom 22px right 12px;
            width: 100%;
            padding: 10px 40px 10px 15px;
            font-family: "Poppins", sans-serif;
            font-size: 18px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.03em;
            color: #000000;
            border: 1px solid #fff;
            appearance: none;
            box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
            border-radius: 5px;
            height: 55px;
            margin: 0;
            outline: none;
          }
        }

        .questionsLong {
          margin-top: 28px;
        }

        .analyseQuestionType {
          width: -moz-fit-content;
          width: fit-content;
          @media screen and (max-width: $screen_768px) {
            width: 100%;
          }
          span {
            display: block;
            white-space: nowrap;
          }
        }

        .analyseMailDropdown {
          margin-left: auto;
          border: 1px solid #18a0fb;
          border-radius: 3px;
          width: -moz-fit-content;
          width: fit-content;
          min-width: 160px;

          @media screen and (max-width: $screen_540px) {
            width: 100%;
          }

          .mailDropdownButton {
            > div {
              border: 0;
              box-shadow: none;
            }
            .css-yk16xz-control {
              background: transparent;
            }
            .css-2613qy-menu {
              box-shadow: 0 0 1px #18a0fb;
            }
          }
        }

        .analyseMailUser {
          box-sizing: border-box;
          border: 1px solid #aeb6cf;
          width: 130px;
          height: 41px;
          margin-left: 394px;

          span {
            position: absolute;
            width: 63px;
            height: 21px;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            color: #18a0fb;
            margin-top: 10px;
            margin-left: 34px;
          }
        }

        .googleGeoChart {
          margin-top: 4.4em;
        }

        .barChart {
          min-height: 500px;
          height: 100%;

          .analyse-filter-box {
            @media screen and (max-width: $screen_1368px) {
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              .select-items-flex {
                width: 100%;
                // .item{
                //     flex: 0 0 50%;
                // }
              }
            }
          }
        }
      }

      .surveyEffectiveness {
        display: block;
        padding-bottom: 20px;

        .analyseCard {
          font-family: "Poppins", sans-serif;
          font-size: 16px;
          font-weight: 400;
          padding: 15px;
          background: #ffffff;
          box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
          backdrop-filter: blur(19px);
          border-radius: 10px;
          margin-bottom: 20px;

          .analyse-filter-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 10px;

            @media screen and (max-width: $screen_768px) {
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 0;
            }

            .title {
              font-size: 16px;
              font-weight: 500;
              color: #181818;

              @media screen and (max-width: $screen_768px) {
                margin-bottom: 0 !important;
              }
            }

            .select-items {
              display: flex;
              align-items: center;

              @media screen and (max-width: $screen_768px) {
                margin-top: 10px;
                width: 100%;
              }

              .menus {
                display: block;
                padding-left: 0;
                padding-right: 0;
                margin-bottom: 0;
                margin-right: 10px;

                @media screen and (max-width: $screen_768px) {
                  width: 50%;
                }

                .menu-items {
                  button {
                    padding: 6px 10px;
                    font-size: 13px;
                    font-weight: 400;
                    color: #18a0fb;
                    line-height: 18px;
                    min-width: 125px;
                    border: 1px solid #18a0fb;
                    border-radius: 4px;
                    position: relative;
                    background-image: url(../assets/images/arrow-downicon.svg);
                    background-repeat: no-repeat;
                    background-size: 12px 6px;
                    background-position: bottom 11px right 12px;

                    .arrow {
                      display: none;
                    }

                    img {
                      object-fit: contain;
                      height: 18px !important;
                      margin: auto !important;
                    }
                  }

                  .dropdown {
                    min-width: 9rem;
                    padding: 0;

                    .menu-items {
                      font-size: 13px;
                      font-weight: 400;
                      color: #18a0fb;
                      line-height: 18px;

                      .custom {
                        padding: 6px 10px;

                        &:hover {
                          background-color: #f2f2f2;
                          color: #18a0fb;
                        }
                      }

                      button {
                        border: none;
                        color: #000;
                        background-image: url(../assets/images/arrow-right.svg);
                        background-size: 9px;

                        &:hover {
                          color: #18a0fb;
                        }

                        .head {
                          display: none;
                        }
                      }

                      &:first-child {
                        .custom {
                          border-top-left-radius: 0.5rem;
                          border-top-right-radius: 0.5rem;
                        }
                      }
                      &:last-child {
                        .custom {
                          border-bottom-left-radius: 0.5rem;
                          border-bottom-right-radius: 0.5rem;
                        }
                      }
                    }
                  }
                }
              }

              .item {
                margin-right: 10px;

                @media screen and (max-width: $screen_768px) {
                  width: 50%;
                }

                //   &:last-child {
                //     margin-right: 0;
                //   }

                select {
                  padding: 7px 35px 7px 10px;
                  background-image: url(../assets/images/arrow-downicon.svg);
                  background-repeat: no-repeat;
                  background-size: 12px 6px;
                  background-position: bottom 11px right 12px;
                  font-family: "Poppins", sans-serif;
                  font-size: 13px;
                  font-weight: 400;
                  width: 100%;
                  height: 32px;
                  min-width: 125px;
                  color: #18a0fb;
                  border: 1px solid #18a0fb;
                  border-radius: 4px;
                  cursor: pointer;
                  outline: none;
                  box-shadow: none;
                  appearance: none;
                }
              }
            }
          }
        }
      }

      .dataraw-wrap {
        .dataraw-filter {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-family: "Poppins", sans-serif;
          font-weight: 400;

          .item {
            margin-bottom: 20px;

            &:first-child {
              width: 390px;
            }

            &:last-child {
              display: flex;

              .formgroup {
                margin-right: 15px;

                &:last-child {
                  margin-right: 0;
                }
              }
            }

            select {
              background: #f8f8f8;
              padding: 0 40px 0 15px;
              background-image: url(../assets/images/arrow-downicon.svg);
              background-repeat: no-repeat;
              background-size: 12px 6px;
              background-position: bottom 16px right 12px;
              font-size: 13px;
              font-weight: 400;
              width: 100%;
              height: 40px;
              color: #8a94a6;
              border: 1px solid #18a0fb;
              border-radius: 5px;
              cursor: pointer;
              outline: none;
              box-shadow: none;
              -webkit-appearance: none;
              appearance: none;

              &.select-question {
                background: #fff url(../assets/images/arrow-downicon.svg)
                  no-repeat;
                background-size: 16px 9px;
                background-position: bottom 16px right 12px;
                padding: 10px 40px 10px 15px;
                font-size: 15px;
                font-weight: 500;
                line-height: 20px;
                height: 40px;
                letter-spacing: 0.03em;
                text-align: left;
                color: #000000;
                border: 1px solid #fff;
                box-shadow: -4px 3px 10px rgba(143, 155, 186, 0.2);
                border-radius: 5px;
              }
            }
          }

          .search-box {
            position: relative;
            margin-top: 3px;

            input {
              display: block;
              background: #f8f8f8;
              padding: 15px 55px 15px 15px;
              width: 100%;
              min-width: 250px;
              height: 40px;
              font-size: 14px;
              font-weight: 300;
              color: #aeb6cf;
              border: 1px solid #aeb6cf;
              border-radius: 5px;

              &::placeholder {
                vertical-align: middle;
              }
            }

            .btn {
              display: flex;
              justify-content: center;
              align-items: center;
              background: #18a0fb;
              padding: 0;
              width: 30px;
              height: 30px;
              position: absolute;
              right: 5px;
              top: 5px;
              z-index: 1;
              border-radius: 5px;
            }
          }
        }

        .dataraw-list {
          display: flex;
          flex-wrap: wrap;
          align-items: flex-start;
          margin: 0 -15px;
          font-family: "Poppins", sans-serif;
          font-weight: 400;

          .dataraw-item {
            width: 25%;
            padding: 0 15px;
            margin-bottom: 25px;

            .dataraw-box {
              background: #ffffff;

              .flexbox {
                background: #eff1f3;
                display: flex;
                //   align-items: center;
                align-items: flex-start;
                justify-content: space-between;
                //   padding: 10px 25px;
                padding: 10px 25px 10px 50px;
                position: relative;
                overflow: hidden;
                flex-direction: column;

                .title {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 24px;
                  color: #18a0fb;
                  margin-bottom: 0;
                  min-height: 24px;
                }

                .date-info {
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 24px;
                  color: #181818;
                }

                .tagmark {
                  background: #9ff4a7;
                  padding: 15px;
                  border: 1px solid #9ff4a7;
                  position: absolute;
                  left: -12px;
                  top: -10px;
                  bottom: 0;
                  transform: skew(-50deg);
                  z-index: 1;

                  &.tagmark-true {
                    background: #fca426;
                    border: 1px solid #fca426;

                    span {
                      &::after {
                        content: "True";
                      }
                    }
                  }

                  span {
                    display: block;
                    text-align: center;
                    transform: skew(50deg);
                    position: relative;

                    &::after {
                      content: "False";
                      position: absolute;
                      -webkit-transform: rotate(315deg);
                      transform: rotate(315deg);
                      font-size: 12px;
                      font-weight: 400;
                      color: #181818;
                      top: 3px;
                      left: -31px;
                      z-index: -1;
                    }
                  }
                }
              }

              .content-box {
                padding: 15px;
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 0.03em;
                color: #8a94a6;
                height: 300px;

                @media screen and (max-width: 576px){
                    height: auto;
                }

                .info-list {
                  display: inline-block;
                  vertical-align: middle;
                  list-style: none;
                  padding-left: 0;
                  margin: 0 0 10px 0;

                  li {
                    display: inline-block;
                    vertical-align: middle;
                    background: #d3eeff;
                    padding: 10px;
                    font-size: 10px;
                    font-weight: 400;
                    color: #181818;
                    line-height: 16px;
                    border-radius: 3px;
                    margin: 0 10px 10px 0;
                    position: relative;

                    .icon-close {
                      //   display: block;
                      position: absolute;
                      width: 10px;
                      right: 0;
                      top: 0;
                      z-index: 1;
                      display: flex;
                      align-items: center;
                      justify-content: center;

                      svg {
                        display: inline-block;
                        vertical-align: middle;
                        width: 10px;
                        height: 10px;
                      }
                    }
                  }
                }

                p {
                  line-height: 24px;
                }
                .content-item{
                  height: 100%;
                  overflow: auto;
                }
              }
            }
          }

          &.dataNotFound {
            margin: 0;
            justify-content: center;

            .dataNotFound--text {
              font-size: 20px;
              padding: 10px 0;
              margin-top: 50px;
              font-weight: 500;
            }
          }
        }

        .pagination-wrapper {
          padding: 15px 0;

          .pagination {
            margin: 0;

            li {
              display: inline-block;
              vertical-align: middle;
              margin: 5px;
              cursor: pointer;

              a {
                display: inline-block;
                vertical-align: middle;
                text-decoration: none;
                background: #fff;
                padding: 9px;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #8a94a6;
                min-width: 40px;
                text-transform: capitalize;
                border: 1px solid #e1e4e8;
                border-radius: 4px;
                text-align: center;
              }

              &.active {
                a {
                  color: #18a0fb;
                  border-color: #18a0fb;
                }
              }
            }
          }
        }
      }
    }
  }

  &.CreateFormWrapper {
    .surveySubMenu {
      .pageLogicItem {
        @media screen and (max-width: $screen_1024px) {
          margin: 0 0 20px 16px;
          border: 1px solid #18a0fb;
          width: 184px;
          .itemInner {
            svg {
              margin-left: 0px !important;
              text {
                font-size: 20px !important;
                fill: #181818 !important;
              }
            }
          }
        }
      }
    }
  }
}

.questionQuePopup {
  width: 430px;

  h3 {
    padding: 5px 15px 10px 15px;
    border-bottom: 1px solid #ccc;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
    margin: 0;
  }

  .innerPopup {
    width: 100%;
    padding: 10px 5px;

    h4 {
      font-weight: 500;
      font-size: 10px;
      line-height: 15px;
      color: #8a94a6;
      margin-bottom: 10px;
    }

    .items {
      display: flex;
      flex-wrap: wrap;
      align-content: center;

      .item {
        width: 33.33%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        cursor: pointer;
        position: relative;

        &.questionHelp{
            img{
                width: 18px;
                margin: 5px 10px;
                height: auto;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
            }
        }

        &.active {
          background: #e1e4e8;
          border-radius: 5px;
        }

        &:hover {
          background: #e1e4e8;
          border-radius: 5px;
        }

        .ai {
          display: none;
          position: absolute;
          right: 40px;
          top: -3px;
          font-weight: bold;
          font-size: 12px;
        }

        &:first-child {
          .ai {
            display: block;
          }
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: #181818;
          margin: 0;
          margin-top: 8px;
        }
      }
    }
  }
}

ul {
  li {
    .sortAnswerLabel {
      font-size: 10px;
      line-height: 17px;
      left: 13px;
      top: 0;
      text-overflow: ellipsis;
      overflow: hidden;
      position: relative;
      width: calc(100% - 4px);
      text-align: left;

      @media screen and (max-width: 600px) {
        top: -8px;
      }
    }

    .sortAnswerIcon {
      width: 23px;
      position: absolute;
      top: 6px;
      left: 5px;
      height: auto;

      &.pictureIcon {
        width: 22px !important;
        height: auto !important;
        padding: 5px;
        padding-top: 4px;
      }
    }
  }
}

.questionDropDown {
  .MuiPaper-root {
    border-radius: 5px !important;
    background-color: transparent !important;
  }
  ul {
    padding: 0;
    border: 2px solid #ccc;
    border-radius: 5px;
    background-color: #fff;

    li {
      font-size: 12px;
    }
  }
  &.DashboardList {
    ul {
      min-width: 130px;
      li {
        color: #8a94a6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 6px 6px 10px;

        @media screen and (max-width: $screen_768px) {
          min-height: 35px;
        }
        svg {
          float: right;
          font-size: 19px;
        }
        &:hover {
          color: #18a0fb;
          svg {
            color: #18a0fb;
          }
        }
      }
    }
  }
}

.pictureIcon {
  width: 37px;
  height: auto;
  padding: 11px;
}

.imageWrapInner {
  // padding: 50px 30px;
  padding: 20px;
  height: 100%;
  width: 100%;
  overflow-y: auto;

  @media screen and (max-width: 1024px) {
    padding: 0;
  }

  h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #8a94a6;
  }

  em {
    //   font-weight: lighter;
    font-size: 14px;
    word-break: break-word;

    input {
      font-size: 14px;
      border: none;
      height: 40px;
      padding-left: 5px;
      width: 100%;
      color: #181818;

      &:focus-visible,
      &:focus {
        outline: none;
      }

      &::placeholder {
        line-height: 18px;
      }
    }
  }

  .questionWrap {
    .question {
      display: flex;
      align-items: flex-start;
      width: 100%;

      .num {
        line-height: 24px;
        padding: 0;
        font-style: italic;
        color: #8e98a9;
        min-width: 10px;
      }

      label {
        margin-bottom: 0;
        width: 100%;
        position: relative;

        textarea {
          font-weight: 400;
          line-height: 18px;
          height: auto;
          // min-height: 42px;
          min-height: 75px;
          font-family: "Open Sans";
          font-size: 16px;
          color: #8a94a6;
          width: 100%;

          &::placeholder {
            font-weight: 300;
            font-style: italic;
            font-size: 16px;
            line-height: 18px;
            color: #8a94a6;
          }
        }
      }
    }
  }

  .imageUploadWrap {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    height: calc(100% - 170px);
    overflow: auto;

    .pictureVertical {
      display: block;

      .hqgcXe {
        display: block;

        &:before {
          padding-top: 0;
        }
      }
    }

    &.imageUploadItems {
      .imageUpload {
        min-height: 142px;
      }
      .imageUploadCard {
        min-height: 100px;
      }
      .pictureVertical {
        > div {
          &:before {
            display: none;
          }
          .imageUpload {
            min-height: 142px;
          }
          .imageUploadCard {
            min-height: 100px;
          }
        }
      }
    }
  }

  .imageUpload {
    margin-top: 15px;
    margin-right: 10px;
    width: 150px;
    padding: 5px;
    background: rgba(24, 160, 251, 0.2);
    border-radius: 5px;
    position: relative;

    svg {
      color: #18a0fb;
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .inner {
      position: relative;
      height: 98px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.uploaded {
      border: 1px solid #18a0fb;

      .cancel {
        display: none;
        cursor: pointer;
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 5;

        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          top: 8px;
          right: 7px;
          background: #fff;
          z-index: -1;
        }
      }

      input[type="text"] {
        height: 30px;
        width: 100%;
        z-index: 1;
        background: transparent;
        border: none;
        border-radius: 5px;
        padding: 0 0 0 10px;
        line-height: normal;
        font-size: 14px;

        &::placeholder {
          position: relative;
          top: 0;
          left: 0;
          color: #7ec1ff;
          font-size: 14px;
          text-align: left;
        }

        &:focus,
        &:focus-visible {
          border: none;
          outline: none;
        }
      }

      .inner {
        height: 110px;

        img {
          // max-width: 100%;
          // max-height: 100%;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          border-radius: 5px;
          transform: translate(-50%, -50%);
          object-fit: cover;
        }

        svg {
          display: none;
        }
      }

      &:hover {
        .cancel {
          display: block;
        }
      }
    }

    &.superSize {
      width: 300px;
      height: 240px;

      .inner {
        height: 210px;
      }
    }
  }
  .imageUploadCard {
    margin-top: 15px;
    margin-right: 10px;
    width: 150px;
    padding: 5px;
    background: rgba(24, 160, 251, 0.2);
    border-radius: 5px;
    position: relative;

    svg {
      color: #18a0fb;
    }

    input[type="file"] {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
    }

    .inner {
      position: relative;
      height: 98px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.uploaded {
      border: 1px solid #18a0fb;

      .cancel {
        display: none;
        cursor: pointer;
        position: absolute;
        top: -10px;
        right: -10px;
        z-index: 5;

        &:after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          top: 8px;
          right: 7px;
          background: #fff;
          z-index: -1;
        }
      }

      input[type="text"] {
        height: 30px;
        width: 100%;
        z-index: 1;
        background: transparent;
        border: none;
        border-radius: 5px;
        padding: 0 0 0 10px;
        line-height: normal;
        font-size: 14px;

        &::placeholder {
          position: relative;
          top: 0;
          left: 0;
          color: #7ec1ff;
          font-size: 14px;
          text-align: left;
        }

        &:focus,
        &:focus-visible {
          border: none;
          outline: none;
        }
      }

      .inner {
        height: 110px;

        img {
          // max-width: 100%;
          // max-height: 100%;
          width: 100%;
          height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          border-radius: 5px;
          transform: translate(-50%, -50%);
          object-fit: cover;
        }

        svg {
          display: none;
        }
      }

      &:hover {
        .cancel {
          display: block;
        }
      }
    }

    &.superSize {
      width: 300px;
      height: 240px;

      .inner {
        height: 210px;
      }
    }
  }

  .btnGroup {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 25px 0;

    &.btnPadding {
      padding: 0;
    }

    @media screen and (max-width: 1024px) {
      padding: 20px 0 0 0;
    }

    .btnSave {
      margin-left: 0 !important;
      margin-bottom: 0 !important;
    }
  }
}

.ratingSetting {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 30px;

  select,
  input[type="color"] {
    width: auto;
    border: 1px solid #8a94a6;
    height: 34px;
    padding: 5px;
    font-size: 14px;
    line-height: 21px;
    color: #8a94a6;
    min-width: 70px;
  }
}

.ratingInner {
  margin: 40px 0;

  @media screen and (max-width: $screen_540px){
    margin: 20px 0;
  }

  .rs-rate{
    .rs-rate-character{
        .rs-rate-character-before{
            width: 100%;
        }
        @media screen and (max-width: $screen_540px){
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }
    }
  }

  svg {
    width: 30px;
    height: 30px;
    margin: 0 5px 0 0;
    @media screen and (max-width: $screen_540px){
        &.rs-icon{
            width: 20px;
            height: 20px;
        }
    }
  }

  .decoratorImage{
    .imageBottom{
        img{
            width: 100%;
            height: 80px;
        }
    }
  }
}

.multipleInner {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  padding: 20px 0;
  max-height: calc(100% - 130px);
  overflow: auto;

  .item {
    background: rgba(24, 160, 251, 0.2);
    border-radius: 3px;
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
    width: 24%;
    height: 32px;
    border: 1px solid transparent;
    display: flex;
    align-items: center;

    .Option {
      position: absolute;
      width: 21px;
      height: 21px;
      left: 5px;
      top: 5px;
      background: #ffffff;
      border-radius: 2px;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #18a0fb;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    input {
      border: none;
      padding-left: 35px;
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #18a0fb;
      background: transparent;
      width: 100%;
      padding-right: 22px;

      &:focus-visible,
      &:focus {
        outline: none;
      }

      &::placeholder {
        font-style: italic;
        font-weight: 300;
        font-size: 12px;
        line-height: 12px;
        color: #a3daff;
        margin-top: -5px;
        padding-left: 30px;
        text-transform: capitalize;
      }
    }

    .cancel {
      display: none;
      position: absolute;
      top: -12px;
      right: -5px;
      z-index: 0;
      cursor: pointer;

      &:after {
        content: "";
        position: absolute;
        top: 8px;
        left: 3px;
        background: #fff;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        z-index: -1;
      }

      svg {
        color: #18a0fb;
        font-size: 17px;
        border-radius: 50%;
      }
    }

    &:hover {
      border-color: #18a0fb;

      .cancel {
        display: block;
      }
    }

    .selectedIcon {
      position: absolute;
      right: 4px;
      top: 4px;
      background: rgba(255, 255, 255, 0.5);
      border-radius: 3px;
      height: 23px;
      width: 23px;
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 21px;
        color: #18a0fb;
      }
    }
  }

  &.yesNoInner {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 20px 0;
    flex-direction: column;

    .item {
      margin-bottom: 10px;
      width: 30%;

      @media screen and (max-width: $screen_540px) {
        width: 65%;
      }
    }
  }
}

.dropDownInner {
  width: 100%;
  margin-top: 30px;

  .selectWrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .selectInner {
      width: calc(100% - 130px);

      .autoSelectWrap {
        margin-top: -10px;

        label {
          font-style: italic !important;
          font-weight: 300 !important;
          font-size: 18px !important;
          line-height: 27px !important;
          color: #a3daff !important;
          margin-top: -3px;
        }

        input {
          width: 100% !important;
        }

        .MuiInput-underline {
          &:before {
            border-color: #18a0fb;
          }
        }
      }
    }
  }

  .choiceList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .createMultiple {
      position: relative;
      width: 60%;
      max-width: 100%;

      .btnChoice {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
    }

    .choiceDrop {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid #ccc;
      border-radius: 5px;
      background: #fff;
      padding: 5px;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        float: left;
        width: 100%;

        li {
          width: 100%;
          padding: 3px 10px;
          margin: 2px 0;

          &.active,
          &:hover {
            background: #ececec;
          }
        }
      }

      textarea {
        width: 100%;
        height: 80px;
        border: none;
        padding: 0 20px;
        resize: none;

        &:focus-visible,
        &:focus {
          outline: none;
        }
      }

      button.btnSave {
        font-size: 10px;
        width: auto;
        height: auto;
        margin: 0;
        position: absolute;
        right: 10px;
        bottom: 10px;
      }
    }

    .number {
      font-size: 14px;
      line-height: 18px;
      color: #a3daff;
    }
  }
}

.createMultiple {
  max-width: 100px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #8a94a6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  svg {
    color: #7ec1ff;
    padding-right: 5px;
    font-size: 25px;
  }
}

.contactInner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 10px;

  @media screen and (max-width: $screen_540px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
  }

  .flag {
    // height: 32px;
    border-radius: 4px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: $screen_540px){
        margin: 0 0 10px 0;
        width: 100%;

        > div{
            width: 100%;
        }
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }

    ul {
      width: 150px;

      @media screen and (max-width: $screen_540px) {
        width: 100%;
      }
    }
  }

  input {
    border: none;
    border-bottom: 2px solid #a3daff;
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    color: #a3daff;
    width: 100%;
    padding-right: 110px;

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &::placeholder {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      color: #a3daff;

      @media screen and (max-width: $screen_540px) {
        font-size: 14px;
      }
    }
  }

  .answerTypeSelectBox {
    position: absolute;
    right: 0;
    bottom: 5px;
  }
}

.fileUploadInner {
  width: 100%;

  .fileUpload {
    width: 95px;
    height: 63px;
    background: #f2f3f7;
    border: 1px dashed #18a0fb;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  h4 {
    margin: 0;
    width: 100%;
    padding: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #18a0fb;
    text-indent: 0;
  }

  p {
    margin: 0;
    padding: 0;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #8a94a6;
  }
}

.questionOver {
  .MuiPaper-root {
    max-width: 200px;
    box-shadow: 1px 1px 2px #ccc;
    padding: 10px 5px;
    border-radius: 5px !important;

    h5 {
      font-weight: 700;
      font-size: 18px;
      line-height: 21px;
      color: #26293f;
      padding: 5px 10px;
    }

    h6 {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #26293f;
      text-align: left;
      padding: 5px 10px;
    }
  }
}

.questionBankSetting {
  .searchOption {
    position: relative;
    width: 100%;
    padding: 10px;

    input {
      background: #f8f8f8;
      border: 1px solid #e1e4e8;
      border-radius: 4px;
      font-size: 12px;
      line-height: 18px;
      color: #aeb6cf;
      padding: 8px 35px 8px 5px;
      width: 100%;

      &:focus-visible,
      &:focus {
        outline: none;
      }
    }

    svg {
      cursor: pointer;
      position: absolute;
      right: 13px;
      top: 11px;
      color: #18a0fb;
      font-size: 34px;
    }
  }

  .items {
    border-top: 1px solid #ccc;
    height: calc(100% - 110px);
    overflow: auto;

    ul {
      float: left;
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8a94a6;
        padding: 10px 15px;
        cursor: pointer;

        @media screen and (max-width: 1024px) {
          font-size: 14px;
        }

        &:hover,
        &.active {
          color: #18a0fb;
          background: #e1e4e8;
          mix-blend-mode: normal;
        }
      }
    }
  }
}

.question-bank-dialog {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  min-height: 10px !important;

  .dialogBoxQuestion {
    width: 100%;
    height: 100%;

    .closeDailogNew {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }

    .quesBank-left {
      width: 70%;
      position: relative;

      .question-bank-header {
        //   position: absolute;
        position: unset;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .ques-container {
        width: 100%;
        //   margin-top: 77px;
        height: calc(100% - 77px);
        display: block;

        .ques-containerBody {
          display: flex;
          overflow: auto;
          height: calc(100% - 77px);
          padding: 0 10px;
        }

        .quesInnerHead {
          position: relative;
          display: flex;
          justify-content: space-between;
          // align-items: center;
          align-items: flex-start;
          width: 100%;
          padding: 10px 20px;

          .quesTagList {
            display: flex;
            flex-wrap: wrap;
            .tag {
              margin-bottom: 8px;
            }
          }

          .tag {
            background: rgba(24, 160, 251, 0.2);
            border-radius: 8px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 5px 15px;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #000000;

            &:before {
              content: "";
              position: absolute;
              top: -1px;
              right: -1px;
              background: #18a0fb;
              height: 12px;
              width: 12px;
              z-index: 0;
            }

            svg {
              position: absolute;
              right: -7px;
              top: -7px;
              color: #fff;
              box-shadow: 1px 1px 4px 0px #ccc;
              border-radius: 50%;
              z-index: 1;
              cursor: pointer;
            }
          }

          .filter {
            background: #fff;
            border-radius: 50%;
            padding: 10px;
            box-shadow: 1px 1px 4px #ccc;
            cursor: pointer;

            svg {
              color: #18a0fb;
            }
          }
        }
      }
    }

    .quesBank-right {
      width: 30%;
      height: 100%;

      .ques-queue-container {
        height: calc(100% - 125px);
        position: relative;

        .ques-queue-containerBody {
          height: calc(100% - 70px);
          overflow: auto;
          position: relative;

          .queue-card {
            .cancelIcon {
              height: 25px;
              cursor: pointer;
              visibility: hidden;
            }

            &:hover,
            &:focus {
              .cancelIcon {
                visibility: visible;
              }
            }
          }
        }

        .queue-add-btn {
          position: absolute;
          bottom: 10px;
          border-radius: 5px;
          text-align: center;
          white-space: nowrap;
          padding: 18px 15px;
        }
      }
    }
  }
}

.confirmationDialog {
  .MuiDialog-paper {
    height: auto;
    width: 500px;
    max-height: initial;
    max-width: 100% !important;

    .dialog-paper-content {
      min-width: 500px;
    }
  }

  .form {
    padding: 30px 0;
    text-align: center;

    .btnGroup {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      .btn {
        padding: 6px 30px;
        border: 1px solid #18a0fb;
        background: #18a0fb;
        font-size: 14px;
        text-align: center;
        color: #fff;
        margin-left: 10px;

        &.btnCancel {
          border: 1px solid #18a0fb;
          background: #fff;
          color: #000;
        }
      }
    }
  }
}

.addLogoDialog {
  .MuiDialog-paper {
    max-height: 384px;
    width: 942px;
    max-width: 100% !important;
  }

  .form {
    padding: 30px 0;
    text-align: center;

    .imgWrapper {
      margin: 40px auto 40px auto;
      width: 182px;
      height: 82px;
      background: #f0eaea;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 100%;
        height: auto;
        max-height: 100%;
      }
    }
  }

  .addLogoDialogInner {
    .fileUploadInner {
      text-align: center;

      .fileUpload {
        margin: 20px auto;
        position: relative;

        input {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          opacity: 0;
          cursor: pointer;
        }
      }

      h4 {
        color: #000;
        margin-top: 30px;
        margin-bottom: 20px;
      }

      p {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 18px;
      }
    }

    button.btnSave {
      background: #18a0fb;
      color: #fff;
      text-align: center;
      padding: 8px 25px;
      border-radius: 3px;
      font-size: 15px;
      line-height: 22px;
    }

    .btn--wrap {
      display: flex;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: $screen_540px) {
        justify-content: space-between;
        button {
          width: 45%;
          margin: 0;
        }
      }
    }
  }

  &.addPageTitleDialog {
    .MuiDialog-paper {
      height: auto;
      width: 725px;
      max-height: initial;
      max-width: 100% !important;
    }

    .pageTitleDialogInner {
      legend {
        display: none;
      }

      .form {
        text-align: left;
        padding: 15px 0;

        .MuiGrid-root {
          position: relative;

          label {
            width: 100%;
            margin-bottom: 15px;
            display: block;
          }
        }

        .otherSectionButton {
          button {
            font-size: 16px;
            line-height: 24px;
            color: #18a0fb;
            background: none;
            display: inline-flex;
            align-items: center;
            padding-left: 0;
            margin-right: 10px;

            svg {
              color: #18a0fb;
              font-size: 16px;
              margin-right: 5px;
              margin-top: 1px;
            }
          }
        }

        .btnGroup {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          align-items: center;
          margin-top: 30px;

          .btn {
            padding: 6px 30px;
            border: 1px solid #18a0fb;
            background: #18a0fb;
            font-size: 14px;
            text-align: center;
            color: #fff;
            margin-left: 10px;

            &.btnCancel {
              border: 1px solid #18a0fb;
              background: #fff;
              color: #000;
            }
          }

          @media screen and (max-width: $screen_540px) {
            justify-content: space-between;
            .btn {
              width: 45%;
              margin: 0;
            }
          }
        }

        .rightText {
          position: absolute;
          right: 0;
          top: 5px;

          label {
            display: inline-flex;
            margin-bottom: 0;
            font-size: 14px;

            .MuiFormControlLabel-label {
              font-size: 14px !important;
            }
          }

          @media screen and (max-width: $screen_540px) {
            top: 10px;
            .MuiFormGroup-root {
              .MuiFormControlLabel-root {
                margin: 0;
                .MuiButtonBase-root {
                  padding: 5px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.textEditorDialog{
    .MuiDialog-container {
        overflow-y: auto;
        padding: 10px;
        align-items: flex-start;
        .MuiPaper-root{
            width: 100%;
            height: 100%;
            margin: 0;
            .jodit-react-container{
                .jodit-workplace{
                    min-height: calc(100vh - 360px) !important;
                }
            }
        }
    }
  }
}

.optionSetting {
  .optionInner {
    width: 100%;
    height: calc(100% - 50px);
    overflow: auto;

    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 27px;
      color: #181818;
      padding: 20px 20px 0 20px;
    }

    .items {
      display: inline-block;
      width: 100%;
      border-bottom: 1px solid #aeb6cf;
      padding: 10px;

      h5 {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #8a94a6;
        padding: 0 10px;

        svg,
        img {
          padding-right: 10px;
          color: #18a0fb;
          font-size: 30px;
        }
      }

      .item {
        position: relative;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        color: #8a94a6;
        cursor: pointer;
        background: #fff;

        .cancelIcon {
          position: absolute;
          top: -9px;
          right: -6px;
          font-size: 19px;
          color: #fff;
          z-index: 1;
          border-radius: 50%;
          box-shadow: 0 0 3px 1px #ccc;
          background: #fff;

          &:before {
            content: "";
            position: absolute;
            top: 5px;
            right: 4px;
            height: 10px;
            width: 10px;
            background: #18a0fb;
            z-index: -1;
          }

          svg {
            font-size: 19px;
          }
        }

        svg[data-testid="StarIcon"] {
          font-size: 14px;
        }
      }

      &.setAlert {
        .itemsInner {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
        }

        .item {
          width: calc(50% - 20px);
          margin: 10px;
          border: 1px solid #18a0fb;
          border-radius: 5px;
          text-align: center;
          padding: 10px;
        }
      }

      &.notifyAlertGroup {
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        padding-top: 15px;

        .groupInner {
          width: 50%;

          .itemsInner {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            padding: 10px;

            .item {
              width: 28px;
              height: 28px;
              background: #ffffff;
              box-shadow: 0px 4px 10px rgba(10, 68, 106, 0.15);
              border-radius: 12px;
              margin-right: 15px;
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }

      &.currentAlert {
        .itemsInner {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;

          .item {
            width: calc(50% - 20px);
            margin: 10px;
            border: 1px solid transparent;
            border-radius: 5px;
            text-align: center;
            padding: 10px;
            background: rgba(24, 160, 251, 0.2);
            font-size: 12px;

            .Alert {
              position: absolute;
              bottom: -8px;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              .alertItem {
                width: 18px;
                height: 18px;
                background: #ffffff;
                box-shadow: 0 4px 10px rgba(10, 68, 106, 0.15);
                border-radius: 12px;
                margin-left: 6px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                  width: 100%;
                  max-width: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .btnSave{
    width: 85px;
    height: 32px;
    background: #18a0fb;
    border-radius: 4px;
    border: 1px solid #18a0fb;
    color: #fff;
    margin-left: 34px;
  }
  .disabled{
    pointer-events: none;
    opacity: 0.6;
  }
}

.introCard {
  height: 450px;
  background: #fff;
  border-radius: 3px;
  padding: 20px 0;
  margin: 20px 0;
  box-sizing: border-box;
  border: 1px solid #ccc;
  position: relative;

  @media screen and (max-width: $screen_768px) {
    height: auto;
  }

  .logoTitle {
    text-align: center;
    position: relative;
    padding: 20px;

    @media screen and (max-width: $screen_540px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .logo {
      //   position: absolute;
      //   left: 20px;
      //   top: 20px;
      width: 120px;
      height: 55px;
      background: #f0eaea;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      @media screen and (max-width: $screen_540px) {
        position: unset;
        margin-bottom: 20px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .title {
      margin: 10px 0;
      padding: 0 20px;
      height: auto;
      background: #fff;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 22px;
      font-weight: bold;
      border-radius: 3px;
      border: 1px solid #ccc;
    }
  }

  .description {
    padding: 25px 20px;
    width: calc(100%);
    border-radius: 3px;
    background: #fff;
    font-style: italic;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    text-align: center;
    word-break: break-word;
  }

  .groupBottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: 20px;
    transform: translateX(-50%);

    @media screen and (max-width: $screen_768px) {
      position: static;
      transform: none;
    }
  }

  .btn {
    margin: 0 auto;
    width: -moz-fit-content;
    width: fit-content;
    background: #319eb8;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
  }

  .time {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: #181818;
    display: inline-flex;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    margin-top: 10px;

    svg {
      color: #319eb8;
      margin-right: 5px;
      font-size: 18px;
    }
  }
}

.finalSubmitCard {
  height: 439px;
  background: #fff;
  border-radius: 3px;
  padding: 20px 0;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  border: 1px solid #ccc;

  button.btn {
    &.submit {
      height: 60px;
      background: #ffffff;
      border-radius: 4px;
      font-size: 25px;
      color: #319eb8;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      padding: 0 40px;
      border: 1px solid #319eb8;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .footerContent {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 20px;

    p {
      font-style: italic;
      font-weight: 300;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #319eb8;
      margin: 0;
      word-break: break-word;

      a {
        color: #319eb8;
        text-decoration: underline;
      }
    }
  }
}

.slider {
  .layoutWrap {
    min-height: 1px;
    height: 439px !important;

    &.layout1 {
      .questionAnswerWrap,
      .imageWrapInner {
        height: 100%;
        overflow: auto;
      }

      .ImageWrap {
        height: 100%;
      }
    }

    .imageWrapInner {
      height: 100%;
      padding: 20px 30px;
    }
  }

  .slick-arrow {
    &.slick-prev,
    &.slick-next {
      background: #319eb8;
      width: 40px;
      height: 40px;
      border-radius: 3px;

      &:before {
        right: 6px;
        top: 50%;
        border: solid transparent;
        content: "";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-right-color: #fff;
        border-width: 10px;
        margin-top: -10px;
      }
    }

    &.slick-prev {
      left: -50px;

      &:before {
        right: 16px;
        @media screen and (max-width: 600px) {
          right: 12px;
        }
      }
    }

    &.slick-next {
      right: -50px;

      &:before {
        right: 3px;
        transform: rotate(180deg);
        @media screen and (max-width: 600px) {
          right: 0;
        }
      }
    }
  }
}



.sendEmailBody {
  background: #ffffff;
  border-radius: 5px;
  height: calc(100% - 40px);
  width: calc(100% - 60px);
  margin: 20px 30px;

  @media screen and (max-width: 1024px) {
    width: 100%;
    // height: 100%;
    // margin: -40px 0 0 0;
    height: auto;
    margin: 0;
  }

  .head {
    border-bottom: 1px solid #aeb6cf;
    height: 60px;
    padding: 5px 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    border-top: none;
    border-left: none;
    border-right: none;

    @media screen and (max-width: $screen_540px) {
      padding: 5px 20px;
    }

    .icon {
      height: 27px;
      width: 27px;
      background: #fdeee8;
      border-radius: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      svg {
        font-size: 18px;
        color: #f75f1e;
      }
    }

    font-size: 16px;
    line-height: 24px;
    color: #181818;
  }

  .sendEmailInner {
    height: calc(100% - 60px);
    overflow: auto;

    @media screen and (max-width: 1024px) {
      background-color: #fff;
      height: 100%;
    }

    .noData {
      padding: 100px 182px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 900px;
      margin: 0 auto;

      @media screen and (max-width: 1024px) {
        padding: 60px 35px;
      }

      @media screen and (max-width: 850px) {
        padding: 35px;
        flex-direction: column;
        align-items: flex-start;
      }

      @media screen and (max-width: $screen_540px) {
        padding: 20px;
      }

      .leftNoData {
        img {
          @media screen and (max-width: 1024px) {
            width: 150px;
            margin-bottom: 30px;
          }
        }
      }

      .rightNoData {
        margin-left: 50px;
        h3 {
          font-weight: bold;
          font-size: 22px;
          line-height: 33px;
          color: #181818;
          margin-bottom: 10px;
        }

        p {
          font-size: 18px;
          line-height: 30px;
          color: #8a94a6;
          margin-bottom: 20px;
        }

        @media screen and (max-width: 850px) {
          margin-left: 0;
        }

        @media screen and (max-width: $screen_768px) {
          width: 100%;

          h3 {
            font-size: 18px;
            line-height: 28px;
          }
          p {
            font-size: 14px;
            line-height: 25px;
          }
        }
      }
    }

    .btnGroup {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .btn {
        height: 40px;
        background: transparent;
        border-radius: 3px;
        border: transparent;
        font-size: 12px;
        line-height: 18px;
        color: #8a94a6;
        text-decoration: underline;

        &.btnDefault {
          background: #18a0fb;
          color: #ffffff;
          text-decoration: none;
          margin-right: 15px;
        }
      }
    }

    .peopleCount {
      max-width: 700px;
      padding: 20px 50px;

      @media screen and (max-width: $screen_540px) {
        padding: 20px;
      }

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #8a94a6;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 22px;
        line-height: 33px;
        color: #181818;
        margin: 50px 0 20px 0;
      }

      .selectBox {
        margin: 0;

        .MuiSelect-select {
          color: #18a0fb;
        }

        fieldset {
          border: 1px solid #18a0fb;

          legend {
            display: none;
          }
        }
      }

      .btnGroup {
        margin: 20px 0 0 0;

        .btn {
          &.btnDefault {
            font-size: 15px;
            line-height: 22px;
            color: #ffffff;
            height: auto;
            padding: 8px 30px;
          }
        }
      }
    }

    .chooseMethod {
      padding: 30px 50px;

      @media screen and (max-width: 1024px) {
        padding: 30px 35px;
      }

      @media screen and (max-width: $screen_540px) {
        padding: 20px;
      }

      .details {
        margin-bottom: 20px;
        max-width: 700px;

        h3 {
          font-size: 22px;
          line-height: 33px;
          color: #181818;
          font-weight: bold;
          margin-bottom: 15px;
        }

        p {
          font-size: 18px;
          line-height: 30px;
          color: #8a94a6;
        }

        @media screen and (max-width: $screen_768px) {
          width: 100%;

          h3 {
            font-size: 18px;
            line-height: 28px;
          }
          p {
            font-size: 14px;
            line-height: 25px;
          }
        }
      }

      .items {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media screen and (max-width: $screen_768px) {
          flex-direction: column;
        }

        .item {
          width: 27%;
          border: 1px solid transparent;
          box-shadow: 0 0 3px #ccc;
          padding: 40px 30px;
          border-radius: 2px;

          @media screen and (max-width: 1200px) {
            width: 30%;
            padding: 30px 15px;
            min-height: 250px;
          }

          @media screen and (max-width: $screen_768px) {
            width: 100%;
            margin-bottom: 20px;
            min-height: unset;
          }

          .icon {
            margin-bottom: 20px;

            svg {
              path {
                fill: #ccc;
              }
            }
          }

          h4 {
            font-size: 18px;
            line-height: 30px;
            font-weight: normal;
            color: #181818;
            margin-bottom: 10px;
            text-indent: 0;
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #8a94a6;
          }

          &.active,
          &:hover {
            border: 1px solid #18a0fb;

            .icon {
              svg {
                path {
                  fill: #18a0fb;
                }
              }
            }
          }

          a {
            font-size: 14px;
            line-height: 20px;
            color: #18a0fb;
            text-decoration: underline;
          }
        }
      }

      .btnGroup {
        margin-top: 10px;

        .btn {
          height: auto;
          padding: 8px 25px;
        }
      }
    }
  }
}

.uploadDialog {
  .MuiDialog-paper {
    max-width: 800px !important;
    overflow: hidden !important;
    height: 300px;
    width: 100%;
    max-height: 100%;
  }

  .closeDailogNew {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  button.btn {
    width: 112px;
    height: 40px;
    background: #18a0fb;
    border-radius: 3px;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
  }

  .uploadDialogInner {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .icon {
      width: 57px;
      height: 57px;
      background: #ffffff;
      box-shadow: 0 4px 10px rgba(10, 68, 106, 0.15);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
    }

    .areaInput {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding: 10px 30px;

      @media screen and (max-width: $screen_768px) {
        padding: 10px 0;
      }

      .dragableElement {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 1px dotted #18a0fb;
        border-radius: 5px;
        display: none;
      }

      input {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        opacity: 0;

        &:hover {
          & ~ .dragableElement {
            display: block;
          }
        }
      }
    }

    h3 {
      font-size: 14px;
      line-height: 21px;
      margin-bottom: 0;
      color: #181818;
    }

    p {
      font-size: 12px;
      line-height: 18px;
      text-align: center;
      color: #8a94a6;
      margin-bottom: 20px;
    }

    @media screen and (max-width: $screen_768px) {
      height: 100%;
      padding: 20px !important;
      textarea {
        width: 100% !important;
        height: 100% !important;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }

  .createContactInner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .createContactDialog {
      width: 100%;

      h3 {
        font-size: 18px;
        line-height: 33px;
        color: #181818;
        margin-bottom: 25px;
      }

      .radioGroup {
        margin-bottom: 25px;

        label {
          margin-right: 30px;
        }

        .MuiFormControlLabel-label {
          font-family: inherit !important;
          color: #8a94a6;
          font-size: 16px !important;
        }
      }

      button.btn {
        margin: 0 auto;
        white-space: nowrap;
      }
    }

    &.summaryShare {
      align-items: flex-start;
      padding-top: 30px !important;
      .summaryShare--head {
        .title--wrapper {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          label {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 22px;
            line-height: 33px;
            color: #000000;
            margin: 0;
          }
        }
      }
      .share--icons {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #18a0fb;
        padding: 0;
        margin: 0 20px 0 0;
        background: #ffffff;
        box-shadow: 0px 4px 10px rgba(10, 68, 106, 0.15);
        flex: 0 0 45px;

        img {
          width: 25px;
          height: auto;
          object-fit: contain;
        }
      }

      .inputField,
      textarea {
        background: #ffffff;
        border: 1px solid #18a0fb;
        border-radius: 8px;
        height: 60px;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        color: #8a94a6;
        padding: 16px;

        &::placeholder {
          font-size: 18px;
        }
      }

      textarea {
        width: 100%;
        height: auto;
        resize: none;

        &:focus {
          outline: 0;
        }
      }

      .labelField {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 22px;
        line-height: 33px;
        color: #000000;
      }

      .inputHeight {
        .inputField {
          min-height: 200px;
        }
      }
    }
  }
}

.summaryDialog {
  .MuiPaper-root {
    min-height: 510px;
  }
}

.codeGenerateDialog {
  .MuiDialog-paper {
    height: 550px;
  }
}

.contactListWrap {
  .contactListPage {
    position: relative;

    .MuiPaper-root {
      box-shadow: none;
      margin-bottom: 0;
    }

    .rightSideFilter {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      .sortSelect {
        select {
          background: #f8f8f8;
          border: 1px solid #e1e4e8;
          border-radius: 3px;
          height: 42px;
          padding: 5px;
          font-size: 12px;
          line-height: 18px;
          color: #8a94a6;

          &:hover,
          &:focus,
          &:focus-visible {
            border-color: #18a0fb;
            outline: none;
          }
        }
      }

      .searchBar {
        position: relative;
        margin-left: 10px;

        input {
          background: #f8f8f8;
          border: 1px solid #e1e4e8;
          border-radius: 3px;
          height: 42px;
          padding: 5px 45px 5px 10px;
          font-size: 12px;
          line-height: 18px;
          color: #8a94a6;

          &:hover,
          &:focus,
          &:focus-visible {
            border-color: #18a0fb;
            outline: none;
          }
        }

        .icon {
          position: absolute;
          right: 5px;
          top: 5px;
          background: #18a0fb;
          padding: 4px 5px;
          border-radius: 5px;

          svg {
            color: #fff;
          }
        }
      }
    }

    .saveBtn {
      position: absolute;
      bottom: 8px;
      left: 20px;
      background: #18a0fb;
      padding: 8px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      border-radius: 5px;
      opacity: 0.6;
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: unset;
      }
    }

    .cancelBtn {
      opacity: 1;
      background: #ffffff;
      color: #18a0fb;
      left: 120px;
      height: 35px;
      border: 1px solid;
      pointer-events: unset;
    }

    .tableWrap {
      margin: 0 20px 10px 20px;
      border: 1px solid #ccc;
      width: calc(100% - 40px);
      border-radius: 5px;
      min-height: 320px;
    }

    table {
      width: 100%;

      thead {
        background: #eff1f3;
      }
    }
  }
}

.personalizeInner {
  padding: 10px 30px;
  position: relative;

  @media screen and (max-width: $screen_540px) {
    padding: 10px 20px;
  }

  h2 {
    font-weight: normal;

    label {
      font-weight: normal;
    }
  }

  .create {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    font-size: 14px;
    color: #8a94a6;

    svg {
      color: #18a0fb;
    }
  }

  p {
    font-size: 14px;
    line-height: 25px;
    color: #8a94a6;
  }

  .link {
    background: #ffffff;
    border: 1px solid #18a0fb;
    border-radius: 4px;
    padding: 5px 15px;
    margin: 10px 0;
    display: inline-block;

    a {
      font-size: 14px;
      line-height: 21px;
      color: #18a0fb;
      &:hover {
        color: #18a0fb;
      }
    }
  }

  .tableWrap {
    margin: 0;
    margin-top: 30px;
    width: 100%;

    table {
      thead {
        background: #eff1f3;
      }
    }

    .actionBtn {
      border: none;
      background: none;
      color: #18a0fb;
      text-decoration: underline;

      &:hover {
        font-weight: bold;
      }

      &.disabled {
        color: #6e6e6e;
        pointer-events: none;
        text-decoration: none;
      }
    }

    .saveBtn {
      position: absolute;
      bottom: 8px;
      left: 30px;
      background: #18a0fb;
      padding: 8px 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 14px;
      border-radius: 5px;
      opacity: 0.6;
      pointer-events: none;

      &.active {
        opacity: 1;
        pointer-events: unset;
      }

      &.btnScheduleSurvey{
        left: 200px;
      }
    }

    .cancelBtn {
      opacity: 1;
      background: #ffffff;
      color: #18a0fb;
      left: 120px;
      height: 35px;
      border: 1px solid;
      pointer-events: unset;
    }

    .trash {
      display: none;
      cursor: pointer;
    }
  }
}

.socialLinkPage {
  position: relative;
  padding: 25px 20px 10px 100px;
  max-width: 900px;

  @media screen and (max-width: $screen_768px) {
    padding: 25px 20px 10px 70px;
  }
  @media screen and (max-width: $screen_540px) {
    padding: 25px 20px 10px 20px;
  }

  .link {
    position: absolute;
    left: 25px;
    top: 20px;
    background: #ffffff;
    box-shadow: 0 4px 10px rgba(10, 68, 106, 0.15);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: #18a0fb;
    }
  }

  h1 {
    font-size: 18px;
    line-height: 27px;
    color: #181818;
    margin-bottom: 20px;

    @media screen and (max-width: $screen_540px) {
      padding-left: 50px;
    }
  }

  .copyLink {
    margin-bottom: 20px;
    background: #ffffff;
    border: 1px solid #aeb6cf;
    border-radius: 4px;
    min-height: 38px;
    padding-right: 100px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;

    .copyQrButton{
      background: #18a0fb;
      color: #fff;
      text-align: center;
      padding: 8px 25px;
      border-radius: 3px;
      font-size: 15px;
      line-height: 22px;
    }

    @media screen and (max-width: $screen_768px) {
      height: auto;
      width: 100%;
    }

    a {
      font-size: 14px;
      line-height: 21px;
      color: #1675e0 !important;
      text-decoration: underline !important;
      word-break: break-word;
      padding-right: 20px;
      &:hover {
        color: #18a0fb;
      }
    }

    .btnSave {
      background: #18a0fb;
      border-radius: 0 4px 4px 0;
      font-size: 15px;
      line-height: 22px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 38px;
      position: absolute;
      right: 0;
      top: 0;
      padding: 5px 20px;
      @media screen and (max-width: $screen_768px) {
        height: 100%;
      }
    }
  }

  .socialIcons {
    margin-top: 30px;
    position: relative;

    .customiseLink {
      position: absolute;
      right: 0;
      top: 5px;
      text-decoration: underline;
      font-size: 14px;
      line-height: 21px;
      color: #18a0fb;
      cursor: pointer;
    }

    ul {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline-block;

      @media screen and (max-width: $screen_540px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      li {
        float: left;
        padding-right: 25px;

        @media screen and (max-width: $screen_540px) {
          float: unset;
          padding-right: 0;
        }

        a {
          height: 35px;
          width: 35px;
          background: #ffffff;
          box-shadow: 0 4px 10px rgba(10, 68, 106, 0.15);
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

.embedViewWrap {
  max-width: 100%;
  margin-top: 30px;

  .embedView {
    margin: 20px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: $screen_768px) {
      flex-direction: column;
    }

    .item {
      border: 1px solid transparent;
      padding: 10px;
      background: #ffffff;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      width: 15%;
      margin-bottom: 10px;
      cursor: pointer;

      @media (max-width: 1360px) {
        width: 22%;
      }

      @media (max-width: 767px) {
        width: 100%;
      }

      h3 {
        padding: 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: #181818;
        margin: 10px 0;
      }

      p {
        font-size: 12px;
        line-height: 20px;
        color: #8a94a6;
      }

      &:hover,
      &.active {
        border-color: #18a0fb;
      }
    }
  }
}

.embedPageWrap {
  background: transparent;
  border-radius: 5px;
  height: calc(100% - 40px);
  width: calc(100% - 30px);
  margin: 20px 30px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 1024px) {
    flex-direction: column;
    margin: 0;
    width: 100%;
    height: auto;
  }

  .embedSidebar {
    background: #fff;
    width: 366px;
    height: 100%;
    padding-left: 40px;
    padding-bottom: 20px;
    overflow: auto;

    @media screen and (max-width: 1024px) {
      width: 100%;
      padding-left: 0;
    }

    .head {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px;
      margin: 0;
      border-top: none;
      border-bottom: 1px solid #ccc;

      .icon {
        margin-right: 20px;

        svg {
          color: #18a0fb;
        }
      }

      h3 {
        font-size: 18px;
        line-height: 27px;
        color: #181818;
        font-weight: normal;
      }
    }

    .embedSideBody {
      ul {
        list-style: none;
        margin: 0 0 30px 0;
        padding: 0;
        display: inline-block;
        width: 100%;

        li {
          float: left;
          width: 100%;

          a {
            width: 100%;
            float: left;
            padding: 15px 80px 15px 60px;
            color: #8a94a6;
            font-size: 18px;
            text-decoration: none;
            display: flex;
            justify-content: space-between;
            align-items: center;

            svg {
              font-size: 14px;
              margin-left: 30px;
            }
          }

          ul.dropDownMode {
            display: none;
            width: 100%;
            margin-top: 10px;
            margin-bottom: 0;

            li {
              float: left;
              width: 50%;
              display: flex;
              justify-content: center;
              align-items: flex-start;
              margin: 5px 0;
              height: 88px;

              a {
                text-align: center;
                width: 85px;
                margin: 0 auto;
                background: #edeff5;
                border-radius: 4px;
                padding: 10px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                opacity: 1;

                h3 {
                  display: none;
                  font-size: 12px;
                  line-height: 18px;
                  color: #181818;
                  font-weight: normal;
                }

                img {
                  width: 100%;
                  filter: grayscale(100%);
                  height: 50px;
                }
              }
            }
            .textPropertyWrap {
              display: block;
              width: 100%;
              padding: 15px 0px;

              .apperanceTextHeading {
                position: relative;
                width: 100%;
                top: inherit;
                left: inherit;
                font-weight: bold;
              }

              .fontPickSize {
                width: 100%;
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 0;

                #font-picker {
                  position: relative;
                  top: inherit;
                  left: inherit;
                }

                .fontSizeBox {
                  position: relative;
                  top: inherit;
                  left: inherit;
                }
              }

              .fontStyle {
                padding: 15px 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
              }
            }
          }

          &.active {
            a {
              background: #f0f2f4;
              color: #8a94a6;

              svg {
                color: #8a94a6;
                transform: rotate(90deg);
              }
            }

            ul.dropDownMode {
              display: inline-block;

              li {
                &.active,
                &:hover {
                  a {
                    h3 {
                      display: block;
                    }

                    img {
                      filter: none;
                    }
                  }
                }
              }
            }
          }

          a.disable {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }

      button.btn {
        background: #18a0fb;
        border-radius: 6px;
        font-size: 15px;
        line-height: 22px;
        color: #ffffff;
        margin-left: 85px;
        padding: 10px 35px;

        &:hover {
          opacity: 0.8;
        }
      }

      .text {
        margin-top: 30px;
        margin-left: 60px;

        a {
          text-decoration: underline;
        }
      }
    }
  }

  .embedView {
    width: calc(100% - 400px);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    @media screen and (max-width: 1024px) {
      width: 100%;
      height: auto;
    }

    .embedViewInner {
      width: 100%;
      max-width: 1400px;
      height: 100%;
      overflow: auto;
      background: #fff;
    }
  }
}

.codeCopyDialog {
  h3 {
    margin-bottom: 0 !important;
  }

  p {
    margin-bottom: 30px;
  }

  pre {
    min-height: 100px;
    background: #000c17;
    padding: 10px;
    color: #fff;
    border-radius: 5px;
    overflow-wrap: break-word;
    white-space: inherit;
  }

  .btnGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    float: right;
  }

  button.btn {
    padding: 10px 20px;
    border: 1px solid #18a0fb;
    text-align: center;
    border-radius: 3px;
    margin-left: 10px !important;
    width: auto;
    color: #18a0fb;
    background: transparent;

    &.btnSubmit {
      color: #fff;
      background: #18a0fb;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.embedViewInner {
  iframe {
    border: none;
  }

  &.standard {
    background-origin: padding-box;
    -webkit-background-clip: border-box;
    background-clip: border-box;
    background-color: #fff;
    padding: 10px 20px;

    @media screen and (max-width: 1024px) {
      padding: 10px;
    }

    iframe {
      padding: 0 20px;
      border: none;
      @media screen and (max-width: 1024px) {
        padding: 0;
      }
    }

    .bar {
      height: 20px;
      width: calc(100% - 40px);
      background: #f4f4f4;
      margin: 5px 20px;

      @media screen and (max-width: 1024px) {
        width: 100%;
        margin: 5px 0;
      }
    }
  }
}

.templateDialogInner {
  width: 100%;
  .row {
    label {
      width: 100%;
      .label {
        font-size: 16px;
        line-height: 24px;
        color: #8a94a6;
        padding-top: 10px;
        letter-spacing: normal;
      }
      .inputField {
        width: 100%;
        border: 1px solid #aeb6cf;
        border-radius: 10px;
        box-shadow: none;
        height: 42px;
        filter: none;
        padding: 5px 10px;
        &:hover,
        &:active {
          outline: none;
          border-color: #18a0fb;
        }
      }
    }
  }
  .btnGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 10px;
    .btn {
      margin-left: 10px;
      &.btnDefault {
        border-color: #18a0fb;
        background: #ffffff;
        color: #18a0fb;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}

@media (max-height: 680px) {
  .CreateSurveyWrapper {
    .questionWrapper {
      top: 70px;
      transform: translateY(0);
      height: calc(100% - 30px);
      overflow: auto;
    }
  }
}

@media (max-width: 1460px) {
  //   .proTips {
  //     .proTipPopup {
  //       top: inherit;
  //       bottom: 40px;
  //       left: inherit;
  //       right: -47px;

  //       .leftIcon {
  //         top: inherit;
  //         bottom: -20px;
  //         left: inherit;
  //         right: 53px;
  //         transform: rotate(-90deg);
  //       }
  //     }
  //   }

  .CreateSurveyWrapper {
    .analyseResultsBody {
      .analyseResultsWrap {
        .dataraw-wrap {
          .dataraw-list {
            .dataraw-item {
              width: 33.333%;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1199px) {
  .newSurveyWrapper {
    .survey-event {
      .response {
        .response-whiteboard {
          .survey-response {
            .responseBody {
              .responseItem {
                .survey-link {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .CreateSurveyWrapper {
    .analyseResultsBody {
      .analyseResultsWrap {
        .dataraw-wrap {
          .dataraw-list {
            .dataraw-item {
              width: 50%;
            }
          }
        }

        .answerAnalysisPage {
          .analyse-answerResponse {
            display: block;

            .answer-item {
              width: 100%;

              &:last-child {
                border-left: none;
              }
            }

            .filterbox {
              padding-left: 15px;
              padding-right: 15px;
            }

            .progress-list {
              padding-left: 15px;
              padding-right: 15px;
            }
          }
        }

        .getNewRecommendationsPage {
          .tabData-Wrap {
            .contentbox {
              .table-wrapper {
                overflow-x: scroll;

                &::-webkit-scrollbar {
                  border-radius: 10px;
                  width: 10px;
                  height: 12px;
                  background: rgba(204, 204, 204, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                  border-radius: 10px;
                  height: 6px;
                  background: rgba(204, 204, 204, 0.8);
                  cursor: pointer;
                }

                table {
                  width: 100%;
                  min-width: 1090px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1165px) {
  .CreateSurveyWrapper {
    .analyseResultsBody {
      .analyseResultsWrap {
        .dataraw-wrap {
          .dataraw-filter {
            display: block;

            .item {
              margin-left: 0 !important;

              &:first-child {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .newSurveyWrapper {
    .survey-event {
      .response {
        flex-wrap: wrap;

        .response-whiteboard {
          width: 100%;

          &:first-child {
            margin-right: 0;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-left: 0;
          }

          .survey-response {
            .responseBody {
              .responseItem {
                .survey-link {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }

  .CreateSurveyWrapper {
    .analyseResultsBody {
      display: block;
      padding-right: 0;
      height: calc(100% - 100px);
      overflow-y: auto;

      .analyseResultsMenu {
        padding-top: 0;
        padding-bottom: 0;
        width: 100%;
      }

      .analyseResultsWrap {
        padding: 15px;
        width: 100%;
        margin-left: 0;

        .answerAnalysisPage {
          padding: 0;

          .analyseChart {
            min-height: 1px;

            .lineChart {
              width: 100%;
              margin-left: 0;

              canvas {
                max-width: 100%;
                width: 100%;
              }
            }
          }

          .row {
            display: block;

            &:first-child {
              .col-sm-12 {
                margin-bottom: 20px;
              }
            }

            .col-sm-12 {
              flex: 0 0 100%;
              max-width: 100%;
            }

            &.chart-row {
              display: flex;
              .col-md-6 {
                flex: 0 0 50%;
                max-width: 50%;

                @media screen and (max-width: 767px) {
                  flex: 0 0 100%;
                  max-width: 100%;
                }
              }

              .analyseCard.analyseChart2 {
                @media screen and (max-width: 991px) {
                  margin: 0;
                }
                @media screen and (max-width: 767px) {
                  margin: 20px 0 0 0;
                  height: auto;
                }
              }
            }
          }

          .analyseResponse {
            min-height: 1px !important;
          }

          .analyseCard {
            min-height: 1px !important;
          }
        }

        .dataraw-wrap {
          .dataraw-filter {
            display: block;
          }

          .dataraw-list {
            display: block;
            margin-left: 0;
            margin-right: 0;

            .dataraw-item {
              width: 100%;
              padding-left: 0;
              padding-right: 0;

              .dataraw-box {
                .content-box {
                  min-height: 1px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .CreateSurveyWrapper {
    .analyseResultsBody {
      .analyseResultsWrap {
        .audienceAnalysisPage {
          padding-top: 15px;

          .analyseQuestionSelect {
            margin-bottom: 15px;
          }

          .analyseCard {
            margin-bottom: 10px;
          }

          .barChart {
            min-height: 1px;
          }
        }

        .dataraw-wrap {
          .dataraw-filter {
            display: block;

            .search-box {
              input {
                min-width: 1px;
              }
            }

            .item {
              width: 100%;

              &:first-child {
                width: 100%;
              }

              &:last-child {
                display: flex;
                flex-wrap: wrap;

                .formgroup {
                  width: 49%;
                  margin-left: 0;
                  margin-right: 2%;
                  margin-bottom: 15px;

                  &:nth-child(2n + 2) {
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }

        .answerAnalysisPage {
          .analyseCircle {
            .doughnut {
              width: 100%;
            }
          }

          .analyse-answerResponse {
            .progress-list {
              overflow-x: auto;

              .progress-item {
                min-width: 500px;
              }
            }
          }
        }

        .getNewRecommendationsPage {
          .analyseCard {
            margin-bottom: 15px;
          }

          .cart-tabs-wrapper {
            .tab-link {
              margin-bottom: 15px;

              &:first-child {
                width: 100%;
                text-align: center;
              }

              &:last-child {
                margin-bottom: 0;
              }

              span {
                font-size: 15px;
                padding-left: 0;
                padding-right: 0;

                &.linkButton {
                  font-size: 15px;
                }
              }
            }
          }
        }

        .progress-box-list {
          .col-md-4 {
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .newSurveyWrapper {
    .choose-theme {
      .select-exp-label {
        margin-left: 0;
      }

      .select-experience {
        .MuiFormControl-root {
          width: 100%;
          min-width: 100%;
          margin: 8px 0;
        }
      }
    }
  }
}

@media (max-width: 567px) {
  .CreateSurveyWrapper {
    .analyseResultsBody {
      .analyseResultsWrap {
        .answerAnalysisPage {
          .analyse-filter-box {
            display: block;

            .title {
              margin-bottom: 15px;
            }

            .select-items {
              .item {
                width: 50%;
              }
            }
          }

          .analyseQuestionSelect {
            .analyseQuestion {
              font-size: 16px;
            }
          }

          .analyseQuestionType {
            font-size: 16px;
          }

          .analyse-answerResponse {
            .answer-item {
              .listingwrap {
                .questtion-item {
                  .questtion-tilte {
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }

        .audienceAnalysisPage {
          .analyse-filter-box {
            display: block;

            .title {
              margin-bottom: 15px;
            }

            .select-items {
              .item {
                width: 50%;
              }
            }
          }

          .analyseQuestionSelect {
            .analyseQuestion {
              font-size: 16px;
            }
          }

          .analyseQuestionType {
            font-size: 16px;
          }

          .analyse-answerResponse {
            .filterbox {
              display: block;

              .title {
                margin-bottom: 15px;
              }

              .buttonbox {
                justify-content: flex-start;
              }
            }

            .answer-item {
              .listingwrap {
                .questtion-item {
                  .questtion-tilte {
                    padding-right: 25px;
                  }
                }
              }
            }
          }
        }

        .surveyEffectiveness {
          .analyseCard {
            .analyse-filter-box {
              display: block;

              .title {
                margin-bottom: 15px;
              }

              .select-items {
                .item {
                  width: 50%;
                }
              }
            }
          }
        }

        .getNewRecommendationsPage {
          .analyseCard {
            .item {
              select {
                &.select-question {
                  font-size: 16px;
                }
              }
            }
          }

          .analyseQuestionType {
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media (max-width: $screen_540px) {
  .newSurveyWrapper {
    .innerScratch {
      padding: 15px;

      .btnGroup {
        .back-btn,
        .next-btn {
          width: 50%;
        }
      }
    }

    .scratch-heading {
      font-size: 16px;
    }

    .survey-event {
      .eventWrap {
        flex-wrap: wrap;

        .imgWrap {
          width: 100%;
          margin-bottom: 15px;
        }
      }

      .response {
        margin-top: 0 !important;
        padding-top: 10px;

        .response-whiteboard {
          .survey-response {
            padding: 10px;
            height: auto;

            .responseBody {
              flex-wrap: wrap;

              .responseItem {
                width: 100%;

                .checkBoxWrap {
                  left: -10px;
                  top: -4px;
                }
              }
            }
          }
        }
      }
    }

    .proTips {
      .proTipPopup {
        width: 317px;
        right: -24px;
        height: auto;
      }
    }
  }
}

.setting-btn {
  position: relative;

  @media screen and (max-width: $screen_768px) {
    position: unset;
  }
}

.setting-infobox {
  display: none;
  background: #ffffff;
  padding: 15px;
  width: 390px;
  text-align: left;
  position: absolute;
  // right: 140px;
  right: 165px;
  top: 0;
  border: 1px solid #e1e4e8;
  box-shadow: 0px 12px 58px rgba(34, 43, 69, 0.15);
  z-index: 1;

  @media screen and (max-width: $screen_768px) {
    width: 100%;
    right: unset;
    left: 0;
    top: 100%;
  }

  .itembox {
    margin-bottom: 15px;

    .range-items {
      display: block;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }

      .range-item {
        display: flex;
        align-items: center;

        label {
          font-size: 12px;
          color: #000;
          width: 140px;
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }

    .radio-list {
      display: block;
      padding-left: 0;
      margin-bottom: 0;

      .radio-item {
        display: block;
        margin-bottom: 12px;

        .option {
          display: inline-flex;
          align-items: center;
          position: relative;
          margin-left: 0;

          input {
            position: absolute;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            z-index: 1;
            cursor: pointer;

            &:checked + label {
              z-index: 11;

              &::before {
                border-color: #18a0fb;
              }

              &::after {
                content: "";
                background-color: #18a0fb;
                display: inline-block;
                position: absolute;
                width: 8px;
                height: 8px;
                left: 5px;
                top: 5px;
                border-radius: 50%;
              }
            }
          }

          label {
            position: relative;
            font-size: 13px;
            font-weight: normal;
            color: #474c57;
            padding-left: 26px;
            cursor: pointer;
            margin-bottom: 0;

            &::before {
              content: "";
              position: absolute;
              width: 18px;
              height: 18px;
              left: 0;
              top: 0;
              border-radius: 50%;
              border: 1px solid #ddd;
            }
          }
        }
      }
    }

    .categories-list {
      // padding-left: 25px;

      .add-category-btn {
        display: inline-block;
        vertical-align: middle;
        background: transparent;
        padding: 0;
        font-size: 13px;
        font-weight: normal;
        color: #474c57;
        border: none;
        box-shadow: none;

        svg {
          display: inline-block;
          vertical-align: middle;
          width: 18px;
          margin-right: 5px;

          path {
            fill: #18a0fb;
          }
        }
      }

      .categories-data {
        display: flex;
        flex-wrap: wrap;
        max-height: 200px;
        overflow-y: auto;
        padding-bottom: 20px;

        span {
          background: rgba(#18a0fb, 0.2);
          padding: 8px 16px;
          font-size: 12px;
          color: #8a94a6;
          font-weight: 400;
          margin: 15px 15px 0 0;
          position: relative;
          word-break: break-word;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // min-height: 120px;
          // -webkit-line-clamp: 4;
          // -webkit-box-orient: vertical;

          &:nth-child(3n + 3) {
            margin-right: 0;
          }

          .category-closebtn {
            background: transparent;
            padding: 0;
            position: absolute;
            right: -6px;
            top: -8px;
            z-index: 1;

            svg {
              display: inline-block;
              vertical-align: middle;
              width: 25px;
              height: 25px;
            }
          }
        }
      }
    }

    .btn-save {
      background: #18a0fb;
      padding: 8px 20px;
      font-size: 13px;
      font-weight: 400;
      color: #fff;
      min-width: 100px;
      border: none;
      box-shadow: none;
      border-radius: 5px;
      margin-top: 10px;

      &.customButtonDisabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .setting-infobox-pointer {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 0 10px 18px;
    border-color: transparent transparent transparent #fff;
    position: absolute;
    right: -10px;
    top: 85px;
  }

  &.infobox-active {
    display: block;
  }
}

.times-fieldbox {
  display: block;
  position: relative;
  margin-top: 15px;

  input {
    display: block;
    padding: 10px 60px 10px 15px;
    font-size: 10px;
    font-weight: 400;
    color: #8a94a6;
    width: 100%;
    height: 32px;
    border: 1px solid #dee2e6;
    border-radius: 4px;
    box-shadow: none;

    &:focus,
    &:hover {
      box-shadow: none;
    }
  }

  .btn {
    background: #18a0fb;
    padding: 2px;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    top: 0;
    z-index: 1;

    svg {
      display: inline-block;
      vertical-align: middle;
      width: 18px;
      height: 18px;

      path {
        fill: #fff;
      }
    }
  }
}

.analyseDetailwrap {
  .analyseDetail {
    .emailField {
      .items {
        background-color: #fff;
        align-items: flex-start;

        .emailfield-box {
          position: relative;
          background: rgba(24, 160, 251, 0.2);

          input {
            font-size: 12px;
            color: #8a94a6;
          }

          .cancelIcon {
            position: absolute;
            top: -8px;
            right: -6px;
            font-size: 19px;
            color: #fff;
            z-index: 1;
            border-radius: 50%;
            box-shadow: 0 0 3px 1px #ccc;
            background: #fff;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: #18a0fb;
            font-size: 15px;
          }
        }

        .notes_btn {
          margin: 8px 5px 5px;
          .add-email-btn {
            background-color: transparent;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-family: inherit;
            position: relative;
            margin-right: 10px;
            white-space: nowrap;
            width: -moz-fit-content;
            width: fit-content;

            svg {
              margin-right: 10px;
              width: 24px;
              height: 24px;
            }
          }
        }

        .email-input-wrap {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          margin: 5px;

          .input-fields {
            position: relative;
          }

          input {
            background: #18a0fb;
            border-radius: 3px;
            border: 0;
            color: #fff;
            font-family: inherit;

            &:focus {
              outline: 0;
              box-shadow: none;
            }

            &::placeholder {
              color: #fff;
            }
          }

          .email-save-btn {
            height: 30px;
            border-radius: 4px;
          }
          .cancelIcon {
            position: absolute;
            top: -6px;
            right: 44px;
            font-size: 19px;
            color: #fff;
            z-index: 1;
            border-radius: 50%;
            box-shadow: 0 0 3px 1px #ccc;
            background: #fff;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            color: #18a0fb;
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen_540px) {
    .flexbox {
      .buttonsWrap {
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
          width: 45%;
        }
      }
    }
  }
}

#popup-menu {
  .MuiPopover-paper {
    border-radius: 4px !important;
    .MuiMenuItem-root {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #8a94a6;
      padding: 6px 10px;
    }
  }
}

.dashboardPanel {
  display: none;
  position: absolute;
  left: 20px;
  z-index: 20;
  .panel-btn {
    background: transparent;
    padding: 0;
  }

  @media screen and (max-width: $screen_768px) {
    display: block;
  }
}

.dashboardFilter {
  display: none;
  position: absolute;
  right: 20px;
  z-index: 20;
  .filter-btn {
    background: transparent;
    padding: 0;
  }
  @media screen and (max-width: $screen_768px) {
    display: block;
  }
}

.backdrop-overlay {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 998;
  display: none;

  &.active {
    display: block;
  }
}

.surveyLeftMenuBtn {
  display: none;
  // position: absolute;
  position: fixed;
  // top: 20px;
  top: 125px;
  left: 20px;
  button {
    padding: 0;
    background: transparent;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.surveyMenuCloseBtn {
  display: none;
  position: absolute;
  right: 0px;
  top: 0px;

  button {
    padding: 0;
    background: transparent;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

.surveyRightMenuBtn {
  display: none;
  // position: absolute;
  position: fixed;
  // top: 20px;
  top: 125px;
  right: 20px;

  button {
    padding: 0;
    background: transparent;
    svg {
      width: 30px;
      height: 30px;
    }
  }

  @media screen and (max-width: 1024px) {
    display: block;
  }
}

#account-menu {
  .questionQuePopup {
    width: 100%;
  }
}

.dropdown-handler {
  position: absolute;
  display: none;

  &.show {
    display: block;
  }
}

.createDataForm {
  .form-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: -0.015em;
    color: #181818;
    margin-bottom: 70px;
  }
  .form-card-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .btn-wrap {
      .blue-btn {
        background: #18a0fb;
        border-radius: 3px;
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        padding: 8px 16px;
        width: -moz-fit-content;
        width: fit-content;
      }

      @media screen and (max-width: $screen_540px) {
        width: 100%;
        .blue-btn {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }
  }

  .form-checkbox-card {
    width: 40%;
    height: auto;
    background: #ffffff;

    @media screen and (max-width: 1024px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .inputGroup {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 18px;
    margin-bottom: 16px;
    transition: all 0.3s;
    cursor: pointer;
    border: 1px solid #fff;
    pointer-events: none;
    &.active {
      pointer-events: unset;
      border: 1px solid #18a0fb;
    }

    &:hover {
      border: 1px solid #18a0fb;
      transition: all 0.3s;
    }

    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: flex;
      align-items: center;
      justify-content: space-between;

      li {
        margin-right: 18px;
        a {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          text-decoration-line: underline;
          color: #8a94a6;
        }
      }
    }
    .checkbox-container {
      display: block;
      position: relative;
      padding-left: 40px;
      padding-top: 2px;
      margin-bottom: 22px;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      font-weight: 400;
      font-size: 20px;
      line-height: 30px;
      color: #8a94a6;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
      }

      .checkmark {
        position: absolute;
        top: 4px;
        left: 0;
        height: 25px;
        width: 25px;
        border: 1px solid #8a94a6;
        border-radius: 2px;
        padding: 0;

        &::after {
          content: "";
          position: absolute;
          display: none;
        }
      }
    }
    .checkbox-container input:checked ~ .checkmark {
      background: #9ff4a7;
      border: 1px solid #9ff4a7;
    }
    .checkbox-container input:checked ~ .checkmark:after {
      display: block;
    }
    .checkbox-container .checkmark:after {
      left: 8px;
      top: 0px;
      width: 8px;
      height: 18px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .test {
    position: relative;
    display: inline-block;
  }
  .test_hover {
    visibility: hidden;
    width: inherit;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 2px 0;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    transform: translate(-32px, -20px);
  }
  .test:hover .test_hover {
    visibility: visible;
  }

  .description {
    border: 1px solid #aeb6cf;
    border-radius: 4px;
    width: 100%;
    padding: 10px 18px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.015em;
    color: #8a94a6;
    min-height: 40px;
  }
}

.uploadExternalDialog {
  .MuiDialog-container {
    .MuiPaper-root {
      height: 360px;
      .surveyNameWrap {
        width: 900px;
        height: 100%;
        padding: 20px 40px !important;

        @media screen and (max-width: $screen_540px) {
          padding: 20px !important;
        }
      }
    }
  }
}

.surveyMenuWrapper {
  .mobileMenuWrapper {
    display: none;
    margin: 15px 0 0 20px;
    width: -moz-fit-content;
    width: fit-content;
    z-index: 9999;
    position: fixed;
    top: 54px;
    button {
      padding: 0;
      background: transparent;
    }

    @media screen and (max-width: 1024px) {
      display: block;
    }
  }
}

.menuClose {
  display: none;
  text-align: right;
  margin: 15px 10px;
  button {
    padding: 0;
    border: 0;
    background: transparent;
  }

  @media screen and (max-width: $screen_768px) {
    display: block;
  }
}

#account-menu {
  .questionQuePopup {
    &.tooltipPopup {
      width: 430px;
      @media screen and (max-width: $screen_540px) {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: $screen_768px) {
  .newSurveyWrapper {
    .innerScratch {
      //   padding: 30px 20px;
      padding: 30px 20px 100px;
      .choose-theme {
        width: 100%;
      }
      .survey-event {
        .eventWrap {
          flex-direction: column;
          width: 100%;
          margin: 0;
          .imgWrap {
            width: 100%;
            height: 300px;
            margin: 0 0 20px 0;
          }
        }
      }
    }
  }
}

.bannerWrapper {
  width: 100%;
  height: 100%;
  //   background-image: url("../assets/images/mainBanner.jpeg");
  //   background-repeat: no-repeat;
  //   background-position: center;
  //   background-size: cover;
  //   padding: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  background: rgb(187,236,214);
  background: linear-gradient(90deg, rgba(187,236,214,1) 0%, rgba(177,223,223,1) 35%, rgba(167,198,252,1) 100%);

  &--left{
    padding: 20px 8%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  //   @media screen and (max-width: 1024px) {
  //     padding: 40px;
  //   }

  h1 {
    // font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    // color: #18a0fb;
    text-align: left;

    background: #181818;
    border-radius: 10px;
    width: fit-content;
    color: #FFF;
    font-weight: 600;
    padding: 4px 16px;
    margin-bottom: 8px;

    @media screen and (max-width: 991px){
        font-size: 20px;
        line-height: 30px;
    }
  }
  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    color: #181818;
    // margin-bottom: 50px;
    text-align: left;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #181818;
    margin-bottom: 10px;
    text-align: left;

    @media screen and (max-width: 991px){
        font-size: 14px;
        line-height: 20px;
    }
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #181818;
      margin-bottom: 0;
      text-align: left;
      svg {
        margin-right: 20px;
      }

      @media screen and (max-width: 991px){
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}

.profile-menu {
  .MuiPaper-root {
    ul {
      .mobileProfile {
        display: none;
      }

      li {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .icon-wrapper {
          display: none;
        }

        svg {
          margin-right: 10px;
          width: 30px;
          height: 30px;
          path {
            fill: #aeb6cf;
          }
        }

        .menuItem {
          flex: 1;

          @media screen and (max-width: $screen_540px) {
            display: flex;
            align-items: center;
            a {
              flex: 1;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $screen_540px) {
    .MuiPaper-root {
      left: 0 !important;
      right: 0;
      bottom: 0;
      max-width: 100%;
      background: #18a0fb;
      padding-top: 50px;

      &::before {
        background: #18a0fb;
      }

      ul {
        background: #fff;
        background: #fff;
        border-radius: 48% 48% 0 0;
        height: 85%;
        padding: 200px 0 0;
        position: absolute;
        width: 100%;
        bottom: 0;

        .mobileProfile {
          display: block;
          position: absolute;
          top: 40px;
          left: 50%;
          transform: translateX(-50%);
          // background: transparent !important;
          background: #878282 !important;
          color: #ffffff;
          line-height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          font-size: 2rem;

          .photoArrow {
            .photo {
              img {
                border-radius: 50%;
                object-fit: cover;
              }
            }
            svg {
              position: absolute;
              right: -160%;
              top: -135%;
              margin-right: 0;
              width: 24px;
              height: 24px;
              path {
                fill: #fff;
              }
            }
          }
        }

        li {
          .icon-wrapper {
            display: block;
            svg {
              margin-right: 0;
            }
          }
        }

        .MuiDivider-root {
          display: none;
        }
      }
    }
  }
}

.question-bank-dialog {
  .dialogBoxQuestion {
    @media screen and (max-width: 1024px) {
      flex-direction: column;
      max-height: 92vh;
      overflow: auto;
    }

    .closeDailogNew {
      z-index: 100;
    }
    .quesBank-left {
      .question-bank-header {
        padding: 8px 20px;
        @media screen and (max-width: 1024px) {
          justify-content: flex-start;
          padding: 8px 15px;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          z-index: 10;
          background: rgb(217 217 217);
        }
        @media screen and (max-width: 800px) {
          flex-direction: column;
          align-items: flex-start;
          .bank-title {
            margin-bottom: 10px;
          }
        }

        .question-bank-search {
          @media screen and (max-width: 1300px) {
            width: auto;
          }
          @media screen and (max-width: 800px) {
            width: 100%;
          }
          @media screen and (max-width: 600px) {
            height: auto;
            padding: 10px;
            > div {
              flex-direction: column;
            }
            .cust-select {
              .MuiFormControl-root {
                .MuiInputBase-root {
                  width: 100%;
                  margin: 0 !important;
                }
              }
            }
            .question-search {
              .MuiFormControl-root {
                width: 100%;
                margin: 10px 0 0 0 !important;
              }
            }
          }
        }
      }

      @media screen and (max-width: 1024px) {
        width: 100%;
        .ques-container {
          height: auto;
          .ques-containerBody {
            max-height: 40vh;
            margin-bottom: 10px;
          }
        }
      }

      @media screen and (max-width: $screen_768px) {
        .ques-container {
          .ques-containerBody {
            flex-direction: column;
            .ques-card {
              float: unset;
            }
          }
        }
      }
    }
    .quesBank-right {
      @media screen and (max-width: 1024px) {
        width: 100%;
        background: #fff;
        .ques-queue-container {
          height: 100%;
        }
      }
      @media screen and (max-width: $screen_768px) {
        .ques-queue-container {
          padding-bottom: 100px;
        }
      }
    }
  }
}

.MuiDialog-root {
  .MuiDialog-paper {
    overflow-y: auto !important;
    @media screen and (max-width: 1024px) {
      width: 100%;
      .analyseNotes {
        width: 100%;
      }
    }
    @media screen and (max-width: $screen_768px) {
      .analyseNotes {
        padding: 0 !important;
      }
    }
  }
}

.kwGEbL .experience .MuiInput-underline:before {
  display: none;
}

.dropdown-font-family {
  font-size: 12px;
}

#component-error-text,
#standard-basic-helper-text {
  font-family: "Poppins", sans-serif !important;
  color: #dc3545 !important;
  font-size: 0.875rem !important;
}

.link__container {
  text-align: center;
}
.blue__link {
  color: #18a0fb;
}


.startSurveySlider {
  padding: 40px 100px;
  text-align: center;
  @media screen and (max-width: 1024px) {
    padding: 20px;
  }

  .startSurvey-btn {
    border: 1px solid rgb(24, 160, 251);
    background: rgb(24, 160, 251);
    padding: 10px 35px;
    border-radius: 30px;
    margin: 0px auto;
    cursor: pointer;
    color: white;
    white-space: nowrap;
  }
}

.emptyResult {
  padding: 15px;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}

#rotateArrow {
  transform: rotate(180deg);
}

.experience {
  .MuiFormControl-root {
    .MuiInputBase-root {
      &::after {
        display: none;
      }
    }
  }
}

.pvtAxisContainer li span.pvtAttr {
  white-space: unset;
  padding: 0px 5px;
  word-break: break-word;
}
.ant-space-item {
  margin-bottom: 20px;
}

.ant-space {
  @media screen and (max-width: 576px) {
    max-width: 100%;
  }
  .ant-space-item {
    @media screen and (max-width: 576px) {
      .ant-btn {
        font-size: 12px;
      }
    }
    .pvtUi {
      tbody {
        tr {
          .pvtOutput {
            .js-plotly-plot {
              width: 100%;
              .svg-container {
                // width: 100% !important;
                svg {
                  width: 100%;
                }
              }

              .plotly {
                .modebar-container {
                  .modebar {
                    position: unset;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    .modebar-group {
                      padding: 0;
                      a {
                        padding: 8px;
                        svg {
                          width: 20px;
                          height: auto;
                        }
                      }

                      #symbol {
                        display: none;
                      }
                    }
                  }
                }
              }
            }
          }
          @media screen and (max-width: 1024px) {
            display: flex;
            flex-direction: column;

            .pvtRenderers {
              padding-left: 0;
              .pvtDropdown {
                margin: 10px 0;
              }
            }

            .pvtOutput {
              .pvtTable {
                margin: 10px 0;
                tbody {
                  tr {
                    flex-direction: row;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.ant-table-wrapper {
  .ant-table-content {
    overflow-x: auto;
    // .ant-table-thead{
    //     .ant-table-cell{
    //         white-space: nowrap;
    //     }
    // }
    .ant-table-cell {
      .ant-space-item {
        margin-bottom: 0;
      }
    }
  }
}

.analysisCustomSelectBox {
  @media screen and (max-width: 1024px) {
    max-width: 100% !important;
  }
}

.analysisCustomSelect {
  background: #fff url(../assets/images/arrow-downicon.svg) no-repeat;
  background-size: 16px 9px;
  background-position: bottom 22px right 12px;
  width: 100%;
  border: 1px solid #fff;
  box-shadow: -4px 3px 10px rgb(143 155 186 / 20%);
  border-radius: 5px;
  height: 55px;

  .MuiSelect-select {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.03em;
    color: #000000;
  }

  .MuiOutlinedInput-notchedOutline,
  .MuiSvgIcon-root {
    display: none;
  }
}

.analysisMenuItem {
  max-width: 485px;
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.6 !important;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }
}

.selectPaperRoot {
  max-height: 400px !important;
  overflow-y: auto !important;
}

.tableCellLink {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.tableCellText {
    font-size: 16px;
    max-height: 200px;
    overflow-y: auto;
    padding-right: 8px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-line-clamp: 5;
//   -webkit-box-orient: vertical;
}

.showAnswerRow {
  background: rgba(24, 160, 251, 0.2);
}
.showAnswerRowItems {
  background: rgba(24, 160, 251, 0.1);
  border-bottom: 1px solid rgb(24, 160, 251, 0.5);
}

.tableAnswerItems {
  color: #26293f;
  b {
    min-width: 25px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .YourHighlightClass {
    padding: 0;
    color: #26293f !important;
  }
}

.experienceTypeContainer {
  width: calc(100% - 638px);
  margin: auto;
  position: absolute;
  left: 302px;
  top: 19px;
  z-index: 1;

  .experienceTypeLabel {
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 6px 12px;
    width: -moz-fit-content;
    width: fit-content;
    border: 1px solid #aeb6cf;
    color: #18a0fb;
    background: #ffffff;
    box-shadow: -4px 3px 10px rgb(143 155 186 / 20%);
    backdrop-filter: blur(20px);
    border-radius: 5px;
  }

  @media screen and (max-width: 1024px) {
    position: unset;
    width: 100%;
    margin-bottom: 10px;
  }
}

.answerAnalysisPage {
  .ant-space {
    width: 100%;
    .ant-space-item {
      @media screen and (max-width: 991px) {
        margin-bottom: 0;
      }
      .pvtUi {
        width: 100%;
        .pvtOutput {
          background-color: #fff;
        }
      }
    }
  }
}

.checkbox-autoSubmit {
  .MuiTypography-root {
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #575757;
  }
}
.cookiesLabel {
  margin-bottom: 0;
}
.cookiesSubLabel {
  font-weight: normal;
  font-size: 10px;
  color: #aeb6cf;
}

