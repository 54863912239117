.bodyInner {
    &.preview-formWrap {
        .layoutWrap {
            min-height: 0;
            height: auto;
            display: flex;
            padding: 0;
            margin: 0;
            border: 0;
            border-radius: 0;
            overflow: visible;

            @media screen and (max-width: 991px) {
                flex-direction: column;
            }

            &.layoutColumn {

                //flex-direction: column-reverse;
                .ImageWrap,
                .questWrap {
                    width: 100% !important;

                    .imageInner {
                        img {
                            max-height: fit-content;
                            object-fit: cover;
                        }
                    }
                }
            }

            .questWrap,
            .ImageWrap {
                float: unset !important;
                display: flex;
                align-items: center;

                @media screen and (max-width: 991px) {
                    width: 100% !important;
                }
            }

            .questionAnswerWrap {
                width: 100%;
                padding: 20px 30px 0 30px;

                .answerWrap {
                    margin-bottom: 0 !important;

                    label {
                        margin-bottom: 0;

                        textarea.answerInputArea {
                            height: 130px !important;
                            margin: 10px 0 0 0 !important;
                            border: 1px solid #292929;
                            border-radius: 4px;
                            color: #292929 !important;
                            width: 100%;
                            font-size: 16px !important;
                            line-height: 25px;

                            &::placeholder {
                                color: #292929;
                            }
                        }
                    }

                    input.answerInputArea {
                        height: 45px !important;
                        margin: 10px 0 0 0 !important;
                        border: 1px solid #292929;
                        border-radius: 4px;
                        color: #292929 !important;
                        width: 100%;
                        font-size: 16px !important;
                        line-height: 25px;

                        &::placeholder {
                            color: #292929;
                        }
                    }
                }
            }

            .ImageWrap,
            .imgWrap {
                .imageWrapInner {
                    padding: 20px 30px 0 30px;

                    .contactInner {
                        margin-top: 10px;

                        input {
                            height: 45px !important;
                            border: 1px solid #292929;
                            border-radius: 4px;
                            color: #292929 !important;
                            width: 100%;
                            font-size: 16px !important;
                            line-height: 25px;
                            padding: 4px;

                            &::placeholder {
                                color: #292929;
                            }
                        }

                        .flag {
                            >div {
                                align-items: center;
                                padding-bottom: 0;
                            }

                            @media screen and (max-width: 540px) {
                                margin: 0 0 10px 0;
                                width: 100%;

                                >div {
                                    width: 100%;
                                }
                            }
                        }
                    }

                    .ratingInner {
                        margin: 10px 0 0 0;

                        >div {
                            margin: 0 !important;
                        }
                    }

                    .multipleInner {
                        padding: 0 !important;
                        margin-top: 10px;

                        .item {
                            &:last-child {
                                margin-bottom: 0;
                            }
                        }

                        &.horizontal {

                            /* width */
                            &::-webkit-scrollbar {
                                height: 10px;
                                cursor: pointer;
                            }

                            /* Track */
                            &::-webkit-scrollbar-track {
                                background: #f1f1f1;
                            }

                            /* Handle */
                            &::-webkit-scrollbar-thumb {
                                background: #888;
                                cursor: pointer;
                            }

                            /* Handle on hover */
                            &::-webkit-scrollbar-thumb:hover {
                                background: #555;
                            }
                        }
                    }

                    .imageUploadWrap {
                        margin-top: 10px;

                        .imageUpload {
                            margin-top: 0;
                        }
                    }

                    .fileUploadInner {
                        padding-bottom: 10px;

                        .fileUpload {
                            margin-top: 10px;
                        }
                    }

                    &.imageWrapGrid {
                        position: relative;

                        @media screen and (max-width: 576px) {
                            padding: 0;
                            overflow: visible;
                        }

                        .imageUploadItems {
                            display: grid !important;
                            grid-template-columns: repeat(3, 1fr);
                            gap: 24px;
                            margin: 0;

                            @media screen and (max-width: 1280px) {
                                grid-template-columns: repeat(2, 1fr);
                            }

                            @media screen and (max-width: 576px) {
                                grid-template-columns: 1fr;
                            }

                            .imageUpload {
                                width: 100%;
                                margin: 0;
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                                border-radius: 10px 10px 10px 10px;
                                overflow: hidden;
                                border: 0;
                                border-right: 2px solid #138D66;
                                box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.3);
                                background-color: #FFFFFF;
                                padding: 40px 20px;
                                height: 100%;
                                min-height: 1px;

                                .inner {
                                    height: unset;

                                    img {
                                        width: 100%;
                                        height: 70px;
                                        object-fit: contain;
                                        position: unset;
                                        transform: unset;
                                    }
                                }

                                .imageCardContainer {
                                    display: flex;
                                    flex-direction: column;
                                    gap: 20px;
                                    align-items: center;
                                    height: 100%;

                                    .imageLabel {
                                        text-align: center;

                                        &.imageDesc {
                                            text-align: left;
                                            padding-left: 10px;
                                            line-height: 1.8;
                                            color: #666666;
                                            font-size: 14px;
                                            flex: 1;
                                        }

                                        &.imageTitle {
                                            color: #138D66;
                                            font-weight: 700;
                                        }
                                    }
                                }
                            }
                        }

                        .imageTop,
                        .imageBottom {
                            img {
                                width: 100%;
                                height: 80px;
                            }
                        }
                    }
                }

                .decoratorImage {
                    padding: 20px 30px 0 30px;
                    width: 100%;

                    .imageBottom {
                        img {
                            width: 100%;
                            height: 80px;
                        }

                        &.imageRotate {
                            transform: rotate(180deg);
                        }
                    }
                }

                .imageInner {
                    padding: 20px 30px 0 30px;

                    img {
                        max-height: 200px;
                        object-fit: cover;
                        margin: 0 !important;
                    }
                }

                .dropDownInner {
                    margin-top: 10px;

                    .selectWrap {
                        .selectInner {
                            width: 100%;
                            border: 1px solid #292929;
                            border-radius: 4px;

                            .autoSelectWrap {
                                .MuiFormControl-root {
                                    .MuiFormLabel-root {
                                        color: #292929 !important;
                                        background-color: #FFF;
                                        padding: 0 4px;
                                        margin-left: 4px;
                                    }

                                    .MuiInputBase-root {
                                        .MuiInputBase-input {
                                            padding: 4px 4px 8px 4px;
                                        }

                                        &:before {
                                            border: 0 !important;
                                        }

                                        &:after {
                                            display: none;
                                        }
                                    }
                                }

                                &.Mui-focused {
                                    .MuiFormControl-root {
                                        .MuiFormLabel-root {
                                            padding: 0 4px;
                                            margin-left: 4px;
                                            background-color: #FFF;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                @media screen and (max-width: 991px) {
                    width: 100% !important;
                }
            }

            .ques {
                h3 {
                    min-height: unset;
                    font-size: 18px;
                    line-height: 25px;
                    color: #292929;
                }

                em,
                p {
                    font-size: 12px;
                    color: gray;
                }
            }
        }
    }
}

.paymentForm {
    padding: 10px 25px 20px;
    width: 100%;

    @media screen and (max-width: 768px){
        padding: 0;
    }

    &__title {
        font-family: Whitney A,Whitney B,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
        font-weight: 500;
        font-size: 24px;
        margin: 0 0 10px 0;
        text-align: center;
    }

    &__controls {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 25px;
        margin: 0 0 30px 0;

        @media screen and (max-width: 1368px) {
            gap: 20px 10px;
        }

        @media screen and (max-width: 1320px) {
            grid-template-columns: 1fr;
        }

        &--title{
            border-bottom: 1px solid #cdcdcd;
            color: #18a0fb;
            display: block;
            padding-bottom: 1rem;
            margin-bottom: 1.25rem;
            font-family: Whitney A,Whitney B,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
            font-weight: 700;
            line-height: 1.1875;
            text-transform: uppercase;
            transition: all .2s ease-in-out;
        }
    }

    &__control {
        position: relative;

        label {
            font-size: 1rem;
            font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-weight: 500;
            line-height: 1.375;
            margin: 0 0 0.5rem 0;
            color: #181818;
            display: block;
        }

        input {
            font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            display: block;
            width: 100%;
            height: calc(3.4375rem + 1px);
            position: relative;
            padding: 1rem 1.25rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #1a1a1a;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #949494;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            outline: 0;

            &:focus{
                border-width: 2px;
                border-color: #18A0FB;
                box-shadow: 0 0 0 0 #18A0FB;
            }

            &::placeholder {
                color: #1a1a1a;
            }
        }

        &--text{
            font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-size: 14px;
            color: #1a1a1a;
            line-height: 20px;
            margin-top: 4px;
            display: block;
        }
    }

    &__buttonList {
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        margin: 20px 0 30px 0;
        position: relative;

        &:empty {
            display: none;
        }

        button {
            position: relative;

            &.defaultBtn {
                font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                background-color: rgba(41 123 240 / 71%);
                border: 1px solid rgba(24, 160, 251, 0.2);
                width: fit-content;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                font-size: 1rem;
                font-weight: 600;
                height: 3.75em;
                line-height: 1;
                padding: 1.2887rem;
                text-transform: uppercase;

                &.defaultBtnOutlined {
                    background-color: #FFFFFF !important;
                    color: #18A0FB !important;
                    border-color: #18A0FB !important;
                }
            }

            .cancelIcon {
                position: absolute;
                right: -12px;
                top: -12px;
                width: 24px;
                height: 24px;
            }
        }

        span.error {
            left: 0 !important;
            color: #dc3545 !important;
        }
    }

    &__addButton {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        position: relative;
        margin: 0;

        select {
            border-color: #18A0FB;
            height: 40px;
            min-width: 120px;
            padding: 8px 20px 10px 5px;
            border-radius: 4px;
            background: #F8F8F8;
            outline: 0;
            cursor: pointer;
            font-size: 14px;
        }

        span.error {
            left: 0 !important;
            color: #dc3545 !important;
        }

        &.addAmountGroup {
            .addAmountBtn {
                font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                padding: 1rem 1.25rem;
                font-size: 14px;
                font-weight: 700;
                line-height: 1.5;
                color: #1a1a1a;
                background-clip: padding-box;
                border: 1px solid #949494;
                background: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                width: fit-content;
                white-space: nowrap;
                height: 56px;
                text-transform: uppercase;
            }

            .input-group {
                gap: 10px;

                input{
                    width: auto;
                    flex: 1;
                    &::placeholder {
                        color: #1a1a1a;
                    }
                }

                button {
                    background: #18a0fb;
                    border-radius: 0;
                    border: 1px solid #18a0fb;
                    width: fit-content;
                    color: #FFFFFF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    font-weight: 500;
                    font-size: 1rem;
                    font-weight: 600;
                    height: 56px;
                    line-height: 1;
                    padding: 1.2887rem;
                    text-transform: uppercase;
                }
            }
        }
    }

    span.error {
        position: absolute;
        bottom: -20px;
        left: 90px;
        font-size: 14px;
        color: #dc3545 !important;
        font-family: Whitney A,Whitney B,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
    }

    &__serviceCharge {
        .paymentForm__buttonList {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            gap: 10px;
            margin: 0;

            h2 {
                font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                // color: #181818;
                color: #18a0fb;
                font-size: 14px;
                font-weight: 700;
                line-height: 24px;
                margin: 0;
                text-transform: uppercase;
            }

            p {
                color: #000000;
                font-size: 14px;
                font-weight: 500;
                line-height: normal;
                letter-spacing: 0.42px;
            }

            .buttonGroup {
                display: flex;
                gap: 20px;
                .defaultBtn {
                    width: 150px;
                    position: relative;

                    span {
                        border-radius: 2px;
                        background: #FFF;
                        width: 21px;
                        height: 21px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        color: #18A0FB;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: normal;
                        letter-spacing: 0.36px;
                        position: absolute;
                        left: 8px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }

    &__previewFlex {
        display: flex;
        flex-direction: column;
        gap: 12px;

        // .flex-6 {
        //     flex: 0 0 60%;
        // }
    }
}

.ant-select-dropdown{
    padding: 0;
    .ant-select-item{
        .ant-select-item-option-content{
            font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            padding: 1rem 1.25rem;
            font-size: 1rem;
            font-weight: 600;
            line-height: 1.5;
            color: #1a1a1a;
        }
    }
}