.CreateSurveyWrapper {
    .questionWrapper {
        .btnSave{
            margin-left: 30px;
            margin-bottom: 20px;
        }
    }
    .disableCard{
        opacity: 0.5;
        pointer-events: none;
    }
}

.questionWrapCreateForm{
    padding-top: 20px;
    .questionTex{
        padding: 0 30px;
    }
    .imageWrapInner{
        padding: 20px 30px;
        .ratingInner{
            margin:0;
            >div{
                margin: 0 !important;
            }
        }
        .dropDownInner{
            margin-top: 0;
            .selectWrap{
                .selectInner{
                    width: 300px;
                    border: 1px solid #292929;
                    border-radius: 4px;
                    .autoSelectWrap{
                        .MuiFormControl-root{
                            .MuiFormLabel-root{
                                color: #CCC !important;
                            }
                            .MuiInputBase-root{
                                .MuiInputBase-input{
                                    padding: 4px 4px 8px 4px;
                                }
                                &:before{
                                    border: 0 !important;
                                }
                                &:after{
                                    display: none;
                                }
                            }
                        }

                        &.Mui-focused{
                            .MuiFormControl-root{
                                .MuiFormLabel-root{
                                    padding: 0 4px;
                                    margin-left: 4px;
                                    background-color: #FFF;
                                }
                            }
                        }
                    }
                }
            }
        }
        .multipleInner{
            padding: 0;
        }
    }
    .form-control{
        margin-bottom: 10px;
        border: 0;
        padding: 0;
        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
    .form-input-control{
        width: 100%;
        border: 1px solid #292929;
        border-radius: 4px;
        margin-bottom: 10px;
        height: 40px;
        font-style: normal;
        font-size: 16px;
        color: #292929;
        font-weight: 400;

        &::placeholder{
            color: #CCC;
        }

        &:focus{
            outline: 0;
            box-shadow: none;
        }
    }
}


.imageCardWrap{
    overflow: visible;
}
.imageCardList{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media screen and (max-width: 1280px){
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 576px){
        grid-template-columns: 1fr;
    }

    .ant-card{
        border: 0;
        border-radius: 10px;
        border-color: #138D66;
        border-right-width: 2px;
        border-style: solid;
        padding-top: 40px;
        padding-right: 20px;
        padding-bottom: 40px;
        padding-left: 20px;
        box-shadow: 0px 2px 18px 0px rgba(0,0,0,0.3);
        position: relative;

        .ant-card-body{
            padding: 0;

            &::before, &::after{
                display: none;
            }

            .cancel{
                position: absolute;
                z-index: 1;
                right: 0;
                top: 0;
                cursor: pointer;
            }

            .questionWrap{
                .question{
                    .num{
                        display: none;
                    }

                    .questionInputArea{
                        min-height: 1px;
                    }
                }
            }

            > div{
                display: flex;
                flex-direction: column;
                gap: 20px;

                .imageUploadWrap {
                    .fileUploadInner{
                        .imgWrapper{
                            width: 100%;
                            cursor: pointer;
                            input[type="file"]{
                                width: 0;
                                opacity: 0;
                            }
                            img{
                                width: 100%;
                                height: 70px;
                                object-fit: contain;
                            }
                        }
                    }
                }
            }

            .questionInputArea{
                width: 100%;
                height: auto;
                overflow: visible;
            }
        }
    }
}

.paymentForm{
    padding: 10px 25px 20px;
    width: 100%;

    @media screen and (max-width: 768px){
        padding: 0;
    }

    &__title{
        font-family: Whitney A,Whitney B,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,Liberation Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
        font-weight: 500;
        font-size: 24px;
        margin: 0 0 10px 0;
        text-align: center;
    }

    &__controls{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        margin: 0 0 30px 0;
    }

    &__control{
        position: relative;

        label{
            font-size: 1rem;
            font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-weight: 500;
            line-height: 1.375;
            margin: 0;
            color: #181818;
        }

        input{
            display: block;
            width: 100%;
            height: calc(3.4375rem + 1px);
            position: relative;
            padding: 1rem 1.25rem;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #1a1a1a;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid #949494;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            border-radius: 0;
            outline: 0;

            &:focus{
                border-width: 2px;
                border-color: #18A0FB;
                box-shadow: 0 0 0 0 #18A0FB;
            }

            &::placeholder {
                color: #1a1a1a;
            }
        }

        &--text{
            font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
            font-size: 14px;
            color: #1a1a1a;
            line-height: 20px;
            margin-top: 4px;
            display: block;
        }
    }

    &__buttonList{
        display: flex;
        align-items: center;
        gap: 15px;
        flex-wrap: wrap;
        margin: 20px 0 30px 0;
        position: relative;

        button{
            position: relative;
            &.defaultBtn{
                background-color: rgba(41 123 240 / 71%);
                border: 1px solid rgba(24, 160, 251, 0.2);
                width: fit-content;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-weight: 500;
                font-size: 1rem;
                font-weight: 600;
                height: 3.75em;
                line-height: 1;
                padding: 1.2887rem;
                text-transform: uppercase;
            }

            .cancelIcon{
                position: absolute;
                right: -12px;
                top: -12px;
                width: 24px;
                height: 24px;
            }
        }

        span.error{
            left: 0 !important;
            color: #dc3545 !important;
        }
    }

    &__addButton{
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 8px;
        position: relative;
        margin: 0;

        select{
            border-color: #18A0FB;
            height: 40px;
            min-width: 120px;
            padding: 8px 20px 10px 5px;
            border-radius: 4px;
            background: #F8F8F8;
            outline: 0;
            cursor: pointer;
            font-size: 14px;
        }

        span.error{
            left: 0 !important;
            color: #dc3545 !important;
        }
    }

    .dropDownWrap {
        position: relative;
        width: 100%;
  
        button {
          border: 1px solid #18a0fb;
          width: 150px;
          font-size: 16px;
          line-height: 24px;
          text-align: center;
          color: #18a0fb;
          height: 42px;
          border-radius: 3px;
          background-color: transparent;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 15px;
  
          @media screen and (max-width: 1024px) {
            width: 120px;
          }
        }
  
        ul.drop {
          position: absolute;
          top: calc(100% + 5px);
          right: 0;
          list-style: none;
          z-index: 199;
          background: #ffffff;
          border-radius: 4px;
          width: 100%;
          padding: 0;
          box-shadow: 0px 12px 58px rgba(34, 43, 69, 0.15);
  
          li {
            width: 100%;
            padding: 4px 10px;
            font-family: "Poppins";
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: #8a94a6;
            text-align: left;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            transition: all 0.3s;
  
            .emptyDiv {
              width: 20px;
              height: 20px;
              background-color: transparent;
              // margin-right: 10px;
            }
  
            svg {
              margin-right: 10px;
            }
  
            &:hover,
            &.active {
              transition: all 0.3s;
              background: rgba(225, 228, 232, 0.5);
              color: #18a0fb;
            }
          }
        }

        .MuiFormControl-root{
            width: 100%;
            .ant-select {
                .ant-select-selector{
                    font-size: .875rem;
                    height: calc(3.4375rem + 1px);
                    overflow: hidden;
                    border: 1px solid #949494;
                    border-radius: 0;
                    box-shadow: none !important;
                    background-image: url("../../../assets/images/up-arrow.svg");
                    background-repeat: no-repeat;
                    background-size: 12px;
                    background-position: right 20px center;

                    .ant-select-selection-item{
                        font-family: Whitney A, Whitney B, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
                        color: #1a1a1a;
                        font-size: 14px;
                        font-weight: 700;
                        text-transform: uppercase;
                        line-height: normal;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }

                .ant-select-arrow{
                    display: none;
                }
            }
        }
  
        @media screen and (max-width: 768px) {
          margin: 0 0 20px 0;
          button {
            width: 100%;
          }
        }
    }

    span.error{
        position: absolute;
        bottom: -20px;
        left: 90px;
        font-size: 12px;
        color: #dc3545 !important;
    }
}