.analyseNotes {
    padding: 0 !important;
    max-width: 1000px;
    margin: 0 auto;
    min-height: 700px;
    overflow: hidden !important;
}

.analyseNotesHeader {
    padding: 15px 20px;
    background: rgb(194 215 230);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 99;
}

.notesDialogTitle {
    font-size: 18px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.17px;
    color: #181818;
    position: relative;
    text-align: left;
}

.analyseDetailwrap {
    background: #FFFFFF;
    padding: 30px;
}

.analyseDetailwrap .flexbox {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
}

.analyseDetailwrap .flexbox:last-child {
    margin-bottom: 0;
}

.analyseDetailwrap .analyseLabel {
    font-size: 16px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0.17px;
    color: #8A94A6;
    margin-right: 15px;
    width: 135px;
    margin-bottom: 15px;
}

.analyseDetailwrap .analyseDetail {
    width: calc(100% - 150px);
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.17px;
    color: #AEB6CF;
}

.analyseDetailwrap .shortText {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.03em;
    color: #181818;
}

.analyseDetailwrap .shortText p {
    margin-bottom: 15px;
}

.analyseDetailwrap .shortText p:last-child {
    margin-bottom: 0;
}

.analyseDetailwrap .summayList {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    list-style: none;
    padding-left: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.17px;
    color: #AEB6CF;
    list-style: none;
    margin-bottom: 0;
}

.analyseDetailwrap .summayList li {
    margin-bottom: 12px;
    padding-right: 15px;
    width: 50%;
}

.analyseDetailwrap .summayList li:nth-child(2n+2) {
    margin-right: 0;
}

.analyseDetailwrap .analyseDetail label {
    display: block;
    margin-bottom: 0;
}

.analyseDetailwrap .category-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    border: 1px solid #18A0FB;
    padding-left: 0;
    margin-bottom: 0;
    overflow-x: auto;
    min-height: 30px;
}

.analyseDetailwrap .category-list::-webkit-scrollbar {
    border-radius: 10px;
    width: 8px;
    height: 6px;
    background: rgba(204, 204, 204, 0.3);
}

.analyseDetailwrap .category-list::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(204, 204, 204, 0.4);
    cursor: pointer;
}

.analyseDetailwrap .category-list li {
    padding: 10px 15px;
    min-width: 150px;
    font-size: 14px;
    font-weight: 400;
    color: #AEB6CF;
    text-align: center;
    border-right: 1px solid transparent;
}

.analyseDetailwrap .category-list li:last-child {
    border-right: none;
}

.analyseDetailwrap .category-list li.active:last-child {
    border-left: 1px solid #18A0FB;
}

.analyseDetailwrap .category-list li.active {
    background: rgba(24, 160, 251, 0.3);
    border-right-color: #18A0FB;
    font-weight: 500;
    color: #181818;
}

.analyseDetailwrap .inputField {
    background: #FFFFFF;
    padding: 12px;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.17px;
    color: #181818;
    min-height: 45px;
    border: 1px solid #18A0FB;
    border-radius: 0;
    margin-bottom: 0;
    box-shadow: none;
    filter: none;
}

.analyseDetailwrap .emailField {
    margin-bottom: 10px;
}

.analyseDetailwrap .emailField .items span+span {
    margin: 5px;
}

.analyseDetailwrap .emailField .items {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: rgba(24, 160, 251, 0.3);
    border: 1px solid #18A0FB;
    padding: 10px;
    margin-bottom: 10px;
}

.analyseDetailwrap .emailField .items .emailfield-box {
    display: inline-flex;
    align-items: center;
    background: #18A0FB;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    border-radius: 3px;
    padding: 8px 32px 8px 10px;
    position: relative;
    margin: 5px;
}

.analyseDetailwrap .emailField .items span input {
    background: #18A0FB;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    border: 1px solid #18A0FB;
    border-radius: 3px;
    box-shadow: none;
    outline: none;
}

.analyseDetailwrap .emailField .items .emailfield-box span input {
    border: 1px solid #18A0FB;
    box-shadow: none;
}

.analyseDetailwrap .emailField .items .emailfield-box input {
    background: none;
    font-size: 14px;
    font-weight: 400;
    color: #fff;
    padding: 0;
    width: auto;
    border: none;
    outline: none;
    box-shadow: none;
}

.analyseDetailwrap .emailField .items .emailfield-box button {
    background: none;
    font-family: 'Montserrat';
    font-size: 18px;
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 1;
}

.analyseDetailwrap .emailField+p {
    margin-bottom: 0;
}

.analyseDetailwrap .analyseDetail .notes {
    padding-bottom: 10px;
}

.analyseDetailwrap .css-w4z10b-MuiStack-root {
    background: rgba(24, 160, 251, 0.3);
    padding: 12px;
    display: block;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.17px;
    color: #181818;
    min-height: 45px;
    border: 1px solid #18A0FB;
    border-radius: 0;
}

.analyseDetailwrap .emailField .css-gac2fo-MuiChip-root {
    background: #18A0FB;
    padding: 8px 10px;
    color: #fff;
    border-radius: 4px;
}

.analyseDetailwrap .buttonsWrap .ant-btn {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.17px;
    border-radius: 4px;
    height: auto;
    min-width: 150px;
    margin-right: 14px;
    border-color: #18A0FB;
}

.analyseDetailwrap .buttonsWrap .ant-btn:last-child {
    margin-right: 0;
}

.map {
    display: inline-block;
    position: relative;
    height: 100%;
}

.map img {
    width: 100%;
    height: 100%;
    margin: auto;
    border: 1px solid black;
}

.pointer {
    top: 0;
    left: 0;
    position: absolute;
    border-radius: 100%;
    color: transparent;
    border: 5px solid red;
}

@media screen and (max-width:639px) {
    .analyseModalbox {
        display: block;
    }

    .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
        display: block !important;
        margin: 15px !important;
    }

    .analyseNotes {
        min-width: 1px;
    }

    .analyseNotesHeader {
        padding-left: 15px;
        padding-right: 15px;
    }

    .analyseDetailwrap {
        padding: 15px;
    }

    .analyseDetailwrap .flexbox {
        display: block;
    }

    .analyseDetailwrap .analyseLabel {
        width: 100%;
        margin-right: 0;
    }

    .analyseDetailwrap .analyseDetail {
        width: 100%;
    }

    .analyseDetailwrap .buttonsWrap .ant-btn {
        min-width: 130px;
    }
}

@media screen and (max-width:567px) {
    .analyseDetailwrap .category-list {
        flex-wrap: wrap;
        flex-direction:column;
    }

    .analyseDetailwrap .category-list li {
        /* width: 50%; */
        width: 100%;
        padding: 10px;
        font-size: 12px;
        line-height: 1.333;
    }

    .analyseDetailwrap .category-list li.active {
        border-right: 0;
        border-left: 0 !important;
        border-bottom: 1px solid #18A0FB;
    }

    /* .analyseDetailwrap .category-list li:nth-child(2) {
        border-left: 1px solid #18A0FB;
        border-right: none;
    }

    .analyseDetailwrap .category-list li:nth-child(3),
    .analyseDetailwrap .category-list li:nth-child(4) {
        border-top: 1px solid #18A0FB;

    } */
}