.summary {
  width: 100%;
  height: calc(100% - 168px);
  padding: 1rem 3.125rem;
  overflow: auto;

  @media screen and (max-width: 1024px) {
    padding: 1rem;
  }
  @media screen and (max-width: 991px) {
    height: calc(100% - 105px);
  }

  &__head {
    width: 100%;
  }

  &__items {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 620px) {
      flex-direction: column;
    }

    @media screen and (max-width: 576px) {
      justify-content: center;
    }
  }

  &__flex {
    display: flex;
    align-items: center;

    @media screen and (max-width: 620px) {
      width: 100%;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    .toggleWrap {
      padding: 0 10px 0 0;

      @media screen and (max-width: 768px) {
        padding: 0 5px;
        justify-content: center;
      }
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 0.5rem;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 768px) {
      margin-right: 0;
      padding: 0.4rem;
    }
    @media screen and (max-width: 620px) {
      flex: 0 0 48%;
    }

    svg {
      margin-right: 0.625rem;
    }

    span,
    p {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1.5;
      text-align: center;
      letter-spacing: 0.03em;
      color: #8a94a6;
    }

    p,
    .labelField {
      color: #aeb6cf;
      margin: 0;
    }

    &.p-0 {
      padding: 0;
    }
  }

  &__score {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    margin-top: 0.625rem;
    background: #ffffff;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    padding: 10px;

    @media screen and (max-width: 576px) {
      grid-template-columns: 1fr;
    }

    &--items {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .score__list {
        list-style-type: none;
        padding: 0;
        margin: 0 0 0.5rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        &--data {
          width: 70px;
          height: 70px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 2.25rem;

          @media screen and (min-width: 577px) and (max-width: 768px) {
            width: 50px;
            height: 50px;
            margin-right: 1rem;
          }

          .data--text {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Poppins", sans-serif;
            font-weight: 700;
            font-size: 1.125rem;
            line-height: 1.6;
            text-align: center;
            letter-spacing: 0.03em;
            color: #181818;
            background-color: #fffdfd;

            @media screen and (min-width: 577px) and (max-width: 768px) {
              width: 40px;
              height: 40px;
              font-size: 0.875rem;
            }
          }

          &:last-child {
            margin-right: 0;
          }

          &.bg {
            &--success {
              background: rgba(22, 125, 5, 0.5);
              mix-blend-mode: normal;
              box-shadow: inset 0px 4px 4px rgba(253, 248, 248, 0.25);
            }
            &--warning {
              background: rgba(243, 220, 20, 0.5);
              mix-blend-mode: normal;
              box-shadow: inset 0px 4px 4px rgba(253, 248, 248, 0.25);
            }
            &--danger {
              background: rgba(249, 21, 21, 0.5);
              mix-blend-mode: normal;
              box-shadow: inset 0px 4px 4px rgba(253, 248, 248, 0.25);
            }
            &--blue {
              background: rgba(12, 20, 232, 0.5);
              mix-blend-mode: normal;
              box-shadow: inset 0px 4px 4px rgba(253, 248, 248, 0.25);
            }
            &--purple {
              background: rgba(166, 2, 193, 0.5);
              mix-blend-mode: normal;
              box-shadow: inset 0px 4px 4px rgba(253, 248, 248, 0.25);
            }
          }
        }
      }

      h2 {
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        font-size: 1.125rem;
        line-height: 1.6;
        text-align: center;
        letter-spacing: 0.03em;
        color: #181818;

        @media screen and (max-width: 768px) {
          font-size: 0.875rem;
        }
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 2.5rem;
    margin-top: 1.5rem;

    @media screen and (max-width: 1368px) {
      gap: 1rem;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 1fr;
    }
  }

  &__leftPanel {
    .button__list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      &--item {
        background-color: #ffffff;
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        height: 50px;

        button {
          padding: 0;
          background: transparent;
          width: 100%;
          height: 100%;
        }
        &:hover,
        &.button--selected {
          background-color: #18a0fb;
          button {
            svg {
              path {
                fill: #ffffff;
              }
            }
          }
        }
      }
      .disable {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .content__box {
      background: #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      margin-top: 10px;

      &--head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid #aeb6cf;
        gap: 1rem;

        @media screen and (max-width: 576px) {
          gap: 0;
        }

        .upgrade--dropdown {
          width: 220px;

          @media screen and (max-width: 576px) {
            width: 180px;
          }
          .MuiFormControl-root {
            vertical-align: inherit;
            .MuiInputBase-root {
              .MuiSelect-select {
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                letter-spacing: 0.03em;
                color: #8a94a6;
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
              }
              .MuiOutlinedInput-notchedOutline {
                display: none;
              }
            }
          }
        }

        .content__box--actions {
          display: flex;
          align-items: center;

          button {
            &.upgrade--btn {
              background: #18a0fb;
              border-radius: 2px;
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 12px;
              text-align: center;
              letter-spacing: 0.03em;
              color: #ffffff;
              padding: 8px 10px;
              width: -moz-fit-content;
              width: fit-content;
              margin-right: 1rem;

              @media screen and (max-width: 576px) {
                margin-right: 0.5rem;
              }
            }

            &.help--btn {
              background: transparent;
              padding: 0;
            }
          }
        }
      }

      &--banner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 6px;
        padding: 0 1rem;
        background: #f2f3f7;

        .banner--text {
          padding: 0.5rem 0;
          h2 {
            font-family: "Poppins", sans-serif;
            font-weight: 500;
            font-size: 1rem;
            line-height: 25px;
            letter-spacing: 0.03em;
            color: #181818;
          }
          a {
            font-weight: 400;
            font-size: 0.875rem;
            line-height: 15px;
            letter-spacing: 0.03em;
            text-decoration-line: underline;
            color: #18a0fb;
          }
        }

        .banner--image {
          width: 75px;
          object-fit: contain;
        }
      }

      &--body {
        padding: 1rem;
        max-height: 241px;

        .body--title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
          p {
            margin: 0;
          }
        }

        h2 {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          color: #181818;
          margin: 0;
        }

        ul {
          padding: 0;
          list-style-type: none;
          margin: 10px 0 0 0;
          max-height: 80px;
          overflow: auto;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.03em;
            color: #aeb6cf;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }

            span {
              color: #181818;
              flex: 0 0 15%;
              text-align: center;
            }
          }
        }

        p {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: 0.03em;
          color: #8a94a6;
          margin: 1rem 0 0 0;
        }

        button {
          border: 1px solid #18a0fb;
          background-color: #ffffff;
          border-radius: 2px;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          letter-spacing: 0.03em;
          color: #18a0fb;
          width: -moz-fit-content;
          width: fit-content;
          padding: 8px 10px;
          margin: 1rem 0 0 0;
        }
      }
    }
  }

  &__rightPanel {
    .grid {
      &__cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @media screen and (max-width: 1368px) {
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
        }
        @media screen and (max-width: 576px) {
          grid-template-columns: 1fr;
        }

        &--item {
          padding: 1.5rem 1rem 1.5rem 1.5rem;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;

          @media screen and (max-width: 1024px) {
            padding: 1rem;
          }

          .card--info {
            padding-right: 1rem;
            flex: 1;
            h2 {
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              font-size: 18px;
              line-height: 27px;
              letter-spacing: 0.03em;
              color: #8a94a6;
              margin: 0;
              white-space: nowrap;
            }
            span {
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              font-size: 18px;
              line-height: 27px;
              letter-spacing: 0.03em;
              color: #181818;
            }
          }
          .card--icon {
            width: 64px;
            height: 64px;
          }

          &.bg {
            &--linearGradientPink {
              background: #ffe5f8;
            }
            &--linearGradientGreen {
              background: #e4f8eb;
            }
            &--linearGradientPurple {
              background: #ebe8fe;
            }
          }
        }
      }

      &__row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 50px;
        margin-top: 20px;

        @media screen and (max-width: 1368px) {
          gap: 1rem;
        }
        @media screen and (max-width: 991px) {
          height: auto;
        }
        @media screen and (max-width: 576px) {
          grid-template-columns: 1fr;
        }
      }

      &__col {
        h2 {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.03em;
          color: #181818;
        }
        p {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          letter-spacing: 0.03em;
          color: #8a94a6;
          min-height: 30px;
        }
        ul {
          padding: 0;
          list-style-type: none;
          margin: 0;
          background: #ffffff;
          box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
          border-radius: 2px;
          max-height: 100%;
          overflow: auto;
          display: flex;
          flex-direction: column;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-family: "Poppins", sans-serif;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.03em;
            color: #aeb6cf;
            padding: 1.85rem 2rem;
            background: #ffffff;
            cursor: pointer;
            flex: 1;

            @media screen and (max-width: 1024px) {
              padding: 1rem;
            }

            span {
              font-family: "Poppins", sans-serif;
              font-weight: 700;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.03em;
              color: #181818;
            }

            &.active {
              background: rgba(24, 160, 251, 0.2);
              color: #181818;
              &:hover {
                background: rgba(24, 160, 251, 0.2);
              }
            }
            &:hover {
              background: rgba(24, 160, 251, 0.05);
              color: #181818;
            }
            button {
              &.upgrade-btn {
                background: #18a0fb;
                border-radius: 2px;
                font-family: "Poppins", sans-serif;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                letter-spacing: 0.03em;
                color: #ffffff;
                padding: 8px 10px;
                width: -moz-fit-content;
                width: fit-content;
              }
            }
          }
          .textAnalysis {
            &:hover {
              background: rgba(24, 160, 251, 0.05);
              color: #181818;
            }
          }
        }
      }
    }
  }
}
