.alerts-filter {
  .item:last-child {
    align-items: flex-end;
  }
}

.dataraw-wrap {
  .dataraw-list {
    .dataraw-item {
      .dataraw-box {
        background: #FFFFFF;
        position: relative;

        .dataraw-box-closeBtn {
          position: absolute;
          top: -10px;
          right: -10px;
          z-index: 99;
          background: transparent;
          padding: 0;
          margin: 0;
        }

        .flexbox {
          &.alerts-dashboard {
            padding: 10px 10px 10px 40px !important;

            & + .content-box {
              position: relative;
            }
          }

          .dateWrapper {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .btnWrapper {
              display: flex;
              align-items: center;

              .iconWrapper {
                position: relative;
              }

              .MuiCheckbox-root {
                padding: 0;
                margin-left: 12px;
                svg {
                  path {
                    fill: #aeb6cf;
                  }
                }

                &.Mui-checked {
                  svg {
                    path {
                      fill: #2dd03c;
                    }
                  }
                }
              }
            }
          }
        }

        .share {
          &__btn {
            padding: 0;
            width: 20px;
            height: 20px;
            position: relative;
            z-index: 10;
          }

          &__list {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            box-shadow: 0px 12px 58px rgb(34 43 69 / 15%);
            border: 1px solid #e1e4e8;
            border-radius: 4px;
            padding: 10px 12px;
            background: #ffffff;
            position: absolute;
            right: 30px;
            top: 2px;
            opacity: 0;
            visibility: hidden;
            z-index: 99;

            &.share__list--show {
              opacity: 1;
              visibility: visible;
            }

            &::after {
              content: "";
              width: 0px;
              height: 0px;
              border-style: solid;
              border-width: 0 10px 10px 10px;
              border-color: transparent transparent #ffffff transparent;
              transform: rotate(0deg);
              position: absolute;
              right: 15px;
              top: -8px;
            }
            &::before {
              content: "";
              width: 0px;
              height: 0px;
              border-style: solid;
              border-width: 0 10px 11px 10px;
              border-color: transparent transparent #e1e4e8 transparent;
              transform: rotate(0deg);
              position: absolute;
              right: 15px;
              top: -10px;
            }

            &--item {
              margin-right: 8px;
              &:last-child {
                margin-right: 0;
              }
            }
          }

          &--icons {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            margin: 0;
            background: #ffffff;
            box-shadow: 0px 4px 10px rgba(10, 68, 106, 0.15);
            border: 1px solid #ffffff;

            &.active {
              border: 1px solid #18a0fb;
            }

            img {
              width: 18px;
              height: auto;
              object-fit: contain;
            }
          }
        }

        .content-box{
            .content-item{
                &.content-item-overflow{
                    height: calc(100% - 40px) !important;
                }
            }
            .content-footer{
                width: 100%;
                text-align: right;
                margin-top: 1rem;
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 14px;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media screen and (max-width:991px){
                    position: static;
                    margin-top: 0;
                    padding: 0;
                }

                ul{
                    list-style-type: none;
                    padding: 0;
                    margin: 0 20px 0 0;
                    display: flex;
                    align-items: center;

                    li{
                        background: #FFFFFF;
                        box-shadow: 0px 4px 10px rgba(10, 68, 106, 0.15);
                        border-radius: 50%;
                        width: 24px;
                        height: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: -8px;
                    }
                }

                button{
                    font-family: 'Poppins', sans-serif;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 15px;
                    text-align: center;
                    letter-spacing: 0.03em;
                    padding: 8px 12px;
                    width: -moz-fit-content;
                    width: fit-content;
                    border-radius: 3px;
                    background: #18A0FB;
                    color: #FFFFFF;
                    border: 1px solid #FFFFFF;
                }

                button:disabled{
                    color: #AEB6CF;
                    border: 1px solid #AEB6CF;
                    background-color: #FFFFFF;
                }
            }
        }
      }
    }
  }
}

.radiogroup {
  display: flex;
  align-items: center;
  border: 1px solid #18a0fb;
  height: 40px;
  border-radius: 4px;
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  @media screen and (max-width: 768px){
    width: 100%;
  }
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
}

/* Create a custom radio button */
.checkmark {
  display: inline-block;
  height: 40px;
  width: 100px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8a94a6;
  padding: 10px 25px;
  border-radius: 4px;
  text-align: center;

  @media screen and (max-width: 768px){
    width: 100%;
  }

  @media screen and (max-width: 576px){
    padding: 10px 15px;
  }
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: #18a0fb;
  color: #ffffff;
}
