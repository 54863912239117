.pageLogicTitle {
  font-size: 20px;
  font-weight: 600;
  text-indent: 0;
  padding: 10px 20px;
  @media screen and (max-width: 576px) {
    font-size: 18px;
  }
}
.createSurveyBody {
  &.pageLoginChart {
    margin-top: 0;
  }
  &.overflow-hidden {
    overflow: hidden;
  }

  .flowChartWrapper {
    margin-top: 18px;
    width: calc(100% - 340px);
    height: 100%;

    @media screen and (max-width: 1991px) {
      width: 100%;
      margin-top: 0;
    }

    > div {
      height: 100% !important;
    }

    .bi.bi-diagram {
      background: transparent;
      box-shadow: none;
      border: 0;

      .bi.bi-diagram-node {
        @media screen and (max-width: 1991px) {
          position: unset;
        }
        > div {
          background: #ffffff !important;
          box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
          border-radius: 3px;
          width: 190px;
          min-height: 100px;

          .main-content {
            font-family: "Poppins";
            min-height: 100px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;
            color: #000000;
            flex-direction: row;
            align-items: center;
            position: relative;
            display: flex;
            padding: 10px;

            .paraContent {
              word-break: break-word;
              padding-bottom: 8px;
            }

            .icon {
              margin-right: 8px;
            }

            .ant-btn {
              width: 20px;
              height: 20px;
              padding: 0;
              border: 0;
              box-shadow: none;
              background-image: url("../../../../assets/images/add.svg");
              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              background-color: transparent;
              position: absolute;
              // right: -15px;
              // top: 50%;
              // transform: translateY(-50%);
              left: 50%;
              bottom: -6px;
              transform: translateX(-50%);

              span {
                display: none;
              }
            }

            // .tooltip-wrapper {
            //     position: absolute;
            //     right: -45px;
            //     top: 50%;
            //     transform: translateY(-50%);
            // }

            // .tooltip-btn {
            //     background-color: transparent;
            //     padding: 0;
            //     border: none;
            // }

            // .tooltip-card {
            //     display: none;
            //     position: absolute;
            //     min-width: 230px;
            //     z-index: 100;
            //     bottom: 180%;
            //     background: #FFFFFF;
            //     border: 1px solid #E1E4E8;
            //     border-radius: 2px;
            //     padding: 8px;

            //     .tooltip-row{
            //         display: flex;
            //         align-items: flex-start;
            //         justify-content: flex-start;
            //         margin-bottom: 15px;

            //         &:nth-child(4){
            //             margin-bottom: 0;
            //         }
            //     }

            //     .tooltip-col{
            //         &:first-child{
            //             flex: 0 0 20%;
            //         }

            //         p{
            //             font-weight: 400;
            //             font-size: 12px;
            //             line-height: 18px;
            //             letter-spacing: 0.03em;
            //             color: #000000;
            //             span{
            //                 font-weight: 700;
            //             }
            //         }

            //         .btn-wrap{
            //             display: flex;
            //             align-items: center;
            //             justify-content: flex-start;

            //             .edit-btn{
            //                 padding: 4px 10px;
            //                 background: #18A0FB;
            //                 border-radius: 2px;
            //                 font-weight: 400;
            //                 font-size: 8px;
            //                 line-height: 12px;
            //                 letter-spacing: 0.03em;
            //                 color: #FFFFFF;
            //                 width: fit-content;
            //                 display: flex;
            //                 align-items: center;
            //                 justify-content: center;
            //                 margin-right: 10px;
            //             }
            //         }
            //     }

            //     .tooltip-triangle{
            //         position: absolute;
            //         bottom: -13px;
            //     }
            // }

            // .tooltip-wrapper:hover .tooltip-card {
            //     display: block;
            // }
          }
        }
      }

      .bi-link-canvas-layer {
        top: -10px;
        height: 400%;
        // display: none;
      }

      .bi {
        .bi-diagram-link {
            @media screen and (max-width: 991px){
                display: none;
            }
          .bi-diagram-link-label {
            background-color: transparent;
          }

          .tooltip-wrapper {
            // position: absolute;
            // right: -45px;
            // top: 50%;
            // transform: translateY(-50%);
          }

          .tooltip-btn {
            background-color: transparent;
            padding: 0;
            border: none;
          }

          .tooltip-card {
            display: none;
            position: absolute;
            min-width: 230px;
            z-index: 100;
            // bottom: 180%;
            background: #ffffff;
            border: 1px solid #e1e4e8;
            border-radius: 2px;
            padding: 8px;
            bottom: -70px;
            left: 270%;

            .tooltip-row {
              display: flex;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: #000000;
              align-items: flex-start;
              justify-content: flex-start;
              margin-bottom: 15px;

              &:nth-child(4) {
                margin-bottom: 0;
              }
            }

            .tooltip-col {
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: #000000;

              &:first-child {
                flex: 0 0 20%;
              }

              p {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                letter-spacing: 0.03em;
                color: #000000;
                span {
                  font-weight: 700;
                }
              }

              .btn-wrap {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                .edit-btn {
                  padding: 4px 10px;
                  background: #18a0fb;
                  border-radius: 2px;
                  font-weight: 400;
                  font-size: 8px;
                  line-height: 12px;
                  letter-spacing: 0.03em;
                  color: #ffffff;
                  width: fit-content;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 10px;
                }
              }
            }

            .tooltip-triangle {
              position: absolute;
              // bottom: -13px;
              top: 10px;
              left: -14px;
              transform: rotate(90deg);
            }
          }

          &:hover .tooltip-card {
            display: block;
          }
        }
      }

      .bi-diagram-link:hover .tooltip-card {
        display: block;
      }

      .bi-link-path {
        // stroke-width: 0.1rem;
        stroke-width: 1.5px;
        stroke: #18a0fb !important;

        &::after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          background-color: blue;
        }
      }
    }
  }

  .pageLogic-wrapper {
    position: absolute;
    right: 0;
    top: 20px;
    width: 316px;
    height: 100%;
    overflow: auto;
    background: #ffffff;
    box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
    backdrop-filter: blur(9.5px);
    z-index: 100;
    @media screen and (max-width: 991px) {
      top: 0;
    }

    .title-wrapper {
      width: 100%;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(118, 160, 188, 0.3);
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #181818;
    }

    .content-wrapper {
      padding: 16px 0 50px;
      background-color: #fff;

      .dropdown-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 16px 0 30px;

        h2 {
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.03em;
          color: #181818;
          display: flex;
          align-items: center;

          svg,
          img {
            margin-left: 16px;
          }
        }

        button {
          background-color: transparent;
          padding: 0;
          margin: 0;
        }
      }

      .dropdown-wrapper {
        margin-bottom: 20px;
        padding: 0 16px 0 30px;

        .dropdownButton {
          border: 1px solid #aeb6cf;
          width: 100%;
          height: auto;
          padding: 6px;

          &:focus {
            outline: 0;
            box-shadow: none;
          }
        }

        .MuiFormControl-root {
          margin: 0;

          .MuiInputBase-root {
            border: 1px solid #aeb6cf;
            border-radius: 0;

            .MuiSelect-select {
              font-family: "Poppins", sans-serif;
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              letter-spacing: 0.03em;
              color: #000000;
            }
          }

          .MuiOutlinedInput-notchedOutline {
            display: none;
          }
        }
      }

      .checkbox-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 12px;
        padding: 0 16px 0 30px;

        .checkbox-label {
          display: block;
          position: relative;
          cursor: pointer;
          background: #ffffff;
          box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
          backdrop-filter: blur(9.5px);
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          color: #8a94a6;
          padding: 6px 20px;
          border-radius: 2px;
          border: 1px solid #fff;
          width: fit-content;
          margin: 0;
        }

        /* Hide the browser's default checkbox */
        .checkbox-input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;

          &:checked ~ .checkbox-label {
            border: 1px solid #18a0fb;
          }
        }
      }

      .addCondition {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 16px;
        padding: 0 16px 0 30px;

        svg {
          margin-right: 4px;
          width: 12px;
          height: 12px;

          path {
            fill: #18a0fb;
          }
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.03em;
          color: #18a0fb;
          margin: 0;
        }
      }

      .goto {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 25px;
        padding: 0 16px 0 30px;

        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          letter-spacing: 0.03em;
          color: #8a94a6;
          margin: 0;
        }

        .checkbox-container {
          margin: 0;
          padding: 0;
        }
      }

      .default-input-fields {
        padding: 0 16px 0 30px;

        .fields-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 20px;

          .age-field {
            width: 35%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            background: #f2f3f7;
            border: 1px solid #f2f3f7;
            border-radius: 2px;
            padding: 6px 10px;

            p {
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.03em;
              color: #aeb6cf;
              margin: 0;
              word-break: break-word;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;

              &:first-child {
                margin-right: 4px;
                flex: 0 0 15px;
              }
            }

            &.active {
              background: #f2f3f7;
              border: 1px solid #18a0fb;

              p {
                color: #181818;
              }
            }
          }
        }
      }

      .select-field {
        width: 60%;

        .dropdownButton {
          background: #f2f3f7;
          border-radius: 2px;
          border: 1px solid #f2f3f7;
          padding: 8px;
          padding: 6px 8px;
          height: 35px;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          color: #aeb6cf;
          width: 100%;
          word-break: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          min-height: 25px;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;

          &:focus {
            outline: 0;
            box-shadow: none;
          }

          &.active {
            background: #f2f3f7;
            border: 1px solid #18a0fb;
            color: #000000;

            span {
              font-weight: 900;
            }
          }
        }
      }

      .estimatedTime-wrapper {
        border-top: 1px solid #aeb6cf;
        margin-bottom: 20px;
        padding: 12px 16px 12px 30px;

        h2 {
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          letter-spacing: 0.03em;
          color: #181818;
          margin-bottom: 12px;
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          letter-spacing: 0.03em;
          margin-bottom: 12px;
          color: #8a94a6;
        }

        .input-group {
          border: 1px solid #aeb6cf;
          justify-content: space-between;

          input {
            border: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            letter-spacing: 0.03em;

            &:focus {
              outline: 0;
            }

            &::placeholder {
              color: rgba(138, 148, 166, 0.3);
            }
          }

          button {
            background-color: transparent;
            border: 0;
            padding: 0 8px;
            margin: 0;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #18a0fb;
          }
        }
      }

      .btn-wrap {
        margin-bottom: 20px;
        padding: 0 16px 0 30px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .blue-btn {
          width: fit-content;
          padding: 8px 35px;
          background-color: #ffffff;
          border: 1px solid #18a0fb;
          border-radius: 2px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          letter-spacing: 0.03em;
          color: #18a0fb;
        }
      }
    }
  }
}

// #menu- {
//     .MuiPaper-root {
//         height: 100px;

//         .MuiList-root {
//             li {
//                 font-family: 'Poppins', sans-serif;
//                 font-weight: 400;
//                 font-size: 12px;
//                 line-height: 18px;
//                 letter-spacing: 0.03em;
//                 color: #000000;

//                 img,
//                 svg {
//                     margin-right: 16px;
//                 }
//             }
//         }

//         &::-webkit-scrollbar {
//             width: 5px;
//             border-radius: 18px;
//         }

//         &::-webkit-scrollbar-track {
//             background: #D9D9D9;
//             border-radius: 18px;
//         }

//         &::-webkit-scrollbar-thumb {
//             background: #AEB6CF;
//             border-radius: 18px;
//         }

//         &::-webkit-scrollbar-thumb:hover {
//             background: #555;
//         }
//     }
// }
