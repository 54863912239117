.analyse-filter-wrapper{
    display: flex;
    align-items: center;

    @media screen and (max-width:650px){
        flex-direction: column;
        width: 100%;

        .select-items{
            justify-content: space-between;
        }

        .item{
            margin-right: 0 !important;
            width: 48% !important;
        }
    }
}

.select-dropdown{
    @media screen and (max-width:650px){
        width: 48%;
    }
    .dropdown {
        &__menu {
            &--upload {  
                padding: 0;
                margin: 0 10px 0 0;
                min-width: 135px;
                height: 32px;
                text-align: right;  

                @media screen and (max-width:650px){
                    margin: 0;
                }

                .dropdown {
                    &__btn {
                        background: #FFF;
                        border: 1px solid #18A0FB;
                        border-radius: 3px;
                        padding: 4px 10px;
                        height: 32px;
                        width: fit-content;
                        font-weight: 400;
                        font-size: 13px;
                        line-height: 21px;
                        color: #18A0FB;
                        cursor: pointer;
                        min-width: 135px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-left: auto;

                        @media screen and (max-width:768px){
                            margin-left: 0;
                        }

                        @media screen and (max-width:650px){
                            width: 100%;
                        }

                        img{
                            width: 22px !important;
                            height: 22px !important;
                            object-fit: contain;
                        }

                        svg{
                            path{
                                fill: #AEB6CF;
                            }
                        }
                    }
    
                    &__list {
                        background: #FFFFFF;
                        border: 1px solid #E1E4E8;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.2s;
                        max-height: 100px;
                        padding: 0;
                        margin: 0;
                        height: auto;
                        position: relative;
                        overflow: unset;
                        z-index: 100;
                        box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
                        min-width: 135px;
                        border-radius: 0.5rem;
    
                        &--item {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            font-weight: 400;
                            color: #8A94A6;
                            cursor: pointer;
                            padding: 5px 10px;
                            transition: all 0.3s;
                            position: relative;
                            font-size: 14px;
                            line-height: 18px;
    
                            .title {
                                display: flex;
                                align-items: center;
                                justify-content: flex-start;
                                width: calc(100% - 20px);
                                font-size: 13px !important;
                                font-weight: 400 !important;
                                color: #181818;
                                text-align: left;
                            }

                            .subDropdown{
                                position: absolute;
                                left: 100%;
                                z-index: 1000;
                                top: 0;
                                background: #fff;
                                background: #FFFFFF;
                                border: 1px solid #E1E4E8;
                                box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
                                border-radius: 0.5rem;
            
                                @media screen and (max-width: 1024px){
                                    left: 10px;
                                    width: 100%;
                                    top: 100%;
                                }
                            }

                            &:hover{
                                .title{
                                    color: #18A0FB !important;
                                }
                            }

                            
    
                            .delete-icon {   
                                svg {
                                    width: 20px;
                                    height: 20px;
    
                                    path {
                                        fill: #AEB6CF;
                                    }
                                }
                            }
    
                            &:hover,&.active {
                                background-color: rgba(225, 228, 232, 0.5);
                                transition: all 0.3s;
                            }
    
                        }
    
                        &.list--active {
                            opacity: 1;
                            visibility: visible;
                            transition: all 0.2s;
                        }
    
                        &::-webkit-scrollbar {
                            width: 5px;
                        }
    
                        &::-webkit-scrollbar-track {
                            background: #D9D9D9;
                            border-radius: 18px;
                        }
    
                        &::-webkit-scrollbar-thumb {
                            background: #AEB6CF;
                            border-radius: 18px;
                        }
    
                        &::-webkit-scrollbar-thumb:hover {
                            background: #555;
                        }
                    }
                }
            }
        }
    }
    

    .subDropdown {
        min-width: 150px;
        display: none;
        max-width: 220px;

        .innerPopup {
            width: 100%;

            .items {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .list-item{
                    width: 100%;
                    padding: 6px 4px;
                    text-align: center;

                    img{
                        margin: 0 !important;
                        object-fit: contain;
                    }
                }
            }
        }
        
        &.active{
            display: block;
        }
    }

    &.select-dropdown-checkbox{
        @media screen and (max-width: 650px){
            width: 100%;
        }

        .dropdown__menu--upload{
            height: 35px;
            margin-right: 0;
        }
        .dropdown__btn{
            height: 100%;
            padding: 4px 6px;
            color: #181818;
            font-size: 12px;

            @media screen and (max-width: 768px){
                width: 100%;
            }

            img{
                width: 15px !important;
                height: 15px !important;
                margin-right: 10px;
            }
        }
        .dropdown__list{
            max-height: 200px;
            min-width: 150px;
            .dropdown__list--item{
                padding: 5px 8px;
                img{
                    width: 15px !important;
                    height: 15px !important;
                    margin-right: 10px;
                }
                .title{
                    font-size: 12px !important;
                }
                .subDropdown-title {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: calc(100% - 20px);
                    font-size: 12px !important;
                    font-weight: 400 !important;
                    color: #181818;
                    text-align: left;
                    &:hover{
                        color: #18A0FB !important;;
                    }
                }

                .subDropdown{
                    left: -100%;
                    min-width: 150px;
                    box-shadow: 0 10px 15px -3px rgb(46 41 51 / 8%), 0 4px 6px -2px rgb(71 63 79 / 16%);
                    border-radius: 0.5rem;
                    ul{
                        padding: 0;
                        margin: 0;
                    }

                    @media screen and (max-width: 650px){
                        left: unset;
                        right: 0;
                    }
                }                
            }
        }
        .MuiCheckbox-root{
            padding: 4px;
            .MuiSvgIcon-root{
                path{
                    fill: #AEB6CF;
                }
            }

            &.Mui-checked{
                .MuiSvgIcon-root{
                    path{
                        fill: #9FF4A7;
                    }
                }
            }   
        }
    }
} 
