.subscriptionPage {
  background: #f3f4f8;
  padding-bottom: 40px;
  min-height: 100vh;
}

.subscriptionWrapper {
  background-color: #fff;
  margin: 80px 50px 40px 50px;
  padding: 35px 60px;
  border-radius: 5px;

  @media screen and (max-width: 1300px) {
    padding: 35px;
    margin: 40px;
  }

  @media screen and (max-width: 1024px) {
    padding: 20px;
    margin: 20px;
  }

  @media screen and (max-width: 768px) {
    margin: 20px 10px;
  }

  &.subscriptionSuccess {
    max-width: 700px;
    margin: 80px auto;

    @media screen and (max-width: 768px) {
      max-width: 100%;
      margin: 80px 20px;
    }

    .subscriptionSuccess-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      svg {
        margin-bottom: 20px;
        width: 50px;
        height: 50px;
        path {
          fill: #41c74d;
        }
      }

      .subscriptionSuccess-message {
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        color: #181818;
        text-align: center;

        @media screen and (max-width: 768px) {
          font-size: 20px;
        }
        @media screen and (max-width: 540px) {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }

    .buttonWrapper {
      @media screen and (max-width: 768px) {
        form {
          text-align: center;
        }
      }

      button {
        padding: 16px 60px;

        @media screen and (max-width: 768px) {
          padding: 12px 40px;
          width: fit-content;
          margin: auto;
        }
      }
    }
  }

  &.paymentSuccess {
    padding: 35px;
    .logo {
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .paymentSuccess__head {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 10px;
      }
      .paymentSuccesss__title,
      h2 {
        font-size: 36px;
        text-align: center;
        font-weight: 700;

        @media screen and (max-width: 768px) {
          font-size: 30px;
          line-height: 40px;
        }
        @media screen and (max-width: 576px) {
          font-size: 24px;
          line-height: 35px;
        }
      }
    }

    .paymentSuccesss__subtitle,
    h2 {
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;

      @media screen and (max-width: 768px) {
        font-size: 30px;
        line-height: 40px;
      }
      @media screen and (max-width: 576px) {
        font-size: 24px;
        line-height: 35px;
      }
    }
  }

  .MuiBox-root {
    margin-bottom: 60px;

    .subscriptionTabs {
      margin-bottom: 100px;

      .MuiTabs-root {
        .MuiTabs-fixed {
          .MuiTabs-indicator {
            display: none;
          }

          .MuiTabs-flexContainer {
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: auto;
            background: #f2f3f7;
            border-radius: 30px;
            padding: 4px;

            .MuiButtonBase-root {
              font-family: "Poppins", sans-serif;
              font-weight: 500;
              font-size: 18px;
              line-height: 27px;
              color: #8a94a6;
              padding: 10px 30px;
              border-radius: 30px;
              text-transform: capitalize;

              &.Mui-selected {
                background: #18a0fb;
                color: #fff;
              }
            }
          }
        }
      }
    }

    .subscriptionPanel {
      .MuiBox-root {
        padding: 0;
      }
    }
  }
}

.subscriptionCardWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 42px;

  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .subscriptionCard {
    background: #ffffff;
    border: 1px solid #e7e7e8;
    box-shadow: -10px 56px 140px rgba(143, 155, 186, 0.2);
    backdrop-filter: blur(9.5px);
    border-radius: 12px;
    padding: 35px 15px 30px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    .subscriptionTitle {
      font-family: "Poppins", sans-serif;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.015em;
      color: #18a0fb;
      margin-bottom: 16px;
      text-align: center;

      @media screen and (max-width: 1300px) {
        font-size: 22px;
        line-height: 32px;
      }
    }

    .subscriptionDescription {
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #8a94a6;
      margin-bottom: 40px;
      text-align: center;

      @media screen and (max-width: 1300px) {
        font-size: 16px;
        line-height: 25px;
      }

      @media screen and (max-width: 540px) {
        margin-bottom: 20px;
      }
    }

    .subscriptionPrice {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 35px;

      h4 {
        font-family: "Poppins", sans-serif;
        font-weight: 700;
        font-size: 30px;
        line-height: 45px;
        color: #18a0fb;
        text-align: center;

        @media screen and (max-width: 1300px) {
          font-size: 25px;
          line-height: 35px;
        }
      }

      p {
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #8a94a6;

        @media screen and (max-width: 1300px) {
          font-size: 16px;
          line-height: 25px;
        }
      }
    }

    .subscriptionCard-list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      min-height: 100px;

      .subscriptionCard-listItem {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        font-family: "Poppins", sans-serif;
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        color: #181818;
        margin-bottom: 25px;

        svg {
          margin-right: 16px;

          path {
            fill: #41c74d;
          }
        }

        @media screen and (max-width: 540px) {
          margin-bottom: 15px;
          font-size: 16px;
          line-height: 22px;
        }
      }
    }

    .blue-btn {
      background: #18a0fb;
      border-radius: 30px;
      padding: 10px 20px;
      width: fit-content;
      font-family: "Poppins", sans-serif;
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
    }

    .subscriptionBadge {
      background: #ffa06e;
      border-radius: 0px 12px 0px 0px;
      padding: 6px 18px;
      width: fit-content;
      position: absolute;
      top: 0;
      right: 0;
      font-family: "Poppins", sans-serif;
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: #ffffff;
      text-align: center;
      display: none;
    }

    .homeIcon {
      position: absolute;
      top: -50px;
      left: 50%;
      transform: translateX(-50%);
      display: none;
    }

    &.subscriptionSelected {
      background: #ffffff;
      border: 3px solid #18a0fb;
      box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.1);
      border-radius: 12px;

      .homeIcon {
        display: block;
      }
    }

    &.currentCard {
      .subscriptionBadge {
        display: block;
      }
    }
  }
}

.subscriptionFooter {
  margin-bottom: 80px;

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  .subscriptionFooterTitle {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #181818;
    margin-bottom: 35px;

    @media screen and (max-width: 768px) {
      font-size: 20px;
      line-height: 30px;
    }

    @media screen and (max-width: 540px) {
      font-size: 18px;
      line-height: 25px;
      margin-bottom: 20px;
    }
  }

  .subscriptionResponse-list {
    list-style-type: none;
    padding: 0;
    margin: 0 0 35px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: 540px) {
      justify-content: space-between;
    }

    .subscriptionResponse-listItem {
      background: #ffffff;
      border: 1px solid #e7e7e8;
      border-radius: 12px;
      padding: 16px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #8a94a6;
      margin-right: 55px;

      &:last-child {
        margin-right: 0;
      }

      &.selectedResponse {
        border: 1px solid #18a0fb;
        color: #18a0fb;
      }

      @media screen and (max-width: 768px) {
        margin-right: 20px;
        padding: 12px;
        font-size: 20px;
        line-height: 30px;
      }

      @media screen and (max-width: 540px) {
        margin-right: 10px;
        padding: 10px;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }

  p {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    color: #8a94a6;
    margin-bottom: 15px;

    a {
      color: #18a0fb;
      text-decoration: underline;
    }

    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 25px;
    }
  }
}

.buttonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;

  button {
    padding: 16px 130px;
    width: fit-content;
    background: #18a0fb;
    border-radius: 6px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    color: #ffffff;
    text-align: center;

    @media screen and (max-width: 768px) {
      padding: 12px 80px;
    }
  }

  @media screen and (max-width: 540px) {
    form {
      width: 100%;
      button {
        padding: 10px;
        width: 100%;
        font-size: 16px;
        line-height: 25px;
      }
    }
  }
}

.subscriptionAccordionTable {
  margin-bottom: 80px;

  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }

  .MuiPaper-root {
    background-color: #fff;
    box-shadow: none;
    border-radius: 0;

    &::before {
      display: none;
    }

    &.Mui-expanded {
      margin: 0 0 16px 0;
    }

    .accordionHead {
      // padding: 0;
      // border-bottom: 1px solid #E7E7E8;
      border-radius: 4px;
      margin-bottom: 12px;
      background: rgba(225, 228, 232, 0.5);
      height: 50px;
      min-height: 50px;
      .MuiAccordionSummary-content {
        justify-content: center;

        p {
          font-family: "Poppins", sans-serif;
          font-weight: 600;
          font-size: 24px;
          line-height: 21px;
          color: #181818;

          @media screen and (max-width: 768px) {
            font-size: 18px;
            line-height: 25px;
          }

          @media screen and (max-width: 540px) {
            font-size: 16px;
            line-height: 20px;
          }
        }

        @media screen and (max-width: 768px) {
          justify-content: flex-start;
        }
      }
      .MuiAccordionSummary-expandIconWrapper {
        .MuiSvgIcon-root {
          width: 1.5em;
          height: 1.5em;

          @media screen and (max-width: 540px) {
            width: 1em;
            height: 1em;
          }
        }

        &.Mui-expanded {
          transform: rotate(135deg);
        }
      }
    }

    .MuiCollapse-root {
      .accordionBody {
        padding: 0;
        .MuiPaper-root {
          table {
            thead {
              tr {
                th {
                  font-family: "Poppins", sans-serif;
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 36px;
                  color: #18a0fb;
                  padding: 8px;
                  width: 15%;

                  @media screen and (max-width: 768px) {
                    font-size: 18px;
                    line-height: 30px;
                  }
                  @media screen and (max-width: 540px) {
                    font-size: 16px;
                    line-height: 25px;
                  }

                  &:first-child {
                    width: 40%;
                  }
                }
              }
            }
            tbody {
              tr {
                th {
                  font-family: "Poppins", sans-serif;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 25px;
                  color: #8a94a6;
                  padding: 8px;

                  @media screen and (max-width: 768px) {
                    font-size: 16px;
                  }

                  @media screen and (max-width: 540px) {
                    font-size: 14px;
                  }
                }
                td {
                  font-family: "Poppins", sans-serif;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 25px;
                  color: #181818;
                  padding: 8px;

                  @media screen and (max-width: 768px) {
                    font-size: 16px;
                  }
                  @media screen and (max-width: 540px) {
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
